import React from 'react'

const AddNewTemplateCard = ({setUpdateSurvey, openNewSurveyCard , setOpenNewSurveyCard }) => {
    return (
        <div onClick={()=>{setOpenNewSurveyCard(true);setUpdateSurvey(false)}} className="addNewTemplateCard" 
        // style={{boxShadow:'0px 5px 10px #FFC9CB'}}
        >
            <AddCircleIcon />
            <div>Create New</div>
        </div>
    )
}

const AddCircleIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00004C14.6667 4.31814 11.6819 1.33337 8.00004 1.33337C4.31814 1.33337 1.33337 4.31814 1.33337 8.00004C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667Z" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/> <path d="M8 5.33337V10.6667" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/> <path d="M5.33337 8H10.6667" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/> </svg> 

export default AddNewTemplateCard
