import React, {useState, useEffect} from 'react';
import useAsync from '../../../Hooks/useAsync';
import { validateEmail } from '../../../utils';

const BatchEdit = ({ 
    setUpdateTable,
    openBatchEdit, 
    setOpenBatchEdit, 
    multiSelected, 
    setMultiSelected, 
    endpoints, 
    axiosOptions,
    handleSuccess,
    handleError,
    makePostApiCall,
    handleInvalidEmail
}) => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const handleChange = (e) => setEmail(e.target.value);
    const handleChangeName = (e) => setName(e.target.value);

    const handleEscape = (e) => {
        if(e.key==='Escape') {
            setOpenBatchEdit(false);
        }
    }
    const handleCancelClick = () => {
        setEmail('');
        setName('');
        setOpenBatchEdit(false);
    }

    // API DEALING
    const endpoint = `${endpoints.updateAllAlert}`;
    const body = { email: email, name: name, ids: [...multiSelected] };
    const { execute, status, value, error } = useAsync(
        () => makePostApiCall(endpoint, body, axiosOptions),
        false
      );
    useEffect(() => {
    if (error) {
        handleError(error);
        setOpenBatchEdit(false);
    };
    if (status === "success") {
        handleSuccess();
        setUpdateTable(true);
        setOpenBatchEdit(false);
    };
    }, [status, value, error]);
    //

    // useEffect(() => {
    //     body.email = email;
    //     body.name = name;
    // }, [email, name]);


    

    const handleBatchEdit = () => {
        // *done validate email
        // *done success/ error alerts
        // *done build body
        // *done: build api endpoint
        // *done: api call
        // *done: remount table after successful post
        
        if(!validateEmail(email)){
            handleInvalidEmail();
            return;
        }
        execute();
    }
    
    return (
          <div 
            onKeyDown={(e)=>handleEscape(e)}
            style={{
                boxShadow:'3px 4px 12px rgba(0, 0, 0, 0.19)', 
                minWidth: '50%'}
            } 
            className="absolute w-8/12 pb-24 space-y-5 bg-white left-56 top-20 rounded-xl">
                <div className="flex flex-col p-8 pl-10 pr-16">
                    <span className="flex items-center justify-between mb-4">
                        <span className="flex items-center space-x-5">
                            <span className="text-2xl font-semibold text-blue-dark">Batch Edit</span>
                            <span className="small-pink-text">{multiSelected?.length} Combinations Selected</span>
                        </span>
                        <span className="flex space-x-4">
                            <button onClick={handleCancelClick} className="btn-grey">Cancel</button>
                            {
                                multiSelected?.length &&
                                <button 
                                    disabled={!multiSelected?.length || !email?.length} 
                                    onClick={handleBatchEdit} 
                                    className={!email?.length ? 'btn-red-disabled' : 'btn-red'}
                                >
                                    Save
                                </button>
                            }
                        </span>
                    </span>
                </div>
                <div className="w-full space-y-5 col-center">
                        <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-5/12">
                        <input
                            // disabled={checked}
                            value={name}
                            onChange={handleChangeName}
                            type="text"
                            className={`w-full text-sm font-semibold text-center text-grey placeholder-opacity-80 rounded-xl placeholder-grey-lightest`}
                            placeholder="Enter Name"
                            />
                    </span>
                    <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-5/12">
                        <input
                            value={email}
                            onChange={handleChange}
                            type="email"
                            className={`w-full text-sm font-semibold text-center text-grey placeholder-opacity-80 rounded-xl placeholder-grey-lightest`}
                            placeholder="Enter email address"
                        />
                        </span>
                </div>
                <Message />
            </div>
    )
}

const Message = () => <div className="text-sm text-center text-blue">Add a Name and Valid Email Address of the relevant person.</div>

export default BatchEdit;