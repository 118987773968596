import React from 'react';


const Dropdown = ({list, addItem, displayValue}) => {


    return (<div id="dropdown" className="absolute z-40 w-10/12 h-auto overflow-y-scroll bg-white rounded shadow max-h-52 top-100 lef-0 max-h-select ">
    <div className="flex flex-col w-full">
        { list.map((item, index) => {
            return <div key={index} 
            className="w-full rounded-t cursor-pointer hover:bg-red-lightest" 
            onClick={() => addItem(item)}>
            <div className="relative flex items-center w-full p-2 pl-2 border-l-2 border-transparent hover:border-red-lightest" >
                <div className="flex items-center w-full">
        <   div className="mx-2 leading-6 ">
            { item[displayValue] }
           </div>
          </div>
         </div>
        </div>
        })}
    </div>
</div>);

};

export default Dropdown;