export const compareFunc = (a, b, key) => {
    var nameA = a[key]?.toUpperCase(); // ignore upper and lowercase
    var nameB = b[key]?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };