import React from 'react'
import personsvg from './person.svg';


const PersonSVG = () => {
    return (
        <>
            <img height="280px" width="280px" src={personsvg} alt="survey image"/> 
        </>
    )
}

export default PersonSVG
