import React from 'react'

const QuestionDescription = (props) => {

    const {
        index,
        handleDescriptionChange,
        description
    } = props;

    return (
        <div className="flex space-x-5">
            <input 
                key={ index } 
                value={ description } 
                onChange={ (e) => handleDescriptionChange(e) } 
                className="w-11/12 px-10 py-2 font-semibold border text-grey-lightest h-11 rounded-xl border-grey-lightest" placeholder="Description" type="text" name="question description" />
            <div className="flex items-center justify-start px-4 py-2 text-sm font-semibold w-60 rounded-xl"></div>
        </div>
    )
}

export default QuestionDescription
