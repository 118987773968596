import React from "react";

const StartEndDate = (props) => {
  const {
    startOnPublish,
    editingStartDate,
    setStartDate,
    setEditingStartDate,
    editingEndDate,
    setEditingEndDate,
    endDate,
    setEndDate,
    startDate,
  } = props;

  return (
    <>
      {!startOnPublish && (
        <>
          {editingStartDate ? (
            <input
              onChange={(e) => setStartDate(e.target.value)}
              onKeyDownCapture={(e) => {
                if (e.key === "Enter") setEditingStartDate(false);
              }}
              autoFocus
              className="px-10 py-2 font-semibold border w-80 text-grey-lightest border-grey-lightest rounded-xl focus:outline-none"
              placeholder="Start Date"
              type="date"
              name="date"
            />
          ) : (
            <div
              onClick={() => setEditingStartDate(true)}
              className="px-10 py-2 font-semibold border w-80 text-grey-lightest pointer border-grey-lightest rounded-xl focus:outline-none"
            >
              {startDate ? `Start Date: ${startDate}` : "Start Date"}
            </div>
          )}
        </>
      )}
      {editingEndDate ? (
        <input
          onChange={(e) => setEndDate(e.target.value)}
          onKeyDownCapture={(e) => {
            if (e.key === "Enter") setEditingEndDate(false);
          }}
          autoFocus
          className="px-10 py-2 font-semibold border w-80 text-grey-lightest border-grey-lightest rounded-xl focus:outline-none"
          placeholder="End Date"
          type="date"
          name="date"
        />
      ) : (
        <div
          onClick={() => setEditingEndDate(true)}
          className="px-10 py-2 font-semibold border w-80 text-grey-lightest pointer border-grey-lightest rounded-xl focus:outline-none"
        >
          {endDate ? `End Date: ${endDate}` : "End Date"}
        </div>
      )}
    </>
  );
};

export default StartEndDate;
