export const getAuthTypeFromLS = () => {
    const sso = localStorage.getItem('ssoToken');
    const base = localStorage.getItem('baseToken');

    if(sso) return 'sso';
    else if(base) return 'base';
    else return null;
  }

export const getAuthTokenFromLS = () => {
    const sso = localStorage.getItem('ssoToken');
    const base = localStorage.getItem('baseToken');

    if(sso) return sso;
    else if(base) return base;
    else return null;
  }

export const removeTokensFromLS = () => {
    localStorage.removeItem('ssoToken');
    localStorage.removeItem('baseToken');
};

export const removeUsernameFromLS = () => localStorage.removeItem('username');

export const axiosOptions = {
    headers: {
          'Content-Type': 'application/json',
          'Authentication': getAuthTokenFromLS(),
          'AuthenticationType': getAuthTypeFromLS(),
          'Accept': '*/*',
          // "Accept-Encoding": "gzip, deflate, br",
    },
    credentials: "same-origin"
  }
  

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateUsername(username){
    /* 
      Usernames can only have: 
      - Lowercase Letters (a-z) 
      - Uppercase Letters (A-Z)
      - Numbers (0-9)
      - Dots (.)
      - Underscores (_)
    */
   const re = /^[a-zA-Z][a-zA-Z^0-9_\.]+$/
  return re.test(username);
}