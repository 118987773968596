import React, { useState, useEffect, useRef } from 'react'

import SelectedOption from '../SelectedOption';
import QuestionOptions from './QuestionOptions';
import Checkbox from '../Checkbox';

import QuestionTitle from './QuestionTitle';
import QuestionDescription from './QuestionDescription';
import DependentOption from './DependentOption';

const NewQuestion = ({ openToast, question, index, questions, setQuestions }) => {
    const [openQuestionOptions, setOpenQuestionOptions] = useState(false);

    // Dependant Update
    const [depends, setDepends] = useState(question.depends);
    // const [fields2, setFields2] = useState(question.fields2);

    const idForDependantRadio = Math.random().toString();
    const idForIndependentRadio = Math.random().toString();

    const [selectedOption, setSelectedOption] = useState(question.type);

    const handleOptionClick = (where) => {
        setOpenQuestionOptions(false);
        setSelectedOption(where);
        const newArr = [...questions];
        newArr[index].type = where;

        setQuestions(newArr)
    }

    const handleDeleteQuestion = (id) => {
        setQuestions(prev=>prev.filter(question=> question.id!==id))
    }

    const handleEnableOtherResponseClick = (id) => {
        const newArr = [...questions];
        newArr[index].enableOtherResponse = !newArr[index].enableOtherResponse

        setQuestions(newArr)
    
    }

    const handleEnableMultiSelectClick = (id) => {
        const newArr = [...questions];
        newArr[index].enableMultiSelect = !newArr[index].enableMultiSelect

        setQuestions(newArr)
    }

    const handleMandatoryClick = (id) => {
        const newArr = [...questions];
        newArr[index].mandatory = !newArr[index].mandatory

        setQuestions(newArr)
    
    }

    const handleAddOption = (id) => {
        const newArr = [...questions];
        newArr[index].options.push('Option');

        setQuestions(newArr);
    }

    const handleTitleChange = (e) => {
        const newArr = [...questions];
        newArr[index].title = e.target.value;

        if(e.target.value==='') openToast(
                () => console.log('Empty Question Label'),
                "error",
                "Label can't be empty"
            )

        setQuestions(newArr)
    }

    const handleDescriptionChange = (e) => {
        const newArr = [...questions];
        newArr[index].description = e.target.value;

        setQuestions(newArr)
    }

    const handleOtherLabelChange = (e) => {
        const newArr = [...questions];
        newArr[index].otherResponseLabel = e.target.value;

        setQuestions(newArr)
    }

    // COMMENT 1

    const fields2Length = question.fields2 ? Object.keys(question.fields2).length : 0;
    const fields2Values = question.fields2 ? Object.values(question.fields2) : [];
    const fields2Keys = question.fields2 ? Object.keys(question.fields2) : [];

    const checkDoubleDepends = (source) => {
        if(source?.split('/')?.length>1){
            return true;
        } else return false;
    }
    //checkDoubleDepends(question.dependsSource) ? question.dependsSource?.split('/')[1] :
    const toAddTemp =  question.depends ?  question.dependsSource : null;
    const questionTemp = toAddTemp ? questions.filter(question=>question.title===toAddTemp)[0] : null;

    const [selectedLabel, setSelectedLabel] = useState(questionTemp ? questionTemp : "");
    const [labelsQuestionsForDD, setLabelsQuestionsForDD] = useState([]);
    const [fieldsAdded, setFieldsAdded] = useState( fields2Length ? question.fields2 : {});
    const [selectedField, setSelectedField] = useState(fields2Keys[0]); //fields2Values.length ? fields2Values[0] : ''


    const handleAddField = () => {
        let tempFieldsObj = {...fieldsAdded};
        tempFieldsObj[selectedField]?.push('Option'); 
        // console.log('addd',tempFieldsObj)
        setFieldsAdded(tempFieldsObj);
    }

    const handleSelectedLabelOnChange = (e) => {
        const toInsert = labelsQuestionsForDD.filter(question=>question.title===e.target.value)[0];
        // console.log('INSERT',toInsert)
        setSelectedLabel(toInsert);

        const options = toInsert?.options?.map(item=>item);
        // const tempFieldsObj = {...fieldsAdded};
        let tempFieldsObj = {};
        
        if(toInsert?.depends){ // double-depend
            // setDoubleDependent(true);
            const questionsBeforeCurrent = questions.slice(0,index);
            const dependsDependency = questionsBeforeCurrent.filter(question=>question.title===toInsert.dependsSource)[0];
            const dependsDependencyOptions = dependsDependency?.options;
            
            // if(dependsDependency?.title) {
            //     setDependsSource(`${dependsDependency.title}/${toInsert.title}`)
            // } else {
            //     setDependsSource(toInsert.title) 
            // }

            if(!dependsDependency) return;

            const options1 = dependsDependencyOptions;
            // const options2 = Object.values(toInsert.fields2).flat();
            const options2Obj = toInsert.fields2;
            let combos = [];
            // options1.forEach(function(a1){
            // options2.forEach(function(a2){
            //     combos.push(`${a1}/${a2}`);
            // });
            // });

            options1.map(item=>{
                // let combo = `${item}`
                Object.keys(options2Obj).map(key=>{
                    let combo = null;
                    if(item===key){
                        options2Obj[key].map(value=>{
                            combo = `${item}/${value}`;
                            if(combo) combos.push(combo);
                        })
                    }
                })
            })

            combos?.map(item=>{
                tempFieldsObj = {
                    ...tempFieldsObj,
                    [item] : []
                }
            })
            setFieldsAdded(prev=>(
                combos?.length ? tempFieldsObj : question.fields2
            ));
            if(combos?.length>0) setSelectedField(combos[0]);
            console.log('Combos',combos);
        } else {
            options?.map(option=>{
                tempFieldsObj = {
                    ...tempFieldsObj,
                    [option] : []
                }
            })
            // setDependsSource(toInsert.title)
            setFieldsAdded(prev=>(
                options?.length ? tempFieldsObj : question.fields2
                ));
            console.log('fields added',tempFieldsObj)
        }
        
    }

    useEffect(() => {
        const newArr = [...questions];
        const labelsQuestionsToShow = [];
        const questionsBeforeCurrent = questions.slice(0,index);
        questionsBeforeCurrent.map((question, index)=>{
            const depends = question.depends;
            if(depends){
                const dependsDependency = questionsBeforeCurrent.filter(question=>question.title===question.title)[0];
                if(
                    2+2===2 // question?.fields2?.split('/').length>1
                    ){
                        // labelsQuestionsToShow.push(question);
                } else if(!dependsDependency.dependsSource && question.type!=='textbox' && question.type!=='simple-checkbox' && question.enableMultiSelect!==true && question.enableOtherResponse!==true){
                    labelsQuestionsToShow.push(question); // double-depend
                }
            } else if(question.type!=='textbox' && question.type!=='simple-checkbox' && question.enableMultiSelect!==true && question.enableOtherResponse!==true){
                labelsQuestionsToShow.push(question);
            }
        })
        setLabelsQuestionsForDD(labelsQuestionsToShow);
        // setSelectedLabel(prev=> prev==='' ? labelsQuestionsToShow[0] : prev);
        // if(questionTemp) setSelectedLabel(questionTemp);
        // else 
        setSelectedLabel(prev=> prev==='' ? labelsQuestionsToShow[0] : prev);
        console.log('labelsQuestionsToShow',labelsQuestionsToShow);
    }, [depends])

    useEffect(() => {
        const tempQuestions = [...questions];
        if(depends && selectedLabel){
            console.log('FIELDS ADDED', fieldsAdded)
            let thisQuestion = {...tempQuestions[index]};
            let thisQAfterFields2 = {
                                        ...thisQuestion,
                                        "depends": true, 
                                        "fields2" : fieldsAdded, 
                                        "dependsSource": depends && selectedLabel.title ? selectedLabel.title : "", //selectedLabel.title
                                        // "dependingChildType": [selectedLabel.type==='dropdown-vertical' ? 'dropdown-simple' : selectedLabel.type],
                                        "options": []
                                    };
            tempQuestions.splice(index,1,thisQAfterFields2)
        } else {
            console.log('FIELDS ADDED', fieldsAdded)
            let thisQuestion = {...tempQuestions[index]};
            let thisQAfterFields2 = {
                                        ...thisQuestion,
                                        "depends": false, 
                                        "fields2" : {}, 
                                        "dependsSource": "", //selectedLabel.title
                                        // "dependingChildType": [selectedLabel.type==='dropdown-vertical' ? 'dropdown-simple' : selectedLabel.type],
                                        "options": question.options
                                    };
            tempQuestions.splice(index,1,thisQAfterFields2)
        }
        setQuestions(tempQuestions) 
        console.log('QUESTIONS', tempQuestions);
    }, [fieldsAdded, selectedLabel, depends])

    // COMMENT 3

    const handleDependsRadioChange = () => {
        // ()=> index !== 0 ? setDepends(prev=>!prev) : null
        let change = false;
        const questionsBeforeCurrent = questions.slice(0,index);
        questionsBeforeCurrent.map(item=>{
            if(item?.type?.includes("dropdown")) change = true;
        })
        if(change) setDepends(prev=>!prev);
    }


    return (
        <div style={{boxShadow:'3px 4px 12px rgba(0, 0, 0, 0.19)', width:'100%'}} className="px-10 py-8 space-y-5 rounded-xl">
            {/* {console.log('fifelddds:',fieldsAdded)} */}
            <QuestionTitle 
                index = { index }
                handleDeleteQuestion = { handleDeleteQuestion }
                questionId = { question.id } 
            />
            {console.log('depends:',depends)}
            <div className="flex space-x-5" 
            >
                <input key={index} style={question.title==='' ? { borderColor: 'red' } : null} 
                    onFocus={(e)=>{if(e.target.value==="Unique Label") e.target.value='';}} 
                    value={question.title} 
                    onChange={(e)=>handleTitleChange(e)} 
                    className="w-11/12 px-10 py-2 font-semibold border text-grey-lightest h-11 rounded-xl border-grey-lightest" 
                    placeholder="Input Label (Unique)" 
                    type="text" 
                    name="Label (Unique)" 
                    />
                <span className="w-72">
                    <button onClick={()=>setOpenQuestionOptions((prev)=>!prev)} className="z-0 flex items-center justify-start w-full px-4 py-2 text-sm font-semibold bg-grey-light rounded-xl">
                        <SelectedOption selectedOption={selectedOption} />
                    </button>
                    {
                    openQuestionOptions && 
                    <QuestionOptions 
                        handleOptionClick={handleOptionClick} 
                        openQuestionOptions={openQuestionOptions} 
                        setOpenQuestionOptions={setOpenQuestionOptions} 
                        />
                }
                </span>
                
            </div>
            {
                question.type!=='textbox' && question.type!=='checkbox-simple' &&
                <QuestionDescription 
                    index = { index }
                    description = { question.description }
                    handleDescriptionChange = { handleDescriptionChange } 
                />
            }
            {
                question.type!=='textbox' && question.type!=='checkbox-simple' &&
                <div className="flex items-center space-x-5 "
                    // onClick={()=>handleEnableMultiSelectClick(question.id)}
                    >
                    <span className="space-x-2 text-lg font-medium text-grey-darkest center">
                        <input onChange={handleDependsRadioChange} checked={!depends} type="radio" id={idForIndependentRadio} />
                        <label htmlFor={idForIndependentRadio}>Independent</label>
                    </span>
                    <span className="space-x-2 text-lg font-medium text-grey-darkest center">
                        <input onChange={handleDependsRadioChange} checked={depends} type="radio" id={idForDependantRadio}/>
                        <label htmlFor={idForDependantRadio}>Dependent {depends && "on: "}</label>
                    </span>
                    {
                        depends ?
                        <span>
                            <SelectLabel 
                                key={selectedLabel}
                                inputArr={labelsQuestionsForDD} 
                                onChange={handleSelectedLabelOnChange}
                                value={selectedLabel}
                                fieldsAdded={fieldsAdded}
                                setFieldsAdded={setFieldsAdded}
                                setSelectedField={setSelectedField}
                                question={question}
                                selectedLabel={selectedLabel}
                                questions={questions}
                                index={index}
                            />
                        </span>
                        :
                        null
                    }
                    {/* <Checkbox checked={question.enableMultiSelect} key={question.id}>Enable Multi-Select</Checkbox> */}
                </div>
            }
            {
                depends && selectedLabel && labelsQuestionsForDD?.length>0 &&
                <>
                    <span key={`${depends}${Object.keys(fieldsAdded)}${selectedLabel}`} className="flex justify-start space-x-3">
                        <span>
                            <SelectOptions 
                                key={`${Object.keys(fieldsAdded)}${selectedLabel.title}`} //selectedLabel 
                                selectedLabel={selectedLabel} 
                                setSelectedLabel={setSelectedLabel}
                                selectedField={selectedField} 
                                setSelectedField={setSelectedField} 
                                fieldsAdded = {fieldsAdded}
                                setFieldsAdded = {setFieldsAdded}
                                />
                        </span>
                        <span className="flex items-center space-x-3">
                        {
                            <span onClick={handleAddField}>
                                <AddDependentOption>Add Option</AddDependentOption>
                            </span>
                        }
                        </span>
                    </span>
                    {
                        depends && Object.keys(fieldsAdded).length>0 && fieldsAdded[selectedField]?.length>0 &&
                        <span key={`${depends}${fieldsAdded[selectedField]?.length}${selectedField}`} className="flex flex-wrap items-center space-y-2 dependentOptionsContainer">
                            {console.log('Rendereed')}
                            {
                                fieldsAdded[selectedField]?.map((field, fieldIndex)=>(
                                    // fieldsAdded[key].map((option, optionIndex)=>{
                                            <DependentOption
                                                key={`${selectedLabel}${fieldsAdded[selectedField]?.length}${fieldIndex}`}
                                                optionIndex={fieldIndex}
                                                option={field} 
                                                question={question} 
                                                setQuestions={setQuestions} 
                                                questions={questions} 
                                                questionIndex={index}
                                                fieldsAdded = {fieldsAdded}
                                                setFieldsAdded = {setFieldsAdded}
                                                selectedField = {selectedField}
                                        />
                                    // })
                                ))
                            }
                        </span>
                    }
                </>
                // <MultiDependentTable question={question} setQuestions={setQuestions} questions={questions} questionIndex={index}/>
            }
            
            {
                !depends &&
                <div key={question.options?.length} className={question.type.includes('vertical') ? "space-y-2 flex flex-col" : "flex-wrap flex space-y-2 items-center horizontalOptionsContainer"}>
                    {question.options?.map((option, optionIndex)=><Option optionIndex={optionIndex} option={option} question={question} setQuestions={setQuestions} questions={questions} questionIndex={index}/>)}
                </div>
            }
            {
                !depends && 
                question.type!=='' && question.type!=='textbox' &&
                    <div><div className="w-44" onClick={handleAddOption}><AddOption>Add Option</AddOption></div></div> 
            }
            {
                question.type!=='textbox' && question.type!=='dropdown-vertical' &&
                <div className="w-60" onClick={()=>handleEnableMultiSelectClick(question.id)}><Checkbox checked={question.enableMultiSelect} key={question.id}>Enable Multi-Select</Checkbox></div>
            }
            {
                question.type!=='textbox' && question.type!=='dropdown-horizontal' && question.type!=='checkbox-dropdown-vertical' && question.type!=='dropdown-vertical' && 
                <span className="flex justify-start h-8 space-x-5">
                    <div onClick={()=>handleEnableOtherResponseClick(question.id)}><Checkbox checked={question.enableOtherResponse} key={question.id}>Enable Other Response</Checkbox></div>
                    {
                        question.enableOtherResponse &&
                        <span className="flex flex-col items-center">
                            <input onChange={(e)=>handleOtherLabelChange(e)} className={`w-64 text-center ${question.otherResponseLabel==="" && "border-red border-2"} rounded-xl text-grey-lightest placeholder-grey-lightest`} value={question.otherResponseLabel} placeholder="Unique Label" type="text"/>
                            { question.otherResponseLabel==="" && <span className="text-sm text-red">Label must be non-empty and unique</span>}
                        </span>
                    }
                </span>
            }
            <div onClick={()=>handleMandatoryClick(question.id)}><Checkbox checked={question.mandatory} key={question.id}>Make it mandatory</Checkbox></div>
        </div>
    )
}


const SelectLabel = ({ questions, index, inputArr,  value, onChange, fieldsAdded, setFieldsAdded, setSelectedField, question, selectedLabel }) => {

    useEffect(() => {
        // same as in ~handleSelectedLabelOnChange
        
        const isFields2KeysLength = () => {
            if (question.fields2 && typeof(question.fields2)==="object") return Object.keys(question.fields2).length>0;
            else return false;
        };

        const options = value?.options?.map(item=>item);
        let tempFieldsObj = {};
        options?.map(option=>{
            tempFieldsObj = {
                ...tempFieldsObj,
                [option] : []
            }
        })
        if(!selectedLabel?.dependsSource && !isFields2KeysLength()){
            setFieldsAdded(options?.length ? tempFieldsObj : question.fields2);
            options && setSelectedField(options[0]);
        } else {
            const toInsert = selectedLabel;
            
            const questionsBeforeCurrent = questions.slice(0,index);
            const dependsDependency = questionsBeforeCurrent.filter(question=>question.title===toInsert.dependsSource)[0];
            const dependsDependencyOptions = dependsDependency?.options;
            
            if(!dependsDependency) return;

            const options1 = dependsDependencyOptions;
            // const options2 = Object.values(toInsert.fields2).flat();
            const options2Obj = toInsert.fields2;
            let combos = [];
            // options1.forEach(function(a1){
            // options2.forEach(function(a2){
            //     combos.push(`${a1}/${a2}`);
            // });
            // });

            options1.map(item=>{
                // let combo = `${item}`
                Object.keys(options2Obj).map(key=>{
                    let combo = null;
                    if(item===key){
                        options2Obj[key].map(value=>{
                            combo = `${item}/${value}`;
                            if(combo) combos.push(combo);
                        })
                    }
                })
            })

            combos?.map(item=>{
                tempFieldsObj = {
                    ...tempFieldsObj,
                    [item] : []
                }
            })
            setFieldsAdded(prev=>(
                combos?.length && !isFields2KeysLength() ? tempFieldsObj : question.fields2
            ));
            if(combos?.length>0) setSelectedField(combos[0]);
            console.log('Combos',combos);
        }
            // COMMENT 4
    }, [])

    return (
        <>
        {/* {console.log('Input Array',inputArr)} */}
            <select
                onChange={(e)=>onChange(e)} 
                value={value?.title} className="content-center pl-10 font-semibold text-center rounded-lg text-grey-lightest border-grey-lightest w-60" >
                    {
                        inputArr?.length>0 ? 
                            inputArr.map((question, index)=><option key={index} id={index} tabIndex={index} className="font-semibold text-grey">{question.title.trim()}</option>)
                        :
                            console.log('Empty Input Array')
                }
            </select>
        </>   
    )

}

const SelectOptions = ({ selectedLabel, fieldsAdded, setFieldsAdded, setSelectedLabel, selectedField, setSelectedField }) => {

    const handleChange = (e) => {
        const val = e.target.value;
        setSelectedField(val);
        // COMMENT 5
    } 

    // COMMENT 6

    return (
        <>
        {console.log('selectedField',selectedField)}
        {console.log('selectedlabel', selectedLabel)}
            <select 
                onChange={(e)=>handleChange(e)} 
                value={selectedField} className="content-center pl-10 font-semibold text-center rounded-lg text-grey-lightest border-grey-lightest w-60" >
                    {
                        // selectedLabel?.options?.map((option, index)=><option key={index} id={index} tabIndex={index} className="font-semibold text-grey">{option.trim()}</option>)
                        // double-depend :
                        Object.keys(fieldsAdded)?.map((option, index)=><option key={index} id={index} tabIndex={index} className="font-semibold text-grey">{option.trim()}</option>)                        
                }
            </select>
        </>   
    )

}


const MultiDependentTable = ({ index, question, questions, setQuestions, questionIndex, optionIndex, option }) => {
    return (
        <div className="">
            <DependentOption optionIndex={optionIndex} option={option} question={question} setQuestions={setQuestions} questions={questions} questionIndex={index}/>
        </div>
    )
}


const AddDependentOption = ({ children }) => 
    <div className="flex items-center justify-center w-40 py-2 font-semibold break-all transition-all border border-dashed pointer hover:text-grey hover:bg-grey-light rounded-xl border-grey-lightest text-grey-lightest">
        {children}
    </div>


const Option = ({ question, questions, setQuestions, questionIndex, optionIndex, option }) => {
    const [optionValue, setOptionValue] = useState(option);

    useEffect(()=>{
        const newArr = [...questions];
        newArr[questionIndex].options[optionIndex] = optionValue;

        setQuestions(newArr);

    }, [optionValue]);

    const removeOption = () => {
        const newArr = [...questions];
        const deletedOptionValue = newArr[questionIndex].options.splice(optionIndex,1);
        // newArr[questionIndex].options[optionIndex] = optionValue;

        setQuestions(newArr);
    }

    // COMMENT 7

    return (
    <>
        {
            question.type!=='textbox' && question.type!=='' ?
            <>
            <div 
                key={optionIndex} 
                className={question.type.includes('vertical') ? "flex items-center justify-start space-x-5" : "flex items-center justify-start space-x-2"}>
                <input 
                    onFocus={(e)=>{if(optionValue==='Option') e.target.value=''}} 
                    onChange={(e)=>{setOptionValue(e.target.value)}} 
                    className="flex items-center justify-center w-40 max-w-xs px-2 py-1 font-semibold text-center break-all border min-w-max rounded-xl border-grey-lightest text-grey-lightest" 
                    value={optionValue} 
                    type="text" 
                    name="option"
                    />
                <span onClick={removeOption} className="pointer"><DeleteIcon /> </span>
            </div>
            </>
            : null
        }
    </>
    )
}

const AddOption = ({ children }) => 
    <div className="flex items-center justify-center w-40 py-2 font-semibold break-all transition-all border border-dashed pointer hover:text-grey hover:bg-grey-light rounded-xl border-grey-lightest text-grey-lightest">
        {children}
    </div>

const DeleteIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3 6H5H21" stroke="#F77D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#F77D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 11V17" stroke="#F77D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14 11V17" stroke="#F77D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const DndIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 10H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 6H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 14H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 18H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>

export default NewQuestion