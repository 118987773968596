import React, {useState, useEffect} from 'react'

import AddNewUserCard from './AddNewUserCard';

import ConfirmationCard from '../../ConfirmationCard'; 

import axios from 'axios';
import ReactTooltip from 'react-tooltip';

import {
    // CheckedIcon,
    // UncheckedIcon,
    EyeInvisibleIcon,
    EyeVisibleIcon,
    ActiveIcon,
    InactiveIcon,
    EditIcon,
    DeleteIcon,
} from '../../Icons';

const UsersPage = ({ EmptyTableSVG, LoadingAnimation, openToast, endpoints, axiosOptions, goToLoginAndRemoveTokensFromLS }) => {
    const [openAddNewUserCard, setOpenAddNewUserCard] = useState(false); 
    const [availableRoles, setAvailableRoles] = useState([]);
    const [updateUser, setUpdateUser] = useState(false);
    const [viewUser, setViewUser] = useState(false);


    const dateMaker = (date) => {
        // dd/mm/yy
        const mm = date.getMonth() + 1;
        const dd = date.getDate();
        const yy = date.getFullYear();

        return `${dd}/${mm}/${yy}`;
    }

    // COMMENT 1

    const [usersArray, setUsersArray] = useState(
        [ // COMMENT 2
        ]
    );

    const errToast = () => openToast( 
        console.log, // goToLoginAndRemoveTokensFromLS, 
        'error', 
        "You're not authorized for this operation." 
        );


    const [loadingAvailableUsers, setLoadingAvailableUsers] = useState(false);

    useEffect(() => {
        axios.get(endpoints.availableRoles, axiosOptions).then(res=>{
            const cleanedArr = [];
            try{
                const resArr = res.data.json_list;
                resArr.map(roleObj=>{
                    const newRoleObj =  {
                        id: roleObj.id,
                        name: roleObj.name
                    }
                    cleanedArr.push(newRoleObj);
                })
            } catch(err){
                console.log(err)
                errToast();
            }
            console.log(cleanedArr);
            setAvailableRoles(cleanedArr);

        }).catch(err=>{
            if(['unsupported authentication type', 'expired token'].includes(err.response?.data)) goToLoginAndRemoveTokensFromLS();
            console.log(err);
            // errToast();
        })
    }, [usersArray.length])

    useEffect(() => {
        setLoadingAvailableUsers(true);
        axios.get(endpoints.availableUsers, axiosOptions).then(res=>{
            const resObj = res.data;
            const dataArr = resObj.json_list;
            const cleanedArr = [];
            console.log('DATAAA', dataArr)
            try{
                if(dataArr){
                    if(dataArr.length>0){
                        dataArr.map(userObj=>{
                            const newUserObj = {
                                id: userObj.id,
                                username: userObj.username,
                                // name: userObj.username,
                                email: userObj.email,
                                role: [...userObj.role],
                                // {
                                //     name: userObj.role.name,
                                //     id: userObj.role.id
                                // },
                                visible: userObj.active,
                                usergroups: userObj.usergroups
                            }
                            cleanedArr.push(newUserObj);
                        })
                    }
                }
            }
            catch(err) {
                console.log(err);
                setLoadingAvailableUsers(false);
                openToast(
                    console.log,
                    'error',
                    err.message
                );
            }
        setUsersArray(cleanedArr);
        setLoadingAvailableUsers(false);
        console.log('CLEANEED',cleanedArr)
    }).catch(err=>{
        if(['unsupported authentication type', 'expired token'].includes(err.response?.data)) goToLoginAndRemoveTokensFromLS();
        console.log(err);
        setLoadingAvailableUsers(false);
        // errToast();
    });
    }, [usersArray.length])

    // const [userRoles, setUserRoles] = useState(['admin', 'user', 'superadmin']);

    const [userObj, setUserObj] = useState({
        name: '',
        email: '',
        userId: '',
        role: {
            name: '',
            id: 0
        },
        id: '',
        // userGroups: [],
    });

    const handleUserDeleteClick = (id) => {
        axios.delete(`${endpoints.deleteUser}/${id}`, axiosOptions).then((res)=>{
            setUsersArray(prev=>prev.filter(item=>item.id!==id));
            openToast(
                ()=>console.log('user deleted'),
                'success',
                "Successfully deleted user."
            );
        }).catch(err=>{
            if(['unsupported authentication type', 'expired token'].includes(err.response?.data)) goToLoginAndRemoveTokensFromLS();
            console.log('error:', err);
            errToast();
        })
    }

    const handleUserEditClick = (id) => {
        const selectedUserObj = usersArray.filter(item=>item.id===id)[0];
        setUpdateUser(true);
        setUserObj(selectedUserObj);
        setOpenAddNewUserCard(true);
    }

    const handleUserReviewClick = (id) => {
        const selectedUserObj = usersArray.filter(item=>item.id===id)[0];
        setViewUser(true);
        setUpdateUser(false);
        setUserObj(selectedUserObj);
        setOpenAddNewUserCard(true);
    }

    
    const [loadingAvailableUserGroups, setLoadingAvailableUserGroups] = useState(false);
    const [userGroups, setUserGroups] = useState([]);
    useEffect(() => {
        setLoadingAvailableUserGroups(true);
        axios.get(endpoints.availableUserGroups, axiosOptions).then(res=>{
            const resObj = res.data;
            const dataArr = resObj.json_list;
            const cleanedArr = [];
            console.log('RAW USER GROUPS', dataArr)
            try{
                if(dataArr){
                    if(dataArr.length>0){
                        dataArr.map(userGroupObj=>{
                            const newUserGroupObj = {
                                id: userGroupObj.id,
                                userGroupName: userGroupObj.name,
                                description: userGroupObj.description,
                                organizationId: userGroupObj.organization_id,
                                users: userGroupObj.users,
                                numOfUsers: userGroupObj?.users?.length,
                                created_on: dateMaker(new Date(userGroupObj.created_at))
                            }
                            cleanedArr.push(newUserGroupObj);
                        })
                    }
                }
            }
            catch(err) {
                console.log(err);
                setLoadingAvailableUserGroups(false);
                openToast(
                    console.log,
                    'error',
                    err.message
                );
            }
        setUserGroups(cleanedArr);
        setLoadingAvailableUserGroups(false);
        console.log('CLEANEED USER GROUPS',cleanedArr)
    }).catch(err=>{
        if(['unsupported authentication type', 'expired token'].includes(err.response?.data)) goToLoginAndRemoveTokensFromLS();
        console.log('ERROR IN REQUEST',err);
        setLoadingAvailableUserGroups(false);
        errToast();
    });
    }, [userGroups.length])

    return (
        <div className="flex flex-col w-full space-y-16 transition-all">
            <div className="flex items-center space-x-5">
                <div className="mt-8 ml-10 blue-heading">Users</div>
                <button disabled={loadingAvailableUsers} onClick={()=>{
                    setUpdateUser(false);
                    setUserObj({
                        name: '',
                        email: '',
                        // userId: '',
                        // role: {
                        //     name: availableRoles[0].name,
                        //     id: availableRoles[0].id
                        // },
                        // id: '',
                        // userGroups: [],
                    });
                    setOpenAddNewUserCard(true);
                    }} 
                    // style={{boxShadow:'0px 8px 18px #FFC9CB'}} 
                    className={`mt-8 ${loadingAvailableUsers ? 'opacity-25' : 'opacity-100'} text-sm text-white shadow-2xl w-36 h-9 bg-red rounded-xl`}>
                        Add New
                </button>
            </div>
            {
                openAddNewUserCard && 
                <span className="w-full">
                    <AddNewUserCard
                        userGroups={userGroups}
                        viewUser = {viewUser} 
                        setViewUser = {setViewUser}
                        updateUser={updateUser}
                        setUpdateUser={setUpdateUser}
                        openToast={openToast}
                        usersArray={usersArray}
                        setUsersArray={setUsersArray}
                        userObj={userObj}
                        // userRoles={userRoles}
                        axiosOptions={axiosOptions}
                        goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
                        endpoints={endpoints}
                        setOpenAddNewUserCard={setOpenAddNewUserCard} 
                        availableRoles={availableRoles}
                    />
                </span>
            }
            <div style={{boxShadow:'3px 4px 12px rgba(0, 0, 0, 0.19)'}} className="w-11/12 p-5 pt-10 mx-10 h-96 rounded-xl">
                <UsersTable 
                    viewUser={viewUser} 
                    setViewUser={setViewUser} 
                    handleUserReviewClick={handleUserReviewClick} 
                    EmptyTableSVG={EmptyTableSVG} 
                    loadingAvailableUsers={loadingAvailableUsers} 
                    LoadingAnimation={LoadingAnimation} 
                    key={usersArray.length} 
                    handleUserEditClick={handleUserEditClick} 
                    handleUserDeleteClick={handleUserDeleteClick} 
                    usersArray={usersArray} 
                    />
            </div> 
        </div>
    )
}

const UsersTable = ({ handleUserReviewClick, EmptyTableSVG, LoadingAnimation, loadingAvailableUsers, usersArray, handleUserDeleteClick, handleUserEditClick }) => {

    return (
        <div className={usersArray.length>5 ? "space-y-5 overflow-x-scroll overflow-y-scroll lg:overflow-x-hidden h-72" : "space-y-5 overflow-x-scroll overflow-y-visible lg:overflow-x-hidden h-72"}>
            <span className="flex items-center font-semibold text-center lg:space-x-6 text-grey-lightest flex-nowrap ">
                <span className="w-5 ml-5 text-center lg:w-3/12">Username</span>
                {/* <span className="w-14 lg:w-14">Name</span> */}
                <span className="w-16 text-center lg:w-4/12">Email</span>
                {/* <span className="text-center w-18 lg:w-2/12 md:w-18 -pl-20 ">User Role</span> */}
                <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-2/12"></span>
            </span>
            
            {
                !loadingAvailableUsers ?
                <span className="flex flex-col w-full pt-8 space-y-5">
                    {
                        usersArray.length>0 ?
                        <>
                            { 
                                usersArray.map((userObj, index) => 
                                    <RowItem
                                        handleUserReviewClick={handleUserReviewClick} 
                                        handleUserEditClick={handleUserEditClick} 
                                        handleUserDeleteClick={handleUserDeleteClick}  
                                        userObj={userObj} 
                                        index={index} 
                                        key={index} 
                                        />
                                    )
                            }
                        </>
                        :
                        <span className="flex flex-col items-center justify-center w-full space-y-8">
                                <span className="w-44"><img src={EmptyTableSVG} alt="" /></span>
                                <span className="text-xl font-semibold w-44 text-red">No Users Found</span>
                        </span>
                    }
                </span>
                :
                <span className="pt-20 center"><LoadingAnimation /> </span>
            }
        </div>
    )
}

const RowItem = ({ userObj, index, handleUserReviewClick, handleUserDeleteClick, handleUserEditClick }) => {

    const [openConfirmationCard, setOpenConfirmationCard] = useState(false);

    return (
        <span key={userObj.id} className="flex">
            <Checkbox>
                <span className="w-24 text-center break-all lg:w-3/12 ">{userObj.username}</span>
                {/* <span className="w-24 break-all lg:w-24">{userObj.name}</span> */}
                <span className="flex flex-wrap justify-center text-center break-all w-44 lg:w-4/12 ">{userObj.email}</span>
                {/* <span className="w-24 text-center lg:pl-7 lg:w-2/12 md:w-14 -pl-20">{userObj.role.name}</span> */}
                {/* <span className="w-20">
                    { surveyGroup.status==='active' ? <Active />: <Inactive /> }
                </span> */}
                <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-2/12">
                    <span onClick={()=>handleUserReviewClick(userObj.id)} data-background-color="#8B0021" data-tip="View" className="pointer">
                    {
                        userObj.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span>
                    <span data-background-color="#8B0021" data-tip="Edit" onClick={()=>handleUserEditClick(userObj.id)} className="pointer">
                        <EditIcon />
                    </span>
                    <span data-background-color="#8B0021" data-tip="Delete" onClick={()=>setOpenConfirmationCard(true)} className="pointer">
                        <DeleteIcon />
                    </span>
                    {
                        openConfirmationCard &&
                        <ConfirmationCard deleteFunc={()=>handleUserDeleteClick(userObj.id)} setOpenConfirmationCard={setOpenConfirmationCard}/>
                    }
                </span>
            </Checkbox>
            {/* <span className="flex justify-end w-2/4 pr-10 space-x-3">
                    <span className="pointer">
                    {
                        surveyGroup.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span>
                    <span className="pointer">
                        <EditIcon />
                    </span>
                    <span className="pointer">
                        <DeleteIcon />
                    </span>
            </span> */}
            <ReactTooltip />
        </span>
    )
}

const Active = () => <div className="space-x-2 center"><span><ActiveIcon /></span><span>Active</span></div>
const Inactive = () => <span className="space-x-2 center"><span><InactiveIcon /></span><span>Inactive</span></span>

const Checkbox = ({ children }) => {

    return (
        <div className="flex items-start w-full font-semibold text-blue">
            {/* <span className="mt-1 ml-9">{checked ? <CheckedIcon /> : <UncheckedIcon /> }</span> */}
            <span className="flex w-full ml-5 lg:space-x-6 space-x-14 md:space-x-14">{children}</span> 
        </div>
    )
}

export default UsersPage
