import React, { useState, useEffect } from "react";

import axios from "axios";
import PermissionTypesRadio from "./PermissionTypesRadio";
import ResourceSpecificSection from "./ResourceSpecificSection";

import { finalJsonMaker, finalJsonMakerForSP } from "./utils";
import SuperPermissionsSection from "./SuperPermissionsSection";

const AddNewRoleCard = ({
  viewRole,
  setViewRole,
  openToast,
  updateRole,
  setUpdateRole,
  usersArray,
  setUsersArray,
  availableRoles,
  setOpenAddNewRoleCard,
  roleObj,
  setRoleObj,
  endpoints,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
  setAvailableRoles,
  availableSurveys,
  surveyGroups,
}) => {
  // SUPER PERMISSIONS
  const [spForUG, setSPForUG] = useState(false);
  const [spForUsers, setSPForUsers] = useState(false);
  const [spForSurveys, setSPForSurveys] = useState(false);
  const [spForSG, setSPForSG] = useState(false);
  const [spForRoles, setSPForRoles] = useState(false);
  //
  const [selectedRole, setSelectedRole] = useState(roleObj?.role);
  const [roleName, setRoleName] = useState(roleObj?.name ? roleObj.name : "");
  const [roleDescription, setRoleDescription] = useState(
    roleObj?.description ? roleObj.description : ""
  );
  const [roleId, setRoleId] = useState(roleObj?.id ? roleObj.id : "");
  // const [password, setPassword] = useState('');

  const [superPermissions, setSuperPermissions] = useState(true);

  // update
  useEffect(() => {
    if (roleObj?.permissions?.length) {
      const perms = roleObj.permissions;
      if (perms[0]?.resource_id === null) {
        // super permissions
        const resourceTypes = perms.map((perm) => perm.resource_type);
        if (resourceTypes.includes("user")) setSPForUsers(true);
        if (resourceTypes.includes("usergroup")) setSPForUG(true);
        if (resourceTypes.includes("survey")) setSPForSurveys(true);
        if (resourceTypes.includes("surveygroup")) setSPForSG(true);
        if (resourceTypes.includes("role")) setSPForRoles(true);
      } else setSuperPermissions(false);
      // if(perms[0]?.resource_id!==null){ // resource specific permissions
      //     setSuperPermissions(false);
      // }
    }
  }, [roleObj]);

  useEffect(() => {
    console.log("roleObj", roleObj);
  }, []);

  const errToast = () =>
    openToast(
      console.log, //goToLoginAndRemoveTokensFromLS,
      "error",
      "You do not have permissions for this operation."
    );
  const errToastWithMsg = (msg) => openToast(console.log, "error", msg);

  const sendNewRoleToApi = (roleObjToSend) => {
    axios
      .post(endpoints.createRole, roleObjToSend, axiosOptions)
      .then((res) => {
        // if (res.body==='survey created') console.log(res);
        if (res.status === 201) {
          setOpenAddNewRoleCard(false);
          const prev = [...availableRoles];
          // roleObjToSend.name= roleObjToSend.roleName;
          prev.push(roleObjToSend);
          setAvailableRoles(prev);
          openToast(
            () => console.log("Role created"),
            "success",
            "Successfully Created Role."
          );
        } else {
          console.log(res);
          // errToast();
        }
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        console.log(err);
        errToastWithMsg(`error: ${err.response?.data}`);
      });
  };

  const sendEditRoleToApi = (roleId, roleObjToSend) => {
    axios
      .post(`${endpoints.updateRole}/${roleId}`, roleObjToSend, axiosOptions)
      .then((res) => {
        // if (res.body==='survey created') console.log(res);
        if (res.status === 201) {
          setOpenAddNewRoleCard(false);
          const prev = [...usersArray];
          const filteredArr = prev.filter((item) => item.id !== roleId);
          filteredArr.push(roleObjToSend);
          // prev.push(roleObjToSend)
          setUsersArray(filteredArr);
          setUpdateRole(false);
          setRoleObj({});
          openToast(
            () => console.log("Role updated"),
            "success",
            "Successfully Updated Role."
          );
        } else {
          console.log(res);
          setUpdateRole(false);
          errToast();
        }
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        console.log(err);
        setUpdateRole(false);
        errToastWithMsg(`error: ${err.response?.data}`);
      });
  };

  const [userGroups, setUserGroups] = useState([]);
  const [loadingAvailableUserGroups, setLoadingAvailableUserGroups] = useState(
    false
  );

  useEffect(() => {
    setLoadingAvailableUserGroups(true);
    axios
      .get(endpoints.availableUserGroups, axiosOptions)
      .then((res) => {
        const resObj = res.data;
        const dataArr = resObj.json_list;
        const cleanedArr = [];
        console.log("RAW USER GROUPS", dataArr);
        try {
          if (dataArr) {
            if (dataArr.length > 0) {
              dataArr.map((userGroupObj) => {
                const newUserGroupObj = {
                  id: userGroupObj.id,
                  userGroupName: userGroupObj.name,
                  description: userGroupObj.description,
                  organizationId: userGroupObj.organization_id,
                  users: userGroupObj.users,
                  numOfUsers: userGroupObj?.users?.length,
                  // created_on: dateMaker(new Date(userGroupObj.created_at))
                };
                cleanedArr.push(newUserGroupObj);
              });
            }
          }
        } catch (err) {
          console.log(err);
          setLoadingAvailableUserGroups(false);
          openToast(console.log, "error", err.message);
        }
        setUserGroups(cleanedArr);
        setLoadingAvailableUserGroups(false);
        console.log("CLEANEED USER GROUPS", cleanedArr);
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        console.log("ERROR IN REQUEST", err);
        setLoadingAvailableUserGroups(false);
        errToast();
      });
  }, [userGroups.length]);

  const [permissionsArrUG, setPermissionsArrUG] = useState([]);
  const [permissionsArrSurveys, setPermissionsArrSurveys] = useState([]);
  const [permissionsArrSG, setPermissionsArrSG] = useState([]);
  const [permissionsArrRoles, setPermissionsArrRoles] = useState([]);

  const [removePermUG, setRemovePermUG] = useState(false);
  const [removePermSurveys, setRemovePermSurveys] = useState(false);
  const [removePermSG, setRemovePermSG] = useState(false);
  const [removePermRoles, setRemovePermRoles] = useState(false);

  const handleCreateRoleClick = () => {
    if (!superPermissions) {
      const roleObjToSend = finalJsonMaker(
        roleName,
        roleDescription,
        permissionsArrUG,
        permissionsArrSurveys,
        permissionsArrSG,
        permissionsArrRoles
      );
      if (!roleObjToSend || !roleName?.length) return;
      if (updateRole) sendEditRoleToApi(roleId, roleObjToSend);
      if (!updateRole) sendNewRoleToApi(roleObjToSend);
      console.log("roleObjToSend", roleObjToSend);
    }
    if (superPermissions) {
      const roleObjToSend = finalJsonMakerForSP(
        roleName,
        roleDescription,
        spForUsers,
        spForUG,
        spForSurveys,
        spForSG,
        spForRoles
      );
      console.log("roleObjToSend", roleObjToSend);
      if (updateRole) sendEditRoleToApi(roleId, roleObjToSend);
      if (!updateRole) sendNewRoleToApi(roleObjToSend);
    }
  };

  // useEffect(() => {
  //     console.log('availableSurveys',availableSurveys);
  // }, [])

  useEffect(() => {
    if (removePermUG) setPermissionsArrUG([]);
    if (removePermSurveys) setPermissionsArrSurveys([]);
    if (removePermSG) setPermissionsArrSG([]);
    if (removePermRoles) setPermissionsArrRoles([]);
  }, [removePermUG, removePermSurveys, removePermSG, removePermRoles]);

  useEffect(() => {
    if (!superPermissions) {
      setRemovePermUG(false);
      setRemovePermSurveys(false);
      setRemovePermSG(false);
      setRemovePermRoles(false);
    }
  }, [superPermissions]);

  // On Edit
  // const itemGetterById = (id, type) => {
  //     const data = type?.filter((item)=> id===item.id);
  //     if(data?.length) return data[0];
  //     else return null;
  // }

  useEffect(() => {
    if (!updateRole) return;
    const types = ["usergroup", "user", "survey", "surveygroup", "role"];
    const extracted = {
      usergroup: {},
      survey: {},
      surveygroup: {},
      role: {},
    };
    types.map((type) => {
      roleObj.permissions?.map((perm) => {
        if (perm.resource_type === type) {
          // const allowed_actions = extracted[type]?.[perm.resource_id]?.allowed_actions ? extracted[type][perm.resource_id]?.allowed_actions : null;
          // if(allowed_actions) extracted[type][perm.resource_id].allowed_actions=[...allowed_actions, perm.allowed_action];
          // else extracted[type][perm.resource_id].allowed_actions=[perm.allowed_action]
          const prev = extracted[type]?.[perm.resource_id]?.allowed_actions;

          const exists = extracted[type]?.[perm.resource_id];
          if (exists) {
            extracted[type][perm.resource_id] = {
              allowed_actions: [
                prev
                  ? [prev.flat(), perm.allowed_action].flat()
                  : perm.allowed_action,
              ].flat(),
            };
          }
          // extracted[type][perm.resource_id].allowed_actions.push(perm.allowed_action)
        }
      });
    });
    console.log("extracted", extracted);

    Object.keys(extracted).map((type) => {
      const permArrTemp = [];
      Object.keys(extracted[type]).map((key) => {
        permArrTemp.push({
          id: Number(key),
          allowed_actions: extracted[type][key].allowed_actions,
          resource_type: type,
        });
      });
      if (type === "survey") setPermissionsArrSurveys(permArrTemp);
      if (type === "surveygroup") setPermissionsArrSG(permArrTemp);
      if (type === "usergroup") setPermissionsArrUG(permArrTemp);
      if (type === "role") setPermissionsArrRoles(permArrTemp);

      console.log("permArrTemp", permArrTemp);
    });
    // roleObj.permissions.map(item=>console.log(item))
    // console.log('surveyGetter(13)',itemGetterById(17, availableSurveys));
  }, []);

  return (
    <div
      style={{
        boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.19)",
        minWidth: "50%",
      }}
      className="absolute w-8/12 pb-10 space-y-5 bg-white left-56 top-24 rounded-xl"
    >
      <div className="flex flex-col p-8 pl-10 pr-16">
        <span className="flex items-center justify-between mb-4">
          <span className="text-2xl font-semibold text-blue-dark">
            {updateRole
              ? "Update Role"
              : viewRole
              ? "View Role"
              : "Create Role"}
          </span>
          <span className="flex space-x-4">
            <button
              onClick={() => {
                setOpenAddNewRoleCard(false);
                setViewRole(false);
                setUpdateRole(false);
                setRoleObj({});
              }}
              className="btn-grey"
            >
              Cancel
            </button>
            {!viewRole && (
              <button
                disabled={!roleName?.length}
                onClick={handleCreateRoleClick}
                className={`${
                  roleName?.length ? "btn-red" : "btn-red-disabled"
                }`}
              >
                {updateRole
                  ? "Update Role"
                  : viewRole
                  ? "View Role"
                  : "Create Role"}
              </button>
            )}
          </span>
        </span>
      </div>

      <div className="pr-5 mx-10 space-y-5">
        <EditField
          viewRole={viewRole}
          type="text"
          input={roleName}
          setInput={setRoleName}
          placeholder="Role Name"
        />
        <EditField
          viewRole={viewRole}
          type="text"
          input={roleDescription}
          setInput={setRoleDescription}
          placeholder="Role Description"
        />
        <PermissionTypesRadio
          title1="Super Permissions"
          title2="Resource Specific Permissions"
          value={superPermissions}
          setToggle={setSuperPermissions}
        />
        <span>
          {!superPermissions && (
            <ResourceSpecificSection
              permissions={roleObj.permissions}
              updateRole={updateRole}
              userGroups={userGroups}
              permissionsArrUG={permissionsArrUG}
              setPermissionsArrUG={setPermissionsArrUG}
              availableSurveys={availableSurveys}
              permissionsArrSurveys={permissionsArrSurveys}
              setPermissionsArrSurveys={setPermissionsArrSurveys}
              surveyGroups={surveyGroups}
              permissionsArrSG={permissionsArrSG}
              setPermissionsArrSG={setPermissionsArrSG}
              availableRoles={availableRoles}
              permissionsArrRoles={permissionsArrRoles}
              setPermissionsArrRoles={setPermissionsArrRoles}
              removePermUG={removePermUG}
              setRemovePermUG={setRemovePermUG}
              removePermSurveys={removePermSurveys}
              setRemovePermSurveys={setRemovePermSurveys}
              removePermSG={removePermSG}
              setRemovePermSG={setRemovePermSG}
              removePermRoles={removePermRoles}
              setRemovePermRoles={setRemovePermRoles}
            />
          )}
          {superPermissions && (
            <SuperPermissionsSection
              spForUG={spForUG}
              setSPForUG={setSPForUG}
              spForUsers={spForUsers}
              setSPForUsers={setSPForUsers}
              spForSurveys={spForSurveys}
              setSPForSurveys={setSPForSurveys}
              spForSG={spForSG}
              setSPForSG={setSPForSG}
              spForRoles={spForRoles}
              setSPForRoles={setSPForRoles}
            />
          )}
        </span>
      </div>
    </div>
  );
};

const EditField = ({ viewRole, type, input, placeholder, setInput }) => {
  return (
    <>
      {
        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          disabled={viewRole}
          // onKeyDownCapture={(e)=>{if(e.key==='Enter') setEditing(false)}}
          // autoFocus
          className="w-full px-10 py-2 font-semibold border grey-placeholder text-grey-lightest border-grey-lightest rounded-xl focus:outline-none"
          placeholder={placeholder}
          type={type}
        />
      }
    </>
  );
};

// const SelectField = ({ viewRole, userRoles, selectedRole , setSelectedRole }) => {

//     // const [editing, setEditing] = useState(false);

//     const handleOnChange = (e) => {
//         const filteredRoles = userRoles.filter(item=>item.name===e.target.value);
//         const id = filteredRoles[0].id;
//         setSelectedRole({
//             name: e.target.value,
//             id: id
//         });
//         setTimeout(() => {
//             console.log(selectedRole);
//         }, 1000);
//     }

//     return (
//         <>
//             <select
//             disabled={viewRole}
//             // id={selectedRole.id}
//             onChange={handleOnChange}
//             value={selectedRole.name} className="content-center pl-10 font-semibold text-center rounded-lg text-grey-lightest border-grey-lightest w-60" >
//                 {
//                     userRoles.map((item, index)=><option key={item.id} id={item.id} tabIndex={index} className="font-semibold text-grey">{item.name}</option>)
//                 }
//             </select>
//         </>
//     )

// }

// const UserGroupInput = ({ type, input, placeholder, setInput }) => {

//     const [editing, setEditing] = useState(false);

//     return (
//         <>
//         {
//             editing ?
//             <input onChange={(e)=>setInput(e.target.value)} onKeyDownCapture={(e)=>{if(e.key==='Enter') setEditing(false)}} autoFocus className="w-full px-10 py-2 font-semibold border grey-placeholder text-grey-lightest border-grey-lightest rounded-xl focus:outline-none" placeholder={placeholder} type={type} />
//             :
//             <div onClick={()=>setEditing(true)} className="w-full px-10 py-2 font-semibold border text-grey-lightest pointer border-grey-lightest rounded-xl focus:outline-none">
//                 {input ? `${input}` : `${placeholder}`}
//             </div>
//         }
//         </>
//     )

// }

export default AddNewRoleCard;
