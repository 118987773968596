import React from 'react';
import { v4 as uuid } from 'uuid';

const AddNewBtn = ({ setQuestions }) => {

    const addNewQuestion = ()=> setQuestions(prev=>[...prev, 
        {
            id: uuid(),
            title: '',
            description: '',
            options: ['Option', 'Option'],
            type: 'textbox',
            enableOtherResponse: false,
            otherResponseLabel: '',
            mandatory: false,
            isVisible: true,
            depends: false,
        },
])

    return (
        <div className="flex justify-center mt-5">
            <button onClick={addNewQuestion} className="w-48 py-1 font-semibold transition-all border-2 hover:bg-red hover:text-white text-red border-red rounded-xl">Add New Question</button>
        </div>
    )
}

export default AddNewBtn
