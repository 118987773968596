const dateFormatter = (date) => {
    // yyyy-mm-dd hh-mm-ss
    const dd = date.getDate();
    const mm = date.getMonth() + 1; // because starts from zero
    const yyyy = date.getFullYear();

    const HH = date.getHours();
    const MM = date.getMinutes();
    const SS = date.getSeconds();

    return `${yyyy}-${mm}-${dd} ${HH}:${MM}:${SS}`
}

export default dateFormatter;