import React, {useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';

import {
    SelectedDivider,
    Divider,
    ArrowIcon,
    SelectedArrowIcon,
    HomeIcon,
    SelectedHomeIcon,
    DocumentIcon,
    SelectedDocumentIcon,
    ProfileIcon,
    SelectedProfileIcon,
    PeopleIcon,
    SelectedPeopleIcon,
    RolesIcon,
    SelectedRolesIcon,
} from './Icons';
// import EmailSVG from '../assets/email.svg';

const Sidebar = ({ selectedPage, setSelectedPage, setOpenNewSurveyCard, resetCompleteSurvey, setPageOpen }) => {

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleMenuItemClick = (pageName) => {
        setOpenNewSurveyCard(false);
        setPageOpen('create');
        setSelectedPage(pageName);
        resetCompleteSurvey();
    }

    const handleClickWhenOpenSidebar = (page) => {
        setPageOpen('create');
        setOpenSidebar(false);
        handleMenuItemClick(page);
    }

    return (
        <>
        <div style={{marginTop:'72px'}} 
            className={openSidebar?"w-96 bg-grey-dark transition-all":"w-24 bg-grey-dark transition-all"}
        >
        {console.log(selectedPage)}
        {
            openSidebar ?
            <div className="fixed flex flex-col items-start justify-start h-screen pt-10 pl-10 transition-all w-72 space-y-7 bg-grey-dark">
                <a className="pointer" onClick={()=>setOpenSidebar(false)}><span className="menuItem"><span><SelectedArrowIcon /></span><span className='text-xl selectedMenuItemText'>Collapse</span></span></a>
                <span><SelectedDivider /></span>
                <a className="pointer" onClick={()=>handleClickWhenOpenSidebar('home')}>
                    <span className="menuItem"><span>{selectedPage === 'home' ? <SelectedHomeIcon /> : <HomeIcon />}</span><span className={selectedPage === 'home' ? 'selectedMenuItemText' : 'menuItemText'}>Home</span></span>
                    </a>
                <a className="pointer" onClick={()=>handleClickWhenOpenSidebar('survey_groups')}>
                    <span className="menuItem"><span>{selectedPage === 'survey_groups' ? <SelectedDocumentIcon /> : <DocumentIcon />}</span><span className={selectedPage === 'survey_groups' ? 'selectedMenuItemText' : 'menuItemText'}>Survey Groups</span></span>
                    </a>
                <a className="pointer" onClick={()=>handleClickWhenOpenSidebar('users')}>
                    <span className="menuItem"><span>{selectedPage === 'users' ? <SelectedProfileIcon /> : <ProfileIcon />}</span><span className={selectedPage === 'users' ? 'selectedMenuItemText' : 'menuItemText'}>Users</span></span>
                    </a>
                <a className="pointer" onClick={()=>handleClickWhenOpenSidebar('user_groups')}>
                    <span className="menuItem"><span>{selectedPage === 'user_groups' ? <SelectedPeopleIcon /> : <PeopleIcon />}</span><span className={selectedPage === 'user_groups' ? 'selectedMenuItemText' : 'menuItemText'}>User Groups</span></span>
                </a>
                <a className="pointer" onClick={()=>handleClickWhenOpenSidebar('roles')}>
                    <span className="menuItem"><span>{selectedPage === 'roles' ? <SelectedRolesIcon /> : <RolesIcon />}</span><span className={selectedPage === 'roles' ? 'selectedMenuItemText' : 'menuItemText'}>Roles</span></span>
                </a>
                <a className="pointer" onClick={()=>handleClickWhenOpenSidebar('emails')}>
                    <span className="menuItem"><span>{selectedPage === 'emails' ? <SelectedAlertIcon /> : <AlertIcon />}</span><span className={selectedPage === 'emails' ? 'selectedMenuItemText' : 'menuItemText'}>Alerts</span></span>
                </a>
            </div>

            :

            <div className="flex flex-col items-center justify-start w-24 h-screen pt-10 transition-all space-y-7 bg-grey-dark">
                <a className="pointer" onClick={()=>setOpenSidebar(true)}><ArrowIcon /></a>
                <span><Divider /></span>
                <SidebarLinkClosed goto='home' dataTip = {"Home"} handleMenuItemClick = { handleMenuItemClick }>
                    <span>{selectedPage === 'home' ? <SelectedHomeIcon /> : <HomeIcon />}</span>
                </SidebarLinkClosed>
                <SidebarLinkClosed goto='survey_groups' dataTip = {"Survey Groups"} handleMenuItemClick = { handleMenuItemClick }>
                    <span>{selectedPage === 'survey_groups' ? <SelectedDocumentIcon /> : <DocumentIcon />}</span>
                </SidebarLinkClosed>
                <SidebarLinkClosed goto='users' dataTip = {"Users"} handleMenuItemClick = { handleMenuItemClick }>
                    <span>{selectedPage === 'users' ? <SelectedProfileIcon /> : <ProfileIcon />}</span>
                </SidebarLinkClosed>
                <SidebarLinkClosed goto='user_groups' dataTip = {"User Groups"} handleMenuItemClick = { handleMenuItemClick }>
                    <span>{selectedPage === 'user_groups' ? <SelectedPeopleIcon /> : <PeopleIcon />}</span>
                </SidebarLinkClosed>
                <SidebarLinkClosed goto='roles' dataTip = {"Roles"} handleMenuItemClick = { handleMenuItemClick }>
                    <span>{selectedPage === 'roles' ? <SelectedRolesIcon /> : <RolesIcon />}</span>
                </SidebarLinkClosed>
                <SidebarLinkClosed goto='emails' dataTip = {"Alerts"} handleMenuItemClick = { handleMenuItemClick }>
                    <span>{selectedPage === 'emails' ? <SelectedAlertIcon /> : <AlertIcon />}</span>
                </SidebarLinkClosed>
            </div>
        }
        </div>
        </>
    )
}

const SidebarLinkClosed = (props) => {

    const {
        goto,
        dataTip,
        handleMenuItemClick,
        children
    } = props;

    return (
        <span data-effect="solid" data-place="right" data-background-color="#8B0021" data-tip={dataTip} className="pointer" onClick={()=>handleMenuItemClick(goto)}>
            { children }
        </span>
    )
}


const SelectedAlertIcon = () => <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#8B0021"><path d="M10.01 21.01c0 1.1.89 1.99 1.99 1.99s1.99-.89 1.99-1.99h-3.98zM12 6c2.76 0 5 2.24 5 5v7H7v-7c0-2.76 2.24-5 5-5zm0-4.5c-.83 0-1.5.67-1.5 1.5v1.17C7.36 4.85 5 7.65 5 11v6l-2 2v1h18v-1l-2-2v-6c0-3.35-2.36-6.15-5.5-6.83V3c0-.83-.67-1.5-1.5-1.5zM13 8h-2v3H8v2h3v3h2v-3h3v-2h-3z"/></svg>
const AlertIcon = () => <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#878787"><path d="M10.01 21.01c0 1.1.89 1.99 1.99 1.99s1.99-.89 1.99-1.99h-3.98zM12 6c2.76 0 5 2.24 5 5v7H7v-7c0-2.76 2.24-5 5-5zm0-4.5c-.83 0-1.5.67-1.5 1.5v1.17C7.36 4.85 5 7.65 5 11v6l-2 2v1h18v-1l-2-2v-6c0-3.35-2.36-6.15-5.5-6.83V3c0-.83-.67-1.5-1.5-1.5zM13 8h-2v3H8v2h3v3h2v-3h3v-2h-3z"/></svg>

export default Sidebar
