import React, {useState} from 'react'

const ReviewHorizontal = ({ options, enableOtherResponse }) => {
    
    return (
        <div className="flex justify-center space-x-3 transition-all center mb-7">
            {
                options.map(option=> <Option option={option}/>)
            }
            {
                enableOtherResponse &&
                    <Option option="Other"/>
            }
        </div>
    )
}

const Option = ({ option }) => {
    const [optionValue, setOptionValue] = useState(false);
    const handleClick = () => {
        setOptionValue((prev)=>!prev);
    }

    return (
    <>
    
        {
            optionValue ?
            <div onClick={()=>handleClick()} className="flex flex-wrap items-center justify-center max-w-xs px-5 py-2 font-semibold text-center text-white break-all min-w-min pointer rounded-xl bg-red">
                {option}
            </div>
            :
            <div onClick={()=>handleClick()} className="flex flex-wrap items-center justify-center max-w-xs px-5 py-2 font-semibold text-center break-all min-w-min pointer rounded-xl bg-grey-light text-blue-dark">
                {option}
            </div>
        }
    </>
    )
}


export default ReviewHorizontal
