// export const url = 'http://3.86.205.95:8000';

export const url = 'https://surveyagilitybackend.agiinnov.com';

export const endpoints = {
    simpleLogin: `${url}/auth/login`,
    simpleSignup: `${url}/auth/signup`,
    ssoLogin: `${url}/auth/login/sso`,
    simpleLogout: `${url}/auth/logout`,
    superAdminLogin: `${url}/auth/login`,

    getCurrentUser: `${url}/auth/logout`,
    
    createSurvey: `${url}/survey/create`,
    availableSurveys: `${url}/survey/index?surveygroups`,
    deleteSurvey: `${url}/survey/delete`,
    updateSurvey: `${url}/survey/update`,
    
    availableUsers: `${url}/user/index?roles&usergroups`,
    createUser: `${url}/user/create`,
    updateUser: `${url}/user/update`,
    deleteUser: `${url}/user/delete`,
    
    availableRoles: `${url}/role/index?permissions`,
    createRole: `${url}/role/create`,
    deleteRole: `${url}/role/delete`,
    updateRole: `${url}/role/update`,
    
    availableSurveyGroups: `${url}/surveygroup/index?surveys`,
    createSurveyGroups: `${url}/surveygroup/create`,
    deleteSurveyGroups: `${url}/surveygroup/delete`,
    updateSurveyGroups: `${url}/surveygroup/update`,

    availableUserGroups: `${url}/usergroup/index?users&surveys&surveygroups`,
    createUserGroup: `${url}/usergroup/create`,
    updateUserGroup: `${url}/usergroup/update`,
    deleteUserGroup: `${url}/usergroup/delete`,
    
    listAlert: `${url}/alert/index`,
    updateAlert: `${url}/alert/update`,
    updateAllAlert: `${url}/alert/update/all`,
    createAlert: `${url}/alert/create`,
    deleteAlert: `${url}/alert/delete`,
  }