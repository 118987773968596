import React, { useState, useEffect } from "react";
import AddNewUserGroupCard from "./AddNewUserGroupCard";

import axios from "axios";

import { EditIcon, DeleteIcon } from "../../Icons";
import { dateMaker } from "./utils";
import ConfirmationCard from "../../ConfirmationCard";
import ReactTooltip from "react-tooltip";

const UserGroupsPage = ({
  openToast,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
  endpoints,
  LoadingAnimation,
  EmptyTableSVG,
  availableSurveys,
  surveyGroups,
}) => {
  const [openAddNewUserGroupCard, setOpenAddNewUserGroupCard] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);

  const [userGroups, setUserGroups] = useState([]); // COMMENT 1
  const [updateUserGroup, setUpdateUserGroup] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState({
    name: "",
    description: "",
    users: [],
  });

  // AVAILABLE USERS

  const errToast = () =>
    openToast(
      console.log,
      "error",
      "You're not authorized for this operation."
    );

  const [loadingAvailableUsers, setLoadingAvailableUsers] = useState(false);

  useEffect(() => {
    setLoadingAvailableUsers(true);
    axios
      .get(endpoints.availableUsers, axiosOptions)
      .then((res) => {
        const resObj = res.data;
        const dataArr = resObj.json_list;
        const cleanedArr = [];
        console.log("RAW USERS", dataArr);
        try {
          if (dataArr) {
            if (dataArr.length > 0) {
              dataArr.map((userObj) => {
                const newUserObj = {
                  id: userObj.id,
                  username: userObj.username,
                  name: userObj.name,
                  email: userObj.email,
                  // role: {
                  //     name: userObj.role.name,
                  //     id: userObj.role.id
                  // },
                  // visible: userObj.active,
                };
                cleanedArr.push(newUserObj);
              });
            }
          }
        } catch (err) {
          console.log(err);
          setLoadingAvailableUsers(false);
          openToast(console.log, "error", err.message);
        }
        setAvailableUsers(cleanedArr);
        setLoadingAvailableUsers(false);
        console.log("CLEANEED USERS", cleanedArr);
      })
      .catch((err) => {
        console.log(err);
        setLoadingAvailableUsers(false);
        errToast();
      });
  }, [userGroups.length]);

  // AVAILABLE USERS

  // const [userGroupsArray, setUserGroupsArray] = useState([]);

  const [loadingAvailableUserGroups, setLoadingAvailableUserGroups] = useState(
    false
  );

  useEffect(() => {
    setLoadingAvailableUserGroups(true);
    axios
      .get(endpoints.availableUserGroups, axiosOptions)
      .then((res) => {
        const resObj = res.data;
        const dataArr = resObj.json_list;
        const cleanedArr = [];
        console.log("RAW USER GROUPS", dataArr);
        try {
          if (dataArr) {
            if (dataArr.length > 0) {
              dataArr.map((userGroupObj) => {
                const newUserGroupObj = {
                  id: userGroupObj.id,
                  userGroupName: userGroupObj.name,
                  description: userGroupObj.description,
                  organizationId: userGroupObj.organization_id,
                  users: userGroupObj.users,
                  numOfUsers: userGroupObj?.users?.length
                    ? userGroupObj?.users?.length
                    : 0,
                  created_on: dateMaker(new Date(userGroupObj.created_at)),
                  surveys: userGroupObj.surveys?.length
                    ? userGroupObj.surveys.map((survey) => ({
                        name: survey.name,
                        id: survey.id,
                        created_on: dateMaker(new Date(survey.created_at)),
                      }))
                    : [],
                  surveygroups: userGroupObj.surveygroups?.length
                    ? userGroupObj.surveygroups.map((surveygroup) => ({
                        surveyGroupName: surveygroup.name,
                        id: surveygroup.id,
                        created_on: dateMaker(new Date(surveygroup.created_at)),
                      }))
                    : [],
                };
                cleanedArr.push(newUserGroupObj);
              });
            }
          }
        } catch (err) {
          console.log(err);
          setLoadingAvailableUserGroups(false);
          openToast(console.log, "error", err.message);
        }
        setUserGroups(cleanedArr);
        setLoadingAvailableUserGroups(false);
        console.log("CLEANEED USER GROUPS", cleanedArr);
      })
      .catch((err) => {
        console.log("ERROR IN REQUEST", err);
        setLoadingAvailableUserGroups(false);
        errToast();
      });
  }, [userGroups.length]);

  const handleUserGroupDeleteClick = (id) => {
    axios
      .delete(`${endpoints.deleteUserGroup}/${id}`, axiosOptions)
      .then((res) => {
        setUserGroups((prev) => prev.filter((item) => item.id !== id));
        openToast(
          () => console.log("user group deleted"),
          "success",
          "Successfully deleted user group."
        );
      })
      .catch((err) => {
        console.log("error:", err);
        errToast();
      });
    // console.log(userGroups.filter(item=>item.id===id))
  };

  const handleUserGroupEditClick = (id) => {
    const selectedUserGroupObj = userGroups.filter((item) => item.id === id)[0];
    setUpdateUserGroup(true);
    setSelectedUserGroup({
      description: selectedUserGroupObj.description,
      id: id,
      numOfUsers: selectedUserGroupObj.numOfUsers,
      organizationId: selectedUserGroupObj.organizationId,
      name: selectedUserGroupObj.userGroupName,
      users: selectedUserGroupObj.users,
      surveygroups: selectedUserGroupObj.surveygroups,
      surveys: selectedUserGroupObj.surveys,
    });

    console.log("selectedUserGroupObj.users", selectedUserGroupObj.users);
    setOpenAddNewUserGroupCard(true);
  };

  return (
    <div className="flex flex-col w-full space-y-16 transition-all">
      <div className="flex items-center space-x-5">
        <div className="mt-8 ml-10 blue-heading">User Groups</div>
        <button
          disabled={loadingAvailableUserGroups && loadingAvailableUsers}
          onClick={() => {
            setUpdateUserGroup(false);
            setSelectedUserGroup({
              name: "",
              description: "",
              users: [],
            });
            setOpenAddNewUserGroupCard(true);
          }}
          // style={{boxShadow:'0px 8px 18px #FFC9CB'}}
          className={`mt-8 ${
            loadingAvailableUsers && loadingAvailableUserGroups
              ? "opacity-25"
              : "opacity-100"
          } text-sm text-white shadow-2xl w-36 h-9 bg-red rounded-xl`}
        >
          Add New
        </button>
      </div>
      {/* <div className="w-full center">
                <span className="underDevelopmentCard">This page is undergoing development.</span>
            </div> */}
      {openAddNewUserGroupCard && (
        <span className="w-full">
          <AddNewUserGroupCard
            updateUserGroup={updateUserGroup}
            selectedUserGroup={selectedUserGroup}
            availableUsers={availableUsers}
            setOpenAddNewUserGroupCard={setOpenAddNewUserGroupCard}
            openToast={openToast}
            axiosOptions={axiosOptions}
            goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            endpoints={endpoints}
            LoadingAnimation={LoadingAnimation}
            errToast={errToast}
            setUserGroups={setUserGroups}
            userGroups={userGroups}
            setUpdateUserGroup={setUpdateUserGroup}
            availableSurveys={availableSurveys}
            surveyGroups={surveyGroups}
          />
        </span>
      )}
      <div
        style={{ boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.19)" }}
        className="w-11/12 p-5 mx-10 h-96 rounded-xl"
      >
        <UserGroupTable
          handleUserGroupEditClick={handleUserGroupEditClick}
          handleUserGroupDeleteClick={handleUserGroupDeleteClick}
          EmptyTableSVG={EmptyTableSVG}
          LoadingAnimation={LoadingAnimation}
          loadingAvailableUserGroups={loadingAvailableUserGroups}
          userGroups={userGroups}
        />
      </div>
    </div>
  );
};

const UserGroupTable = ({
  handleUserGroupEditClick,
  handleUserGroupDeleteClick,
  userGroups,
  EmptyTableSVG,
  LoadingAnimation,
  loadingAvailableUserGroups,
  availableUsers,
  selectedUsers,
  setSelectedUsers,
}) => {
  return (
    <div
      className={
        userGroups.length > 5
          ? "space-y-5 overflow-x-hidden overflow-y-scroll h-72"
          : "space-y-5 overflow-x-hidden overflow-y-visible h-72"
      }
    >
      <span className="flex items-center justify-start mx-10 space-x-2 font-semibold lg:space-x-3 md:space-x-3 xl:space-x-3 text-grey-lightest flex-nowrap ">
        <span className="w-10 text-center break-all lg:w-1/12">No.</span>
        <span className="flex flex-wrap justify-center text-center break-all w-44 lg:w-3/12">
          Group Name
        </span>
        <span className="w-16 text-center lg:w-2/12">No of Users</span>
        <span className="w-20 pl-4 text-center break-all lg:w-3/12">
          Created On
        </span>
        <span className="flex justify-center w-20 text-center flex-nowrap lg:w-2/12"></span>
      </span>

      {!loadingAvailableUserGroups ? (
        <span className="flex flex-col pt-8 space-y-5">
          {userGroups.length > 0 ? (
            <>
              {" "}
              {userGroups.map((userGroup, index) => (
                <RowItem
                  handleUserGroupEditClick={handleUserGroupEditClick}
                  handleUserGroupDeleteClick={handleUserGroupDeleteClick}
                  userGroup={userGroup}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  index={index}
                  key={index}
                />
              ))}{" "}
            </>
          ) : (
            <span className="flex flex-col items-center justify-center w-full space-y-8">
              <span className="w-44">
                <img src={EmptyTableSVG} alt="" />
              </span>
              <span className="text-xl font-semibold w-60 text-red">
                No User Groups Found
              </span>
            </span>
          )}
        </span>
      ) : (
        <span className="pt-20 center">
          <LoadingAnimation />{" "}
        </span>
      )}
    </div>
  );
};

const RowItem = ({
  handleUserGroupEditClick,
  handleUserGroupDeleteClick,
  userGroup,
  index,
  selectedUsers,
  setSelectedUsers,
}) => {
  const [openConfirmationCard, setOpenConfirmationCard] = useState(false);

  return (
    <span key={userGroup.id} className="flex">
      <Checkbox>
        <span className="w-20 text-center break-all lg:w-1/12">
          {index + 1}
        </span>
        <span className="flex flex-wrap justify-center text-center break-all w-44 lg:w-3/12">
          {userGroup.userGroupName}
        </span>
        <span className="w-16 text-center lg:w-2/12">
          {userGroup.numOfUsers}
        </span>
        <span className="w-20 text-center break-all lg:w-3/12">
          {userGroup.created_on}
        </span>
        {/* <span className="w-20">
                    { userGroup.status==='active' ? <Active />: <Inactive /> }
                </span> */}
        <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-1/12">
          {/* <span className="pointer">
                    {
                        userGroup.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span> */}
          <span
            data-background-color="#8B0021"
            data-tip="Edit"
            onClick={() => handleUserGroupEditClick(userGroup.id)}
            className="pointer"
          >
            <EditIcon />
          </span>
          <span
            data-background-color="#8B0021"
            data-tip="Delete"
            onClick={() => setOpenConfirmationCard(true)}
            className="pointer"
          >
            <DeleteIcon />
          </span>
          {openConfirmationCard && (
            <ConfirmationCard
              deleteFunc={() => handleUserGroupDeleteClick(userGroup.id)}
              setOpenConfirmationCard={setOpenConfirmationCard}
            />
          )}
        </span>
      </Checkbox>
      {/* <span className="flex justify-end w-2/4 pr-10 space-x-3">
                    <span className="pointer">
                    {
                        userGroup.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span>
                    <span className="pointer">
                        <EditIcon />
                    </span>
                    <span className="pointer">
                        <DeleteIcon />
                    </span>
            </span> */}
      <ReactTooltip />
    </span>
  );
};

// const Active = () => <div className="space-x-2 center"><span><ActiveIcon /></span><span>Active</span></div>
// const Inactive = () => <span className="space-x-2 center"><span><InactiveIcon /></span><span>Inactive</span></span>

const Checkbox = ({ children }) => {
  return (
    <div className="flex items-start w-full font-semibold text-blue">
      {/* <span className="mt-1 ml-9">{checked ? <CheckedIcon /> : <UncheckedIcon /> }</span> */}
      <span className="flex w-full ml-10 space-x-2 lg:space-x-6 md:space-x-2 xl:space-x-2">
        {children}
      </span>
    </div>
  );
};

export default UserGroupsPage;
