import React from 'react';
import ReviewDropdown from './ReviewDropdown';
import ReviewHorizontal from './ReviewHorizontal';
import ReviewTextBox from './ReviewTextBox';
import ReviewVertical from './ReviewVertical';
import ReviewVerticalCheckbox from './ReviewVerticalCheckbox';

const ReviewQuestion = ({ title, description, mandatory, type, options, enableOtherResponse }) => {
    // "dropdown-simple", "dropdown-horizontal", "checkbox-dropdown-vertical", "checkbox-simple", "textbox"
    
    return (
        <div 
            style={{boxShadow:'3px 4px 12px rgba(0, 0, 0, 0.19)', width:'100%'}} 
            className="flex flex-col items-start justify-center space-y-5 overflow-x-hidden"
            >
            <span className="flex items-center mx-10 mt-8 space-x-5">
                <span className="text-xl font-semibold text-blue-dark">{title}</span>
                <span className="mt-1 text-sm font-medium text-red-light">{mandatory && 'Mandatory'}</span>
            </span>
            <span className="w-full py-5 mx-10 font-medium">
                {
                    type === 'dropdown-horizontal' ? 
                        <CenterDiv>{description}</CenterDiv>
                    :
                        <LeftDiv>{description}</LeftDiv>    
                }
            </span>
            <span className="w-full mx-10">
                {
                    type === 'dropdown-horizontal' ?
                        <ReviewHorizontal options={options} enableOtherResponse={enableOtherResponse} />
                    :
                    type === 'dropdown-simple' ?
                        <ReviewDropdown options={options} enableOtherResponse={enableOtherResponse}/>
                    :
                    type === 'checkbox-dropdown-vertical' || type === 'checkbox-simple' ?
                        <ReviewVerticalCheckbox options={options} enableOtherResponse={enableOtherResponse}/>
                    :
                    type === 'textbox' ?
                    <ReviewTextBox />
                    :
                    type=== 'dropdown-vertical' ?
                        <ReviewVertical options={options} enableOtherResponse={enableOtherResponse} />
                    :
                    <ReviewTextBox />

                }
            </span>
        </div>
    )
}

const LeftDiv = ({ children }) => <div className="flex items-center">{children}</div>
const CenterDiv = ({ children }) => <div className="w-full center"><span>{children}</span></div>


export default ReviewQuestion
