import Loader from "react-loader-spinner";

const LoadingAnimation = () =>
  (
    <div className="center">
      <Loader
        type="TailSpin"
        color="#8B0021"
        height={50}
        width={50}
        timeout={30000}
        />
      </div>
  )

export default LoadingAnimation
