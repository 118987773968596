import React, { useState, useEffect } from 'react';
import Dropdown from './Dropdown';

const Multiselect = (props) => {

    const [dropdown, setDropdown] = useState(false);
    // managing dropdown items (list of dropdown items)
    const [items, setItems] = useState(props.items);
    // contains selected items
    const [selectedItems, setSelected] = useState(props.selectedItems? props.selectedItems : []);

    const toogleDropdown = () => {
        setDropdown(!dropdown)
    };
    // adds new item to multiselect 
    const addTag = (item) => {
        setSelected(selectedItems.concat(item));
        setDropdown(false);
    };
    // removes item from multiselect
    const removeTag = (item) => {
        const filtered = selectedItems.filter((e) => e.id !== item.id);
        setSelected(filtered);
    }

    useEffect(() => {
        props.setSelectedItems(selectedItems);
        setItems(props.items.filter(item=>!selectedItems.includes(item)))
    }, [selectedItems]);

    return (<div className="autcomplete-wrapper">
        <div className="autcomplete">
        <div className="flex flex-col items-center w-full mx-auto">
    <div className="w-full">
        <div className="relative flex flex-col items-center">
            <div className="w-full ">
                <div className="flex p-1 my-2 bg-white border rounded-xl border-grey-lightest ">
                    <div className="flex flex-wrap flex-auto">
                        {
                            // selectedItems?.length &&
                            selectedItems.map((tag, index) => {
                                return (
                                    <div key={index} className="flex items-center justify-center px-2 py-1 m-1 font-medium border rounded-full border-red bg-red-lightest text-red ">
                                            <div className="flex-initial max-w-full text-xs font-normal leading-none">{ tag[props.displayValue] }</div>
                                            <div className="flex flex-row-reverse flex-auto">
                                                <div onClick={() => removeTag(tag)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                                                    className="w-4 h-4 ml-2 rounded-full cursor-pointer feather feather-x hover:text-red-light">
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>)
                            })
                        }
                        <div className="flex-1">
                            <input disabled={props.disabled} placeholder={props.placeholder} className="w-full h-full px-2 py-2 pl-5 bg-transparent outline-none appearance-none text-grey-darkest"/>
                        </div>
                    </div>
                    <div className="flex items-center w-8 py-1 pl-2 pr-1 border-l border-gray-200 text-grey-light" onClick={toogleDropdown}>
                        <button className="w-6 h-6 outline-none cursor-pointer text-grey-lightest focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 feather feather-chevron-up">
                                <polyline points="18 15 12 9 6 15"></polyline>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
         { dropdown && !props.disabled  ? <Dropdown displayValue={props.displayValue} list={items} addItem={addTag}></Dropdown>: null }
    </div>
</div>

    </div>
        </div>)
};

export default Multiselect;