import React from 'react'

const Checkbox = ({ checked, children }) => {
    return (
        <div className="flex items-center space-x-3 font-semibold text-blue pointer">
            <span>{checked ? <CheckedIcon /> : <UncheckedIcon /> }</span>
            <span>{children}</span> 
        </div>
    )
}

const CheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="18" height="18" rx="6" fill="#8B0021"/> <path d="M13.5 6L7.82813 12.3838L5.25 9.48207" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const UncheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="17" height="17" rx="5.5" stroke="#CCCCCC"/> </svg>

export default Checkbox
