import React from "react";

const TemplateTitleCard = ({
  setOpenNewSurveyCard,
  setCompleteSurvey,
  feedbackSurveyTemplate,
  setUpdateSurvey,
  text,
  selected,
}) => {
  // *DONE: setUpdateSurvey(false) when a template is selected

  const handleClick = () => {
    setUpdateSurvey(false);
    setOpenNewSurveyCard(true);
    setCompleteSurvey((prev) => feedbackSurveyTemplate);
  };

  return (
    <div
      onClick={handleClick}
      style={{ fontWeight: "600", width: "200px", height: "100px" }}
      className={
        // selected ?
        // "selectedTemplateTitleCard"
        // :
        "templateTitleCard"
      }
    >
      {text}
    </div>
  );
};

export default TemplateTitleCard;
