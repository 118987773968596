import React, { useState, useEffect } from "react";

import axios from "axios";
import MultiSelect from "./MultiSelect/MultiSelect";
import { validateEmail, validateUsername } from "../../../utils";

const AddNewUserCard = ({
  userGroups,
  viewUser,
  setViewUser,
  openToast,
  updateUser,
  setUpdateUser,
  usersArray,
  setUsersArray,
  availableRoles,
  setOpenAddNewUserCard,
  userObj,
  endpoints,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
}) => {
  const [username, setUsername] = useState(userObj.username);
  const [email, setEmail] = useState(userObj.email);
  const [userId, setUserId] = useState(userObj.id);
  // const [password, setPassword] = useState('');
  const [selectedRoles, setSelectedRoles] = useState(
    userObj.role?.length ? [...userObj.role] : []
  );

  const tempArr = [];
  if (userObj) {
    userObj.usergroups?.map((item) =>
      tempArr.push({
        // description: selectedUserGroupObj.description,
        id: item.id,
        // numOfUsers: selectedUserGroupObj.numOfUsers,
        // organizationId: selectedUserGroupObj.organizationId,
        userGroupName: item.name,
        // users: selectedUserGroupObj.users
      })
    );
  }
  const [selectedUserGroups, setSelectedUserGroups] = useState(tempArr);

  const errToast = () =>
    openToast(
      console.log,
      "error",
      "You're not authorized for this operation."
    );
  const errToastWithMsg = (msg) => openToast(console.log, "error", msg);

  const sendNewUserToApi = (userObjToSend) => {
    axios
      .post(endpoints.createUser, userObjToSend, axiosOptions)
      .then((res) => {
        // if (res.body==='survey created') console.log(res);
        if (res.status === 201) {
          setOpenAddNewUserCard(false);
          const prev = [...usersArray];
          userObjToSend.name = userObjToSend.username;
          prev.push(userObjToSend);
          setUsersArray(prev);
          openToast(
            () => console.log("user created"),
            "success",
            "Successfully Created User."
          );
        } else {
          console.log(res);
          errToast();
        }
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        ) {
          goToLoginAndRemoveTokensFromLS();
        }
        console.log(err);
        errToastWithMsg(`error: ${err.response?.data}`);
      });
  };

  const sendEditUserToApi = (userId, userObjToSend) => {
    axios
      .post(`${endpoints.updateUser}/${userId}`, userObjToSend, axiosOptions)
      .then((res) => {
        // if (res.body==='survey created') console.log(res);
        if (res.status === 201) {
          setOpenAddNewUserCard(false);
          const prev = [...usersArray];
          const filteredArr = prev.filter((item) => item.id !== userId);
          // filteredArr.push(userObjToSend);
          // prev.push(userObjToSend)
          setUsersArray(filteredArr);
          setUpdateUser(false);
          openToast(
            () => console.log("user updated"),
            "success",
            "Successfully Updated User."
          );
        } else {
          console.log(res);
          setUpdateUser(false);
          errToast();
        }
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        console.log(err);
        setUpdateUser(false);
        errToastWithMsg(`error: ${err.response?.data}`);
      });
    console.log(userObjToSend);
  };

  const checkUsername = () => {
    if (!validateUsername(username)) {
      openToast(
        () => console.log("Username Invalid"),
        "error",
        "Username can only have Letters, Numbers and _"
      );
      return false;
    }
    return true;
  };

  const checkEmail = () => {
    if (!validateEmail(email)) {
      openToast(
        () => console.log(""),
        "error",
        "Please enter a valid email address"
      );
      return false;
    }
    return true;
  };

  const validateInputs = () => {
    if (checkUsername() && checkEmail()) return true;
    return false;
  };

  const handleCreateUserClick = () => {
    const valid = validateInputs();
    if (!valid) return;
    const usergroupsToSend = [];
    if (selectedUserGroups?.length) {
      selectedUserGroups.map((item) =>
        usergroupsToSend.push({ id: item.id, name: item.userGroupName })
      );
    }
    const userObjToSend = {
      username: username,
      email: email,
      name: "",
      phone: null,
      // "password": "",
      is_active: true,
      is_email_verified: true,
      roles: [
        ...selectedRoles,
        // {
        //     name: selectedRole.name,
        //     id: selectedRole.id
        // }
      ],
      usergroups: [...usergroupsToSend],
    };
    if (!updateUser) sendNewUserToApi(userObjToSend);
    if (updateUser) sendEditUserToApi(userId, userObjToSend);
    console.log("userObjToSend", userObjToSend);
  };

  const handleEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpenAddNewUserCard(false);
      setViewUser(false);
      setUpdateUser(false);
    }
  };

  const handleCancelClick = () => {
    setOpenAddNewUserCard(false);
    setViewUser(false);
    setUpdateUser(false);
  };

  return (
    <div
      onKeyDown={(e) => handleEscape(e)}
      style={{
        boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.19)",
        minWidth: "50%",
      }}
      className="absolute w-8/12 pb-24 space-y-5 bg-white left-56 top-20 rounded-xl"
    >
      <div className="flex flex-col p-8 pl-10 pr-16">
        <span className="flex items-center justify-between mb-4">
          <span className="text-2xl font-semibold text-blue-dark">
            {updateUser
              ? "Update User"
              : viewUser
              ? "View User"
              : "Create User"}
          </span>
          <span className="flex space-x-4">
            <button onClick={handleCancelClick} className="btn-grey">
              Cancel
            </button>
            {!viewUser && (
              <button
                disabled={!username?.length || !email?.length}
                onClick={handleCreateUserClick}
                className={
                  !username?.length || !email?.length
                    ? "btn-red-disabled"
                    : "btn-red"
                }
              >
                {updateUser ? "Update User" : "Create User"}
              </button>
            )}
          </span>
        </span>
      </div>

      <div className="pr-5 mx-10 space-y-5">
        <EditField
          onBlur={checkUsername}
          viewUser={viewUser}
          type="text"
          input={username}
          setInput={setUsername}
          placeholder="Username"
        />
        <EditField
          onBlur={checkEmail}
          viewUser={viewUser}
          type="email"
          input={email}
          setInput={setEmail}
          placeholder="Email"
        />
        <MultiSelect
          disabled={viewUser}
          placeholder="select multiple user groups"
          items={userGroups}
          displayValue="userGroupName"
          setSelectedItems={setSelectedUserGroups}
          selectedItems={selectedUserGroups}
        />
        {/* <UserGroupInput  
                    type="text"
                    value={userId}
                    setInput={setUserId}
                    placeholder="User Groups"
                /> */}
        {/* <EditField
                    type="password"
                    input={password}
                    setInput={setPassword}
                    placeholder="Password"
                /> */}
        <MultiSelect
          disabled={viewUser}
          placeholder="select roles"
          items={availableRoles}
          displayValue="name"
          setSelectedItems={setSelectedRoles}
          selectedItems={selectedRoles}
        />
        {/* <SelectField
                    viewUser={viewUser}
                    // type="text"
                    userRoles={availableRoles}
                    selectedRole={selectedRole}
                    setSelectedRole={setSelectedRole}
                /> */}
      </div>
    </div>
  );
};

const EditField = ({
  viewUser,
  type,
  input,
  placeholder,
  setInput,
  ...props
}) => {
  return (
    <>
      <input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        disabled={viewUser}
        className="w-full px-10 py-2 font-semibold border grey-placeholder text-grey-lightest border-grey-lightest rounded-xl focus:outline-none"
        placeholder={placeholder}
        type={type}
        {...props}
      />
    </>
  );
};

const SelectField = ({
  viewUser,
  userRoles,
  selectedRole,
  setSelectedRole,
}) => {
  const handleOnChange = (e) => {
    const filteredRoles = userRoles.filter(
      (item) => item.name === e.target.value
    );
    const id = filteredRoles[0].id;
    setSelectedRole({
      name: e.target.value,
      id: id,
    });
    setTimeout(() => {
      console.log(selectedRole);
    }, 1000);
  };

  return (
    <>
      <select
        disabled={viewUser}
        id={selectedRole.id}
        onChange={handleOnChange}
        value={selectedRole.name}
        className="content-center pl-10 font-semibold text-center rounded-lg text-grey-lightest border-grey-lightest w-60"
      >
        {userRoles.map((item, index) => (
          <option
            key={item.id}
            id={item.id}
            tabIndex={index}
            className="font-semibold text-grey"
          >
            {item.name}
          </option>
        ))}
      </select>
    </>
  );
};

const UserGroupInput = ({ type, value, placeholder, setInput }) => {
  return (
    <>
      <input
        value={value}
        onChange={(e) => setInput(e.target.value)}
        className="w-full px-10 py-2 font-semibold border grey-placeholder text-grey-lightest border-grey-lightest rounded-xl focus:outline-none"
        placeholder={placeholder}
        type={type}
      />
    </>
  );
};

export default AddNewUserCard;
