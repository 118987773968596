import React from "react";

const StartEndTime = (props) => {
  const {
    startOnPublish,
    editingStartTime,
    setStartTime,
    setEditingStartTime,
    editingEndTime,
    setEditingEndTime,
    endTime,
    setEndTime,
    startTime,
  } = props;
  return (
    <>
      {!startOnPublish && (
        <>
          {editingStartTime ? (
            <input
              onChange={(e) => setStartTime(e.target.value)}
              onKeyDownCapture={(e) => {
                if (e.key === "Enter") setEditingStartTime(false);
              }}
              autoFocus
              className="px-10 py-2 font-semibold border w-80 text-grey-lightest border-grey-lightest rounded-xl focus:outline-none"
              placeholder="Start Time"
              type="time"
              name="time"
            />
          ) : (
            <div
              onClick={() => setEditingStartTime(true)}
              className="px-10 py-2 font-semibold border w-80 text-grey-lightest pointer border-grey-lightest rounded-xl focus:outline-none"
            >
              {startTime
                ? `Start Time: ${new Date(`7/31/2021 ${startTime}`)
                    .toLocaleTimeString()
                    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")}`
                : "Start Time: "}
            </div>
          )}
        </>
      )}
      {editingEndTime ? (
        <input
          onChange={(e) => setEndTime(e.target.value)}
          onKeyDownCapture={(e) => {
            if (e.key === "Enter") setEditingEndTime(false);
          }}
          autoFocus
          className="px-10 py-2 font-semibold border w-80 text-grey-lightest border-grey-lightest rounded-xl focus:outline-none"
          placeholder="End Time"
          type="time"
          name="time"
        />
      ) : (
        <div
          onClick={() => setEditingEndTime(true)}
          className="px-10 py-2 font-semibold border w-80 text-grey-lightest pointer border-grey-lightest rounded-xl focus:outline-none"
        >
          {endTime
            ? `End Time: ${new Date(`7/31/2021 ${endTime}`)
                .toLocaleTimeString()
                .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")}`
            : "End Time"}
        </div>
      )}
    </>
  );
};

export default StartEndTime;
