import { v4 as uuid } from 'uuid';

export const feedbackSurveyQuestions2 = [
    {
      "index": 0,
      "type": "dropdown-simple",
      "mandatory": 1,
      "label": "Building",
      "description": "Select a hospital from list below",
      "fields": [
        "DCAM",
        "COMER",
        "Orland Park",
        "South Loop",
        "River East",
        "South Shore Senior Center",
        "Silver Cross",
        "Call Center/Burr Ridge"
      ],
      "fields2": {},
      "default": "DCAM",
      "depends": false,
      "depending-childs": [1,2],
      "depending-childs-type": ["dropdown-simple", "dropdown-simple"]
    },
    {
      "index": 1,
      "type": "dropdown-simple",
      "mandatory": 1,
      "label": "Floor",
      "description": "Select an area",
      "multi-select": 0,
      "default": "",
      "depends": true,
      "depends-source": "Building",
      "depending-childs": [2],
      "depending-childs-type": ["dropdown-simple"],
      "fields": [],
      "fields2": {
        "DCAM": ["1","2","3","4","5","6","7"],
        "COMER": ["1","2","3","4","5","6"],
        "Orland Park": ["1","2","3"],
        "South Loop": ["2"],
        "River East": ["1","2"],
        "South Shore Senior Center": ["1"],
        "Silver Cross": ["1","2"],
        "Call Center/Burr Ridge": ["1"]
      }
    },
    {
      "index": 2,
      "type": "dropdown-simple",
      "mandatory": 1,
      "label": "Area",
      "description": "Select an area",
      "multi-select": 0,
      "default": "",
      "depends": true,
      "depends-source": "Building/Floor",
      "fields": [],
      "fields2": {
        "DCAM/1": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "DCAM/2": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "DCAM/3": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "DCAM/4": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "DCAM/5": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "DCAM/6": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "DCAM/7": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "COMER/1": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "COMER/2": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "COMER/3": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "COMER/4": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "COMER/5": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "COMER/6": ["A", "B", "C", "D", "E", "F", "G", "H", "I", "II"],
        "Orland Park/1": ["RadOnc","Imaging", "Laboratory", "Oncology", "ENT", "Endo and Cards", "Primary Care & Multi-speciality"],
        "Orland Park/2": ["RadOnc","Imaging", "Laboratory", "Oncology", "ENT", "Endo and Cards", "Primary Care & Multi-speciality"],
        "Orland Park/3": ["RadOnc","Imaging", "Laboratory", "Oncology", "ENT", "Endo and Cards", "Primary Care & Multi-speciality"],
        "South Loop/2": ["201","202"],
        "River East/1": ["PCG & mutli-speciality"],
        "River East/2": ["PCG & mutli-speciality"],
        "South Shore Senior Center/1": ["Geriatric Care"],
        "Silver Cross/1": ["RadOnc","MedOnc", "Infusion/Lab"],
        "Silver Cross/2": ["RadOnc","MedOnc", "Infusion/Lab"],
        "Call Center/Burr Ridge/1": ["Scheduling call center", "24x7 call center", "Large Breakroom", "Large Cafeteria", "Small Conference Room", "Small Breakroom", "Large Washroom", "Small Washroom", "Lobby"]
      }
    },
    {
      "index": 3,
      "type": "checkbox-simple",
      "mandatory": 1,
      "label": "Issue",
      "multi-select": 1,
      "fields": [
        "Not wearing mask",
        "Improper usage of mask",
        "Not social distancing",
        // "Other"
      ],
      "fields2": {},
      "default": ""
    },
    // {
    //   "index": 4,
    //   "type": "textbox",
    //   "mandatory": 0,
    //   "label": "Other",
    //   "character-limit": 270,
    //   "placeholder": "Enter reason",
    //   "link": true,
    //   "Link-Source": "Issue",
    //   "Link-if": "Other",
    //   "default": ""
    // },
    {
      "index": 5,
      "type": "dropdown-horizontal",
      "mandatory": 1,
      "label": "Who",
      "description": "Select one",
      "multi-select": 0,
      "fields": [
        "Patient",
        "Employee",
        "Care Giver"
      ],
      "fields2": {},
      "default": ""
    },
    {
      "index": 6,
      "type": "textbox",
      "mandatory": 0,
      "label": "Notes",
      "character-limit": 270,
      "placeholder": "Enter notes here",
      "link": false,
      "default": ""
    }             
]

  // feedbackSurveyQuestions
export const serviceSurveyQuestions = [
    {
        id: uuid(),
        title: "Were You satisfied with the customer service we provided you?",
        description: "",
        options: ["Yes", "No", "Somewhat"],
        type: "checkbox-dropdown-vertical",
        enableOtherResponse: false,
        mandatory: false,
        visible: true,
    },
    {
      id: uuid(),
      title: 'Was our customer service provided to you in an accessible manner?',
      description: '',
      options: ["Yes", "No", "Somewhat"],
      type: 'checkbox-dropdown-vertical',
      enableOtherResponse: false,
      mandatory: false,
      isVisible: true,
  },
  {
    id: uuid(),
    title: 'How often do you use our service?',
    description: '',
    options: ['Often', 'Sometimes', 'Very Often'],
    type: 'dropdown-horizontal',
    enableOtherResponse: false,
    mandatory: false,
    isVisible: true,
},
{
    id: uuid(),
    title: 'Would you recommend us to your friends and family?',
    description: '',
    options: ['Definitely', 'Maybe', 'No'],
    type: 'dropdown-horizontal',
    enableOtherResponse: false,
    mandatory: true,
    isVisible: true,
},
{
    id: uuid(),
    title: 'Any comments?',
    description: '',
    options: [],
    type: 'textbox',
    enableOtherResponse: false,
    mandatory: true,
    isVisible: true,
},
];