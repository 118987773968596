import React, { useEffect, useState } from 'react';

import { DeleteIcon } from '../../../Icons';

const DependentOption = ({ fieldsAdded, setFieldsAdded, selectedField, questionIndex, optionIndex, option }) => {
    const [optionValue, setOptionValue] = useState(option);

    useEffect(()=>{
        let newObj = {...fieldsAdded};
        let selectedFieldArr = newObj[selectedField];
        selectedFieldArr?.splice(optionIndex, 1, optionValue);
        newObj = {...newObj, [selectedField]: [...selectedFieldArr]};
        console.log('EDITED Option fieldsAdded',newObj) 

        setFieldsAdded(newObj);

    }, [optionValue]);

    const removeOption = () => {
        let newObj = {...fieldsAdded};
        let selectedFieldArr = newObj[selectedField];
        const deletedOptionValue = selectedFieldArr.splice(optionIndex,1);
        // newArr[questionIndex].options[optionIndex] = optionValue;

        newObj = {...newObj, [selectedField]: [...selectedFieldArr]};

        console.log('removed', deletedOptionValue)

        setFieldsAdded(newObj);
    }

    return (
    <>
        {
            <div className="flex items-center justify-start space-x-1">
                <input 
                    value={optionValue} 
                    type="text" 
                    name="option"
                    onFocus={(e)=>{if(e.target.value==='Option') e.target.value=''}}
                    onChange={(e)=>{setOptionValue(e.target.value)}} 
                    className="flex items-center justify-center px-2 py-1 font-semibold text-center break-all border w-36 text-grey-lightest rounded-xl border-grey-lightest" 
                    />
                <span onClick={removeOption} className="pointer"><DeleteIcon /> </span>
            </div>
        }
    </>
)}

export default DependentOption;