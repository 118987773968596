import React, { useEffect, useState } from "react";

import axios from "axios";
import EmptyResource from "../../EmptyResource";

const AddNewUserGroupCard = ({
  setOpenAddNewUserGroupCard,
  availableUsers,
  openToast,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
  endpoints,
  LoadingAnimation,
  errToast,
  setUserGroups,
  userGroups,
  updateUserGroup,
  selectedUserGroup,
  setUpdateUserGroup,
  availableSurveys,
  surveyGroups,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [selectedSurveyGroups, setSelectedSurveyGroups] = useState([]);

  const errToastWithMsg = (msg) => openToast(console.log, "error", msg);

  useEffect(() => {
    if (updateUserGroup) {
      const toAdd = selectedUserGroup?.users?.map((item) => ({
        id: item?.id,
        name: item?.name,
      }));
      if (toAdd?.length) setSelectedUsers([...toAdd]);

      const toAddSurveys = selectedUserGroup?.surveys?.map((item) => ({
        id: item?.id,
        name: item?.name,
      }));
      if (toAddSurveys?.length) setSelectedSurveys([...toAddSurveys]);

      const toAddSurveyGroups = selectedUserGroup?.surveygroups?.map(
        (item) => ({ id: item?.id, surveyGroupName: item?.surveyGroupName })
      );
      if (toAddSurveyGroups?.length)
        setSelectedSurveyGroups([...toAddSurveyGroups]);
    }
  }, []);

  const [newSurveyGroupName, setNewSurveyGroupName] = useState(
    selectedUserGroup.name
  );

  const sendNewUserGroupToApi = (userGroupObjToSend) => {
    axios
      .post(endpoints.createUserGroup, userGroupObjToSend, axiosOptions)
      .then((res) => {
        // if (res.body==='survey created') console.log(res);
        if (res.status === 201) {
          setOpenAddNewUserGroupCard(false);
          const prev = [...userGroups];
          // userGroupObjToSend.userGroupName= userGroupObjToSend.username;
          prev.push(userGroupObjToSend);
          setUserGroups(prev);
          openToast(
            () => console.log("user group created"),
            "success",
            "Successfully Created User Group."
          );
        } else {
          console.log(res);
          errToast();
        }
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        console.log(err);
        errToastWithMsg(`error: ${err.response?.data}`);
      });
  };

  const sendEditUserGroupToApi = (userGroupId, userGroupObjToSend) => {
    axios
      .post(
        `${endpoints.updateUserGroup}/${userGroupId}`,
        userGroupObjToSend,
        axiosOptions
      )
      .then((res) => {
        // if (res.body==='survey created') console.log(res);
        if (res.status === 201) {
          setOpenAddNewUserGroupCard(false);
          const prev = [...userGroups];
          const filteredArr = prev.filter((item) => item.id !== userGroupId);
          // filteredArr.push(userGroupObjToSend);
          // prev.push(userObjToSend)
          setUserGroups(filteredArr);
          setUpdateUserGroup(false);
          openToast(
            () => console.log("user group updated"),
            "success",
            "Successfully Updated User Group."
          );
        } else {
          console.log(res);
          // setUpdateUser(false);
          errToast();
        }
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        console.log(err);
        // setUpdateUser(false);
        errToastWithMsg(`error: ${err.response?.data}`);
      });
  };

  const handleCreateSurveyGroupClick = () => {
    // console.log('selectedUsers', selectedUsers);
    let users = [];
    let surveys = [];
    let surveygroups = [];
    if (selectedUsers.length) {
      selectedUsers.map((user) => users.push({ id: user.id, name: user.name }));
    }
    if (selectedSurveys.length) {
      selectedSurveys.map((survey) =>
        surveys.push({ id: survey.id, name: survey.name })
      );
    }
    if (selectedSurveyGroups.length) {
      selectedSurveyGroups.map((surveygroup) =>
        surveygroups.push({
          id: surveygroup.id,
          name: surveygroup.surveyGroupName,
        })
      );
    }
    const userGroupObjToSend = {
      name: newSurveyGroupName,
      description: "",
      users: [...users],
      surveygroups: [...surveygroups],
      surveys: [...surveys],
    };
    if (!updateUserGroup) sendNewUserGroupToApi(userGroupObjToSend);
    console.log("userGroupObjToSend", userGroupObjToSend);
    if (updateUserGroup)
      sendEditUserGroupToApi(selectedUserGroup.id, userGroupObjToSend); // console.log(userGroupObjToSend);
  };

  const handleCancelClick = () => {
    setUpdateUserGroup(false);
    setOpenAddNewUserGroupCard(false);
    // setViewUserGroup(false);
  };

  useEffect(() => {
    console.log("selectedUsers", selectedUsers);
  }, [selectedUsers]);

  return (
    <div
      style={{
        boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.19)",
        minWidth: "50%",
      }}
      className="absolute w-9/12 pb-24 space-y-5 bg-white left-56 top-20 rounded-xl"
    >
      <div className="flex flex-col p-8 pl-10">
        <span className="flex items-center justify-between">
          <span className="text-2xl font-semibold text-blue-dark">
            {updateUserGroup ? "Update" : "Create"} User Group
          </span>
          <span className="flex space-x-4">
            <button
              onClick={() => handleCancelClick(false)}
              className="btn-grey"
            >
              Cancel
            </button>
            <button
              disabled={!newSurveyGroupName.length}
              onClick={handleCreateSurveyGroupClick}
              className={`${
                !newSurveyGroupName.length ? "btn-red-disabled" : "btn-red"
              }`}
            >
              Save Group
            </button>
          </span>
        </span>
      </div>

      <div className="flex flex-col justify-center mx-10 space-y-3">
        <EditField
          type="text"
          input={newSurveyGroupName}
          setInput={setNewSurveyGroupName}
          placeholder="User Group Name"
        />
        <span className="flex items-center pt-5 space-x-5">
          <span className="blue-heading">Add Users</span>
          <span className="mt-2 small-pink-text">
            {selectedUsers.length} Selected
          </span>
        </span>
        <span className="pt-1">
          <CreateUserGroupTable
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            availableUsers={availableUsers}
          />
        </span>
        {surveyGroups?.length ? (
          <>
            <span className="flex items-center pt-5 space-x-5">
              <span className="blue-heading">Assign Survey Groups</span>
              <span className="mt-2 small-pink-text">
                {selectedSurveyGroups.length} Selected
              </span>
            </span>
            <span className="pt-1">
              <CreateSurveyGroupTable
                surveyGroups={surveyGroups}
                selectedSurveyGroups={selectedSurveyGroups}
                setSelectedSurveyGroups={setSelectedSurveyGroups}
              />
            </span>
          </>
        ) : null}
        {availableSurveys?.length ? (
          <>
            <span className="flex items-center pt-5 space-x-5">
              <span className="blue-heading">Assign Surveys</span>
              <span className="mt-2 small-pink-text">
                {selectedSurveys.length} Selected
              </span>
            </span>
            <span className="pt-1">
              <CreateSurveysTable
                availableSurveys={availableSurveys}
                selectedSurveys={selectedSurveys}
                setSelectedSurveys={setSelectedSurveys}
              />
            </span>
          </>
        ) : null}
      </div>
    </div>
  );
};

const CreateUserGroupTable = ({
  availableUsers,
  selectedUsers,
  setSelectedUsers,
}) => {
  return (
    <div className="space-y-2">
      <span className="flex items-center justify-start mx-24 font-semibold space-x-44 text-grey-lightest flex-nowrap ">
        <span className="w-44">Username</span>
        {/* <span>Name</span> */}
        <span className="w-44">Email</span>
        {/* <span className="">Status</span> */}
      </span>
      <span
        className={
          availableUsers?.length > 5
            ? "flex h-60 overflow-y-scroll flex-col pt-4 space-y-5"
            : "flex flex-col pt-4 space-y-5"
        }
      >
        {availableUsers.map((userObj, index) => (
          <RowItem
            selectedItems={selectedUsers}
            setSelectedItems={setSelectedUsers}
            itemObj={userObj}
            index={index}
            key={index}
            objQuery1="username"
            objQuery2="email"
          />
        ))}
        {!availableUsers?.length ? (
          <span className="mx-auto mt-5">
            <EmptyResource text="No Users Found" />
          </span>
        ) : null}
      </span>
    </div>
  );
};

const CreateSurveyGroupTable = ({
  surveyGroups,
  selectedSurveyGroups,
  setSelectedSurveyGroups,
}) => {
  return (
    <div className="space-y-2">
      <span className="flex items-center justify-start mx-24 space-x-24 font-semibold text-grey-lightest flex-nowrap ">
        <span className="w-44">Name</span>
        {/* <span>Name</span> */}
        <span className="w-44">No. of Surveys</span>
        {/* <span className="">Status</span> */}
      </span>
      <span
        className={
          surveyGroups?.length > 5
            ? "flex h-60 overflow-y-scroll flex-col pt-4 space-y-5"
            : "flex flex-col pt-4 space-y-5"
        }
      >
        {surveyGroups.map((surveygroupObj, index) => (
          <RowItem
            selectedItems={selectedSurveyGroups}
            setSelectedItems={setSelectedSurveyGroups}
            itemObj={surveygroupObj}
            index={index}
            key={index}
            objQuery1="surveyGroupName"
            objQuery2="numOfSurveys"
          />
        ))}
      </span>
    </div>
  );
};

const CreateSurveysTable = ({
  availableSurveys,
  selectedSurveys,
  setSelectedSurveys,
}) => {
  return (
    <div className="space-y-2">
      <span className="flex items-center justify-start mx-24 font-semibold space-x-36 text-grey-lightest flex-nowrap ">
        <span className="w-44">Name</span>
        {/* <span>Name</span> */}
        <span className="w-44">Created On</span>
        {/* <span className="">Status</span> */}
      </span>
      <span
        className={
          availableSurveys?.length > 5
            ? "flex h-60 overflow-y-scroll flex-col pt-4 space-y-5"
            : "flex flex-col pt-4 space-y-5"
        }
      >
        {availableSurveys.map((surveyObj, index) => (
          <RowItem
            selectedItems={selectedSurveys}
            setSelectedItems={setSelectedSurveys}
            itemObj={surveyObj}
            index={index}
            key={index}
            objQuery1="name"
            objQuery2="created_on"
          />
        ))}
      </span>
    </div>
  );
};

const RowItem = ({
  itemObj,
  index,
  selectedItems,
  setSelectedItems,
  objQuery1,
  objQuery2,
}) => {
  const checked =
    selectedItems.filter((item) => item.id === itemObj.id).length > 0;

  const handleCheck = () => {
    if (checked)
      setSelectedItems((prev) => prev.filter((item) => item.id !== itemObj.id));
    else setSelectedItems((prev) => [...prev, itemObj]);
  };

  return (
    <span key={itemObj.id} className="flex">
      <Checkbox handleCheck={handleCheck} checked={checked}>
        <span className="w-44">{itemObj[objQuery1]}</span>
        {/* <span className="w-10">{userObj.name}</span> */}
        <span className="w-44">{itemObj[objQuery2]}</span>
        {/* <span className="w-20">
                    { userObj.status==='active' ? <Active />: <Inactive /> }
                </span> */}
      </Checkbox>
      {/* <span className="ml-16">
                    {
                        userObj.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
            </span> */}
    </span>
  );
};

const Active = () => (
  <div className="space-x-2 center">
    <span>
      <ActiveIcon />
    </span>
    <span>Active</span>
  </div>
);
const Inactive = () => (
  <span className="space-x-2 center">
    <span>
      <InactiveIcon />
    </span>
    <span>Inactive</span>
  </span>
);

const Checkbox = ({
  checked,
  children,
  selectedUsers,
  setSelectedUsers,
  handleCheck,
}) => {
  return (
    <div
      onClick={handleCheck}
      className="flex items-start font-semibold text-blue pointer"
    >
      <span className="mt-1 ml-9">
        {checked ? <CheckedIcon /> : <UncheckedIcon />}
      </span>
      <span className="flex ml-10 space-x-40">{children}</span>
    </div>
  );
};
const CheckedIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <rect width="18" height="18" rx="6" fill="#8B0021" />{" "}
    <path
      d="M13.5 6L7.82813 12.3838L5.25 9.48207"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
  </svg>
);
const UncheckedIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="5.5"
      stroke="#CCCCCC"
    />{" "}
  </svg>
);
const EyeInvisibleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      fill="#878787"
    />{" "}
  </svg>
);
const EyeVisibleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
      stroke="#8B0021"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      fill="#8B0021"
    />{" "}
  </svg>
);
const ActiveIcon = () => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#8CD76A" />{" "}
  </svg>
);
const InactiveIcon = () => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#CCCCCC" />{" "}
  </svg>
);

const EditField = ({ type, input, placeholder, setInput }) => {
  // const [editing, setEditing] = useState(false);

  return (
    <>
      {
        // editing ?
        <input
          onChange={(e) => setInput(e.target.value)}
          // onKeyDownCapture={(e)=>{if(e.key==='Enter') setEditing(false)}}
          autoFocus
          value={input}
          className="w-full px-10 py-2 font-semibold border grey-placeholder text-grey-lightest border-grey-lightest rounded-xl focus:outline-none"
          placeholder={placeholder}
          type={type}
        />
        // :
        // <div onClick={()=>setEditing(true)} className="w-full px-10 py-2 font-semibold border text-grey-lightest pointer border-grey-lightest rounded-xl focus:outline-none">
        //     {input ? `${input}` : `${placeholder}`}
        // </div>
      }
    </>
  );
};

export default AddNewUserGroupCard;
