import React, {useState} from 'react';

const Signup = (props) => {

    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    return (
        <div className="flex flex-col items-center justify-start space-y-4 h-96">
            <h1 className="mt-32 mb-10 text-5xl font-bold text-blue">Sign Up</h1>
            <div className="s"><input onChange={(e)=>setUsername(e.target.value)} value={username} className="input" placeholder="username" type="text" name="username" /></div>
            <div className="s"><input onChange={(e)=>setEmail(e.target.value)} value={email} className="input" placeholder="email address" type="text" name="email" /></div>
            <div className="s"><input onChange={(e)=>setPhoneNumber(e.target.value)} value={phoneNumber} className="input" placeholder="phone #" type="text" name="phone number" /></div>
            <div id="password" className="pb-5 text-blue"> 
                {/* <div className="absolute -right-3/4">
                    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4Z" fill="#CCCCCC"/></svg>
                </div> */}
                <input onChange={(e)=>setPassword(e.target.value)} value={password} className="input" placeholder="password" type="password" name="password" />
            </div>
            {/* <div className="pb-5"><a href="#" className="large-pink-link">Forgot Password?</a></div> */}
            <button className="text-white transition-all btn-main bg-red">Sign Up</button>
            {/* <div className=""><a href="#" className="large-pink-link">Login with SSO</a></div> */}
        </div>
    )
}

export default Signup;
