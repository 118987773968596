import React, { useState, useEffect, lazy, Suspense } from "react";

import { toast, ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AnimatePresence } from "framer-motion";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";

import "./App.css";

import Navbar from "./components/Navbar";
import LoadingAnimation from "./components/LoadingAnimation";

import demo_dp from "./assets/demo_dp.svg";

import { endpoints, url } from "./endpoints";
import {
  getAuthTypeFromLS,
  getAuthTokenFromLS,
  removeTokensFromLS,
  axiosOptions,
  removeUsernameFromLS,
} from "./utils";

import Login from "./components/pages/Login/Login";
import LandingPage from "./components/pages/LandingPage/LandingPage";
import MainPage from "./components/pages/MainPage";
import Signup from "./components/pages/Signup/Signup";

const App = () => {
  const location = useLocation();

  // const LandingPage = lazy(() =>
  //   import("./components/pages/LandingPage/LandingPage")
  // );
  // const Login = lazy(() => import("./components/pages/Login/Login"));
  // const MainPage = lazy(() => import("./components/pages/MainPage"));
  // const Signup = lazy(() => import("./components/pages/Signup/Signup"));

  const username = localStorage.getItem("username");
  const [logged, setLogged] = useState(false);

  const [userInfo, setUserInfo] = useState({});

  const goToLoginAndRemoveTokensFromLS = () => {
    removeTokensFromLS();
    removeUsernameFromLS();
    setLogged(false);
    window.location.replace("/login");
  };

  useEffect(() => {
    const ssoToken = localStorage.getItem("ssoToken");
    const baseToken = localStorage.getItem("baseToken");
    if (ssoToken || baseToken) setLogged(true);
  }, []);

  const openToast = (onClose, toastType, toastMessage) => {
    toast[toastType](toastMessage, {
      // onOpen: () => window.alert('Called when I open'),
      onClose: () => onClose(),
    });
  };

  return (
    <div className="box-border w-full h-full">
      {/* <Router> */}
      <Navbar
        goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
        user_dp={demo_dp}
        username={username}
        logged={logged}
      />
      {/* <Suspense
          fallback={
            <div className="h-full col-center">
              <LoadingAnimation />
            </div>
          }
        > */}
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route path="/login">
            <Login
              LoadingAnimation={LoadingAnimation}
              openToast={openToast}
              url={url}
              logged={logged}
              setLogged={setLogged}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              endpoints={endpoints}
              removeTokensFromLS={removeTokensFromLS}
              goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            />
          </Route>
          <Route exact path="/signup">
            <Signup url={url} />
          </Route>
          <Route exact path="/dashboard">
            <MainPage
              LoadingAnimation={LoadingAnimation}
              openToast={openToast}
              logged={logged}
              setLogged={setLogged}
              endpoints={endpoints}
              axiosOptions={axiosOptions}
              url={url}
              goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            />
          </Route>
        </Switch>
      </AnimatePresence>
      {/* </Suspense> */}
      {/* </Router> */}
      <ToastContainer
        position="bottom-center"
        hideProgressBar={false}
        autoClose={3000}
        newestOnTop={true}
        closeOnClick={true}
        draggable={false}
        rtl={false}
        pauseOnHover={true}
        limit={1}
        transition={Zoom}
      />
    </div>
  );
};

export default App;
