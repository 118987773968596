import React from 'react'

const Button = ({ children, className, disabled, ...props }) => {
    return (
        <button 
            disabled={disabled} 
            className={`${className} disabled:bg-grey-lightest bg-red text-sm text-white pointer rounded-xl px-5 py-1 flex items-center justify-center`} 
            {...props}
            >
            {children}
        </button>
    )
}

export default Button
