import React, { useState, useEffect, useRef } from "react";
import BottomNav from "../BottomNav";
import TopNav from "../TopNav";
import SettingsMain from "./SettingsMain";

const SettingsOfNewSurvey = ({
  resetCompleteSurvey,
  LoadingAnimation,
  updateSurveyToApi,
  updateSurvey,
  setUpdateSurvey,
  pageOpen,
  setPageOpen,
  questions,
  sendNewSurveyToApi,
  surveyTitle,
  setOpenNewSurveyCard,
  completeSurvey,
  setCompleteSurvey,
}) => {
  const scrollNode = useRef(null);
  // Go to top of the page when this component renders
  useEffect(() => {
    const executeScroll = () => scrollNode.current.scrollIntoView();
    executeScroll();
  }, []);

  return (
    <div className="w-full" ref={scrollNode}>
      <TopNav
        resetCompleteSurvey={resetCompleteSurvey}
        setPageOpen={setPageOpen}
        setOpenNewSurveyCard={setOpenNewSurveyCard}
        pageOpen={pageOpen}
        displayCancelButton={true}
      />
      <div className="mt-10 mb-10 space-y-5">
        <div className="flex items-center mx-10 space-x-5">
          <span className="text-2xl font-semibold text-blue-dark">
            {surveyTitle}
          </span>
          <span className="flex mt-1 space-x-1 text-sm font-medium text-red-light">
            <span>{questions.length}</span>
            <span>Questions Total</span>
          </span>
        </div>
        <div className="mx-10 space-y-8">
          <SettingsMain
            completeSurvey={completeSurvey}
            setCompleteSurvey={setCompleteSurvey}
          />
        </div>
      </div>
      <BottomNav
        LoadingAnimation={LoadingAnimation}
        updateSurveyToApi={updateSurveyToApi}
        updateSurvey={updateSurvey}
        setUpdateSurvey={setUpdateSurvey}
        sendNewSurveyToApi={sendNewSurveyToApi}
        pageOpen={pageOpen}
        setPageOpen={setPageOpen}
        setOpenNewSurveyCard={setOpenNewSurveyCard}
      />
    </div>
  );
};

export default SettingsOfNewSurvey;
