import React, {useState} from 'react'

const ReviewTextBox = () => {
    const [optionValue, setOptionValue] = useState('');
    return (
        <div className="flex flex-col items-center justify-center w-full space-y-2 transition-all mb-7">
            <TextBox optionValue={optionValue} setOptionValue={setOptionValue}/>
            <span className="flex justify-end w-11/12 pr-14 small-pink-text">
                {optionValue.length}/100 Characters
            </span>
        </div>
    )
}

const TextBox = ({ optionValue, setOptionValue }) => {
    const [editing, setEditing] = useState(false);
    const handleClick = () => {
        setEditing(true);
    }
    
    const handleTitleEnter = () => {
        setEditing(false);
    }

    // onFocus={(e)=>{e.target.value=''}} autoFocus onKeyDownCapture={(e)=>{if(e.key==='Enter') handleTitleEnter(e.target.value)}}

    return (
    <span className="w-full">
        {
            !editing ?
            <div onClick={()=>handleClick()} className="w-11/12 h-40 px-5 py-5 font-semibold border text-grey-lightest rounded-xl border-grey-lightest">
                {optionValue.length>0 ? optionValue : 'Text Answer'}
            </div>
            :
            <textarea onFocus={(e)=>{e.target.value=''}} autoFocus onKeyDownCapture={(e)=>{if(e.key==='Enter') handleTitleEnter(e.target.value)}} value={optionValue} onChange={(e)=>setOptionValue(e.target.value)} className="flex items-start justify-start w-11/12 h-40 px-5 py-5 font-semibold break-all border text-blue rounded-xl border-grey-lightest focus:outline-none" placeholder="Text Answer"/>
        }
    </span>
    )
}

export default ReviewTextBox
