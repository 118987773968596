import React, { useState, useEffect, lazy, Suspense, useMemo } from "react";
import { v4 as uuid } from "uuid";
import axios from "axios";

import { motion } from "framer-motion";

import Sidebar from "../Sidebar";
import CreateSurvey from "./CreateSurvey/CreateSurvey";
import MainSurvey from "./MainSurveyPage/MainSurvey";
import UsersPage from "./Users/UsersPage";
import SurveyGroupPage from "./SurveyGroups/SurveyGroupPage";
import UserGroupPage from "./UserGroups/UserGroupsPage";
import RolesPage from "./Roles/RolesPage";

import EmptyTableSVG from "../../assets/empty_data.svg";

import {
  feedbackSurveyQuestions2,
  serviceSurveyQuestions,
} from "../../Data/FeedbackSurveysData";
import { dateMaker } from "./SurveyGroups/utils";

import dateFormatter from "../utils/dateFormatter";
import Emails from "./Emails/Emails";

const MainPage = ({
  openToast,
  LoadingAnimation,
  logged,
  setLogged,
  axiosOptions,
  url,
  goToLoginAndRemoveTokensFromLS,
  endpoints,
}) => {
  // const CreateSurvey = lazy(()=>import('./CreateSurvey/CreateSurvey'));
  //   const MainSurvey =   lazy(()=>import('./MainSurveyPage/MainSurvey'));
  //   const UsersPage =   lazy(()=>import('./Users/UsersPage'));
  //   const SurveyGroupPage =   lazy(()=>import('./SurveyGroups/SurveyGroupPage'));
  //   const UserGroupPage =   lazy(()=>import('./UserGroups/UserGroupsPage'));
  //   const RolesPage =   lazy(()=>import('./Roles/RolesPage'));

  const errToast = (err) =>
    openToast(
      console.log, // goToLoginAndRemoveTokensFromLS,
      "error",
      `You do not have access to this page.`
    );

  const [availableSurveys, setAvailableSurveys] = useState([
    // COMMENT 1 : MainSurvey.txt
  ]);
  const [selectedPage, setSelectedPage] = useState("home");
  const [openNewSurveyCard, setOpenNewSurveyCard] = useState(false);
  const [pageOpen, setPageOpen] = useState("create");
  const [updateSurvey, setUpdateSurvey] = useState(false);

  const parseQuestions = (questions) => {
    // const x = questions.replace(/'/g, '"');
    // const y = x.replace(/True/g, 'true');
    // const z = y.replace(/False/g, 'false');

    let tempParsed = questions;

    let otherRemovedParsed = [];
    let otherLabel = "";
    let linkSource = "";

    tempParsed.map((questionObj, index) => {
      if (questionObj.type === "textbox" && questionObj["Link-Source"]) {
        otherLabel = questionObj.label;
        let tempQuestion = { ...questionObj };
        linkSource = tempQuestion["Link-Source"];
        const otherLinkQuestionArr = tempParsed.filter(
          (item) => item.label === linkSource
        );
        const otherLinkQuestion =
          otherLinkQuestionArr?.length > 0 ? otherLinkQuestionArr[0] : null;

        // index of Linked Question: index-1
        if (typeof otherLinkQuestion === "object") {
          const otherLabelIndex = otherLinkQuestion.fields?.findIndex(
            (item) => item === otherLabel
          );
          otherLinkQuestion.fields?.splice(otherLabelIndex, 1);

          otherRemovedParsed.splice(index - 1, 1, otherLinkQuestion);
        }
      }
      otherRemovedParsed.push(questionObj);
    });

    otherRemovedParsed = otherRemovedParsed.filter(
      (item) => item.label !== otherLabel
    );

    // console.log('otherRemovedParsed',otherRemovedParsed)

    let tempp = [];
    otherRemovedParsed.map((questionObj, index) => {
      tempp.push({
        // "index": index,
        id: uuid(),
        type:
          questionObj.type === "dropdown-simple"
            ? "dropdown-vertical"
            : questionObj.type,
        mandatory: questionObj.mandatory ? true : false,
        title: questionObj.label,
        description: questionObj.description ? questionObj.description : "",
        enableMultiSelect: questionObj["multi-select"] ? true : false,
        options: questionObj?.fields ? questionObj.fields : [],
        fields2: questionObj?.fields2 ? questionObj?.fields2 : {},
        // "default": "",
        depends: questionObj.depends ? true : false,
        dependsSource: questionObj["depends-source"]
          ? questionObj["depends-source"]?.split("/")?.length > 1
            ? questionObj["depends-source"].split("/")[1]
            : questionObj["depends-source"]
          : "",
        "depending-childs": [],
        "depending-childs-type": [],
        enableOtherResponse:
          linkSource === questionObj.label && otherLabel ? true : false,
        otherResponseLabel:
          linkSource === questionObj.label && otherLabel ? otherLabel : "",
      });
    });

    const parsedQuestions = tempp;
    console.log("parsed", parsedQuestions);
    return parsedQuestions;
  };

  const initialCompleteSurvey = {
    surveyId: uuid(),
    surveyTitle: "", //Your new Survey
    surveyStatus: "active",
    surveyDescription: "", //Survey description
    visible: true,
    surveySettings: {
      startDateTime: new Date().toISOString(), // dateFormatter(new Date()),
      endDateTime: new Date("7/31/2050 10:12:15").toISOString(), // dateFormatter(new Date("7/31/2050 10:12:15")), // ""
      // startTime: currentTime(),
      //   endTime: currentTime(),
      // startDate: currentDate(),
      // endDate: oneWeekFromNowDate(),
      allowMultipleResponses: false,
      limitResponses: true,
      maxResponses: 99999,
      startOnPublish: false,
    },
    surveygroups: [],
    surveyQuestions: [
      {
        id: uuid(),
        title: "", // Unique Label
        description: "",
        options: ["Option 1", "Option 2"],
        type: "textbox",
        enableOtherResponse: false,
        otherResponseLabel: "",
        mandatory: false,
        visible: true,
        depends: false,
      },
    ],
  };

  const [completeSurvey, setCompleteSurvey] = useState(initialCompleteSurvey);

  const [feedbackSurveyTemplate, setFeedbackSurveyTemplate] = useState({
    surveyId: uuid(),
    surveyTitle: "Feedback Survey",
    surveyStatus: "active",
    surveyDescription: "A feedback survey to be used as a template.",
    visible: true,
    surveySettings: {
      startDateTime: dateFormatter(new Date()),
      endDateTime: dateFormatter(new Date()),
      // startTime: currentTime(),
      // endTime: currentTime(),
      // startDate: currentDate(),
      // endDate: oneWeekFromNowDate(),
      allowMultipleResponses: true,
      limitResponses: true,
      maxResponses: 1000,
      startOnPublish: false,
    },
    surveygroups: [],
    surveyQuestions: parseQuestions(feedbackSurveyQuestions2),
  });

  // useEffect(() => {

  //     if(!localStorage.getItem('ssoToken') || !localStorage.getItem('baseToken')){
  //         goToLoginAndRemoveTokensFromLS();
  //     }

  //     return () => {
  //     }
  // }, [])

  const [surveyGroups, setSurveyGroups] = useState([]); // COMMENT 1
  const [
    loadingAvailableSurveyGroups,
    setLoadingAvailableSurveyGroups,
  ] = useState(false);

  useEffect(() => {
    setLoadingAvailableSurveyGroups(true);
    axios
      .get(endpoints.availableSurveyGroups, axiosOptions)
      .then((res) => {
        const resObj = res.data;
        const dataArr = resObj.json_list;
        const cleanedArr = [];
        console.log("RAW SURVEY GROUPS", dataArr);
        try {
          if (dataArr) {
            if (dataArr.length > 0) {
              dataArr.map((surveyGroupObj) => {
                const newSurveyGroupObj = {
                  id: surveyGroupObj.id,
                  surveyGroupName: surveyGroupObj.name,
                  description: surveyGroupObj.description,
                  organizationId: surveyGroupObj.organization_id,
                  surveys: surveyGroupObj.surveys,
                  numOfSurveys: surveyGroupObj?.surveys?.length
                    ? surveyGroupObj?.surveys?.length
                    : 0,
                  created_on: dateMaker(new Date(surveyGroupObj.created_at)),
                };
                cleanedArr.push(newSurveyGroupObj);
              });
            }
          }
        } catch (err) {
          console.log(err);
          setLoadingAvailableSurveyGroups(false);
          openToast(console.log, "error", err.message);
        }
        setSurveyGroups(cleanedArr);
        setLoadingAvailableSurveyGroups(false);
        console.log("CLEANEED SURVEY GROUPS", cleanedArr);
      })
      .catch((err) => {
        console.log("ERROR IN REQUEST", err);
        setLoadingAvailableSurveyGroups(false);
        // errToast(err);
      });
  }, [surveyGroups.length, availableSurveys.length]);

  const resetCompleteSurvey = () => setCompleteSurvey(initialCompleteSurvey);

  return (
    <motion.div exit={{ opacity: 0 }} className="flex w-full flex-nowrap">
      <Sidebar
        setPageOpen={setPageOpen}
        resetCompleteSurvey={resetCompleteSurvey}
        setOpenNewSurveyCard={setOpenNewSurveyCard}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
      <div className="w-full pb-40 mt-20 bg-white">
        {openNewSurveyCard ? (
          // <Suspense fallback={<LoadingAnimation />}>
          <CreateSurvey
            LoadingAnimation={LoadingAnimation}
            openToast={openToast}
            setSelectedPage={setSelectedPage}
            setOpenNewSurveyCard={setOpenNewSurveyCard}
            axiosOptions={axiosOptions}
            goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            endpoints={endpoints}
            pageOpen={pageOpen}
            setPageOpen={setPageOpen}
            completeSurvey={completeSurvey}
            setCompleteSurvey={setCompleteSurvey}
            updateSurvey={updateSurvey}
            setUpdateSurvey={setUpdateSurvey}
            resetCompleteSurvey={resetCompleteSurvey}
            surveyGroups={surveyGroups}
          />
        ) : // {/* </Suspense> */}
        selectedPage === "home" ? (
          // <Suspense fallback={<span className="h-full col-center"><LoadingAnimation /></span>}>
          <MainSurvey
            EmptyTableSVG={EmptyTableSVG}
            LoadingAnimation={LoadingAnimation}
            openToast={openToast}
            openNewSurveyCard={openNewSurveyCard}
            setOpenNewSurveyCard={setOpenNewSurveyCard}
            axiosOptions={axiosOptions}
            goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            endpoints={endpoints}
            pageOpen={pageOpen}
            setPageOpen={setPageOpen}
            completeSurvey={completeSurvey}
            setCompleteSurvey={setCompleteSurvey}
            feedbackSurveyTemplate={feedbackSurveyTemplate}
            setFeedbackSurveyTemplate={setFeedbackSurveyTemplate}
            updateSurvey={updateSurvey}
            setUpdateSurvey={setUpdateSurvey}
            availableSurveys={availableSurveys}
            setAvailableSurveys={setAvailableSurveys}
          />
        ) : //   </Suspense>

        selectedPage === "survey_groups" ? (
          // <Suspense fallback={<span className="h-full col-center"><LoadingAnimation /></span>}>
          <SurveyGroupPage
            openToast={openToast}
            axiosOptions={axiosOptions}
            goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            endpoints={endpoints}
            availableSurveys={availableSurveys}
            setAvailableSurveys={setAvailableSurveys}
            EmptyTableSVG={EmptyTableSVG}
            LoadingAnimation={LoadingAnimation}
            loadingAvailableSurveyGroups={loadingAvailableSurveyGroups}
            surveyGroups={surveyGroups}
            setSurveyGroups={setSurveyGroups}
          />
        ) : // </Suspense>

        selectedPage === "users" ? (
          // <Suspense fallback={<span className="h-full col-center"><LoadingAnimation /></span>}>
          <UsersPage
            EmptyTableSVG={EmptyTableSVG}
            LoadingAnimation={LoadingAnimation}
            openToast={openToast}
            axiosOptions={axiosOptions}
            goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            endpoints={endpoints}
          />
        ) : // </Suspense>
        selectedPage === "user_groups" ? (
          // <Suspense fallback={<span className="h-full col-center"><LoadingAnimation /></span>}>
          <UserGroupPage
            availableSurveys={availableSurveys}
            surveyGroups={surveyGroups}
            openToast={openToast}
            axiosOptions={axiosOptions}
            goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            endpoints={endpoints}
            LoadingAnimation={LoadingAnimation}
            EmptyTableSVG={EmptyTableSVG}
          />
        ) : // </Suspense>
        selectedPage === "roles" ? (
          // <Suspense fallback={<span className="h-full col-center"><LoadingAnimation /></span>}>
          <RolesPage
            EmptyTableSVG={EmptyTableSVG}
            LoadingAnimation={LoadingAnimation}
            openToast={openToast}
            axiosOptions={axiosOptions}
            goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            endpoints={endpoints}
            availableSurveys={availableSurveys}
            surveyGroups={surveyGroups}
          />
        ) : // </Suspense>
        selectedPage === "emails" ? (
          <Emails
            EmptyTableSVG={EmptyTableSVG}
            LoadingAnimation={LoadingAnimation}
            openToast={openToast}
            axiosOptions={axiosOptions}
            goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            endpoints={endpoints}
            availableSurveys={availableSurveys}
            surveyGroups={surveyGroups}
          />
        ) : (
          <>
            <MainSurvey
              openToast={openToast}
              openNewSurveyCard={openNewSurveyCard}
              setOpenNewSurveyCard={setOpenNewSurveyCard}
              availableSurveys={availableSurveys}
              setAvailableSurveys={setAvailableSurveys}
            />
          </>
        )}
      </div>
    </motion.div>
  );
};

export default MainPage;
