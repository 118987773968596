import React, {useState, useEffect} from 'react'
import AddNewSurveyGroupCard from './AddNewSurveyGroupCard';

import axios from 'axios';

import {
    EditIcon,
    DeleteIcon,
} from '../../Icons';

import ConfirmationCard from '../../ConfirmationCard';
import ReactTooltip from 'react-tooltip';
import EmptyResource from '../../EmptyResource';

const SurveyGroupPage = ({
    openToast,
    axiosOptions,
    goToLoginAndRemoveTokensFromLS,
    endpoints,
    LoadingAnimation,
    EmptyTableSVG,
    availableSurveys,
    surveyGroups,
    setSurveyGroups,
    loadingAvailableSurveyGroups
}) => {
    const [openAddNewSurveyGroupCard, setOpenAddNewSurveyGroupCard] = useState(false);

     
    const [updateSurveyGroup, setUpdateSurveyGroup] = useState(false);
    const [selectedSurveyGroup, setSelectedSurveyGroup] = useState({
        name: '',
        description: '',
        users: []
    });

    const errToast = () => openToast( 
        console.log, // goToLoginAndRemoveTokensFromLS, 
        'error', 
        "You're not authorized for this operation." 
        );

    // const [surveyGroupsArray, setSurveyGroupsArray] = useState([]);

    
    
    const handleSurveyGroupDeleteClick = (id) => {
        axios.delete(`${endpoints.deleteSurveyGroups}/${id}`, axiosOptions).then((res)=>{
            setSurveyGroups(prev=>prev.filter(item=>item.id!==id));
            openToast(
                ()=>console.log('survey group deleted'),
                'success',
                "Successfully deleted survey group."
            );
        }).catch(err=>{
            if(['unsupported authentication type', 'expired token'].includes(err.response?.data)) goToLoginAndRemoveTokensFromLS();
            console.log('error:', err);
            errToast();
        })
        // console.log(surveyGroups.filter(item=>item.id===id))
    }

    const handleSurveyGroupEditClick = (id) => {
        const selectedSurveyGroupObj = surveyGroups.filter(item=>item.id===id)[0];
        setUpdateSurveyGroup(true);
        setSelectedSurveyGroup({
            description: selectedSurveyGroupObj.description,
            id: id,
            numOfSurveys: selectedSurveyGroupObj.numOfSurveys,
            organizationId: selectedSurveyGroupObj.organizationId,
            name: selectedSurveyGroupObj.surveyGroupName,
            surveys: selectedSurveyGroupObj.surveys
        });

        console.log('selectedSurveyGroupObj.surveys', selectedSurveyGroupObj.surveys);
        setOpenAddNewSurveyGroupCard(true);
    }

    return (
        <div className="flex flex-col w-full space-y-16 transition-all">
            <div className="flex items-center space-x-5">
                <div className="mt-8 ml-10 blue-heading">Survey Groups</div>
                <button
                    disabled={loadingAvailableSurveyGroups}
                    onClick={()=>{
                        setUpdateSurveyGroup(false);
                        setSelectedSurveyGroup({
                            name: '',
                            description: '',
                            users: []
                        })
                        setOpenAddNewSurveyGroupCard(true);
                    }} 
                    // style={{boxShadow:'0px 8px 18px #FFC9CB'}} 
                    className={`mt-8 ${loadingAvailableSurveyGroups ? 'opacity-25' : 'opacity-100'} text-sm text-white shadow-2xl w-36 h-9 bg-red rounded-xl`}>
                        Add New
                </button>
            </div>
            {/* <div className="w-full center">
                <span className="underDevelopmentCard">This page is undergoing development.</span>
            </div> */}
            {
                openAddNewSurveyGroupCard && 
                <span className="w-full">
                    <AddNewSurveyGroupCard
                        updateSurveyGroup={updateSurveyGroup}
                        selectedSurveyGroup={selectedSurveyGroup}
                        availableSurveys={availableSurveys}
                        setOpenAddNewSurveyGroupCard={setOpenAddNewSurveyGroupCard}
                        openToast={openToast}
                        axiosOptions={axiosOptions}
                        goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
                        endpoints={endpoints}
                        LoadingAnimation={LoadingAnimation}
                        errToast = {errToast}
                        setSurveyGroups={setSurveyGroups}
                        surveyGroups={surveyGroups}
                        setUpdateSurveyGroup = {setUpdateSurveyGroup}
                    />
                </span>
            }
            <div style={{boxShadow:'3px 4px 12px rgba(0, 0, 0, 0.19)'}} className="w-11/12 p-5 mx-10 h-96 rounded-xl">
                <SurveyGroupTable 
                    handleSurveyGroupEditClick={handleSurveyGroupEditClick} 
                    handleSurveyGroupDeleteClick={handleSurveyGroupDeleteClick} 
                    EmptyTableSVG={EmptyTableSVG} 
                    LoadingAnimation={LoadingAnimation} 
                    loadingAvailableSurveyGroups={loadingAvailableSurveyGroups} 
                    surveyGroups={surveyGroups} />
            </div> 
        </div>
    )
}

const SurveyGroupTable = ({ 
    handleSurveyGroupEditClick,
    handleSurveyGroupDeleteClick, 
    surveyGroups, 
    LoadingAnimation, 
    loadingAvailableSurveyGroups, 
    availableUsers, 
    selectedUsers, 
    setSelectedUsers }) => {
    
    return (
        <div className= {surveyGroups.length>5 ? "space-y-5 overflow-x-hidden overflow-y-scroll h-72" : "space-y-5 overflow-x-hidden overflow-y-visible h-72"}>
            <TableTitles />
            {
                !loadingAvailableSurveyGroups ?
                <span className="flex flex-col pt-8 space-y-5">
                    {
                        surveyGroups.length>0 ?
                        <> { surveyGroups.map((surveyGroup, index) => 
                            <RowItem 
                                handleSurveyGroupEditClick = {handleSurveyGroupEditClick}
                                handleSurveyGroupDeleteClick = {handleSurveyGroupDeleteClick} 
                                surveyGroup={surveyGroup} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} index={index} key={index} />) } </>
                        :
                        <EmptyResource text="No Survey Groups Found" />
                    }
                </span>
                :
                <span className="pt-20 center"><LoadingAnimation /> </span>
            }
        </div>
    )
}

const RowItem =  ({
    handleSurveyGroupEditClick, 
    handleSurveyGroupDeleteClick, surveyGroup, index, selectedUsers, setSelectedUsers }) => {

    const [openConfirmationCard, setOpenConfirmationCard] = useState(false);

    return (
        <span key={surveyGroup.id} className="flex">
            <Checkbox>
                <span className="w-20 text-center break-all lg:w-1/12">{index+1}</span>
                <span className="flex flex-wrap justify-center text-center break-all w-44 lg:w-3/12">{surveyGroup.surveyGroupName}</span>
                <span className="w-16 text-center lg:w-2/12">{surveyGroup.numOfSurveys}</span>
                <span className="w-20 text-center break-all lg:w-3/12">{surveyGroup.created_on}</span>
                {/* <span className="w-20">
                    { surveyGroup.status==='active' ? <Active />: <Inactive /> }
                </span> */}
                <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-1/12">
                    {/* <span className="pointer">
                    {
                        surveyGroup.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span> */}
                    <span data-background-color="#8B0021" data-tip="Edit" onClick={()=>handleSurveyGroupEditClick(surveyGroup.id)} className="pointer">
                        <EditIcon />
                    </span>
                    <span data-background-color="#8B0021" data-tip="Delete" onClick={()=>setOpenConfirmationCard(true)} className="pointer">
                        <DeleteIcon />
                    </span>
                    {
                        openConfirmationCard &&
                        <ConfirmationCard deleteFunc={()=>handleSurveyGroupDeleteClick(surveyGroup.id)} setOpenConfirmationCard={setOpenConfirmationCard}/>
                    }
                </span>
            </Checkbox>
            {/* <span className="flex justify-end w-2/4 pr-10 space-x-3">
                    <span className="pointer">
                    {
                        surveyGroup.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span>
                    <span className="pointer">
                        <EditIcon />
                    </span>
                    <span className="pointer">
                        <DeleteIcon />
                    </span>
            </span> */}
            <ReactTooltip />
        </span>
    )
}

// const Active = () => <div className="space-x-2 center"><span><ActiveIcon /></span><span>Active</span></div>
// const Inactive = () => <span className="space-x-2 center"><span><InactiveIcon /></span><span>Inactive</span></span>


const Checkbox = ({ children }) => {

    return (
        <div className="flex items-start w-full font-semibold text-blue">
            {/* <span className="mt-1 ml-9">{checked ? <CheckedIcon /> : <UncheckedIcon /> }</span> */}
            <span className="flex w-full ml-10 space-x-2 lg:space-x-6 md:space-x-2 xl:space-x-2">{children}</span> 
        </div>
    )
}

const TableTitles = () => (
    <span className="flex items-center justify-start mx-10 space-x-2 font-semibold lg:space-x-3 md:space-x-3 xl:space-x-3 text-grey-lightest flex-nowrap ">
        <span className="w-10 text-center break-all lg:w-1/12">No.</span>
        <span className="flex flex-wrap justify-center text-center break-all w-44 lg:w-3/12">Group Name</span>
        <span className="w-16 text-center lg:w-2/12">No of Surveys</span>
        <span className="w-20 pl-4 text-center break-all lg:w-3/12">Created On</span>
        <span className="flex justify-center w-20 text-center flex-nowrap lg:w-2/12"></span>
    </span>
)

export default SurveyGroupPage
