import React from 'react';
import arrow_down from '../../../assets/arrow_down.svg';


const SelectedOption = ({ selectedOption }) => {
    return (
        <div className="z-0">
    {
        selectedOption === 'dropdown-vertical' ?
        <span className="flex items-center justify-start space-x-1 xl:space-x-3">
            <span><SmallDndIcon /></span>
            <span>Simple Drop Down</span>
            <DdIcon />
        </span>
        :
        selectedOption === 'textbox' ?
        <span className="flex items-center justify-start space-x-4 xl:space-x-5">
            <span><TextIcon /></span>
            <span>Text Box</span>
            <span className="pl-14"><DdIcon /></span>
        </span>
        :
        selectedOption === 'dropdown-horizontal' ?
        <span className="flex items-center justify-start space-x-1 xl:space-x-3">
            <span><HorizontalDropDownIcon /></span>
            <span>Horizontal Drop Down</span>
            <DdIcon />
        </span>
        :
        selectedOption === 'checkbox-dropdown-vertical' ?
        <span className="flex items-center justify-start space-x-1 xl:space-x-3">
            <span><CheckBoxDropDownIcon /></span>
            <span>Checkbox Dropdown</span>
            <DdIcon />
        </span>
        :
        selectedOption === 'checkbox-simple' ?
        <span className="flex items-center justify-start space-x-2 xl:space-x-4">
            <span><SimpleCheckBoxIcon /></span>
            <span>Simple Checkbox</span>
            <DdIcon />
        </span>
        :
        <span className="flex items-center justify-start space-x-2 xl:space-x-3">
            <span>
                Question Type
            </span> 
            <DdIcon />
        </span>
    }
        </div>
    )
}

const DdIcon = () =>  <span><img width="15" height="15" src={arrow_down} alt="dd_icon"/></span>
const SmallDndIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18 12H6" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18 8H6" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18 16H6" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const DndIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 10H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 6H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 14H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 18H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const TextIcon = () => <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.35 0.199999V1.74H4.592V10H2.982V1.74H0.21V0.199999H7.35Z" fill="#2F2E41"/> </svg>
const HorizontalDropDownIcon = () => <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 1L1 1" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15 1L10 1" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const CheckBoxDropDownIcon = () => <span className="center"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="10" height="10" rx="5" fill="#343434"/> <path d="M7.49996 3.33325L4.34892 6.8798L2.91663 5.26773" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 3.75L5 6.25L7.5 3.75" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>
const SimpleCheckBoxIcon = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="14" height="14" rx="3" fill="#343434"/> <path d="M10.5 4.66675L6.08858 9.63192L4.08337 7.37502" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>

export default SelectedOption
