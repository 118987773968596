import axios from "axios";
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";

import ConfirmationCard from "../../ConfirmationCard";
import EmptyResource from "../../EmptyResource";

const MainSurveyTableCard = ({
  openToast,
  loadingAvailableSurveys,
  LoadingAnimation,
  feedbackSurveyTemplate,
  setFeedbackSurveyTemplate,
  pageOpen,
  setPageOpen,
  completeSurvey,
  setCompleteSurvey,
  availableSurveys,
  updateSurvey,
  setUpdateSurvey,
  openNewSurveyCard,
  setOpenNewSurveyCard,
  setAvailableSurveys,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
  endpoints,
}) => {
  const classes = {};

  const handleSurveyEditClick = (surveyObj) => {
    setCompleteSurvey({
      surveyId: surveyObj.id,
      surveyTitle: surveyObj.name,
      surveyStatus: surveyObj.status,
      surveyDescription: surveyObj.surveyDescription,
      visible: surveyObj.visible,
      surveySettings: surveyObj.surveySettings,
      surveygroups: surveyObj.surveygroups,
      surveyQuestions: surveyObj.surveyQuestions,
    });
    // console.log(surveyObj.surveySettings);
    setPageOpen("create");
    setOpenNewSurveyCard(true);
    setUpdateSurvey(true);
  };
  const handleSurveyReviewClick = (surveyObj) => {
    setCompleteSurvey({
      surveyId: surveyObj.id,
      surveyTitle: surveyObj.name,
      surveyStatus: surveyObj.status,
      surveyDescription: surveyObj.surveyDescription,
      visible: surveyObj.visible,
      surveySettings: surveyObj.surveySettings,
      surveyGroups: surveyObj.groups,
      surveyQuestions: surveyObj.surveyQuestions,
    });
    // console.log(surveyObj.surveySettings);
    setOpenNewSurveyCard(true);
    setPageOpen("review");
    setUpdateSurvey(true);
  };

  return (
    <div className="w-full">
      <div
        style={{ boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.19)" }}
        className="w-11/12 p-5 mx-10 h-96 rounded-xl"
      >
        <SurveyGroupTable
          handleSurveyReviewClick={handleSurveyReviewClick}
          openToast={openToast}
          LoadingAnimation={LoadingAnimation}
          loadingAvailableSurveys={loadingAvailableSurveys}
          handleSurveyEditClick={handleSurveyEditClick}
          setAvailableSurveys={setAvailableSurveys}
          axiosOptions={axiosOptions}
          goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
          endpoints={endpoints}
          availableSurveys={availableSurveys}
        />
      </div>
    </div>
  );
};

const SurveyGroupTable = ({
  openToast,
  handleSurveyReviewClick,
  EmptyTableSVG,
  LoadingAnimation,
  loadingAvailableSurveys,
  handleSurveyEditClick,
  availableSurveys,
  setAvailableSurveys,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
  endpoints,
}) => {
  return (
    <div
      key={availableSurveys.length}
      className={
        availableSurveys.length > 4
          ? "space-y-5 w-full overflow-x-hidden overflow-y-scroll h-72"
          : "space-y-5 overflow-x-hidden overflow-y-visible h-80"
      }
    >
      <span className="flex items-center justify-start w-full space-x-4 font-semibold lg:space-x-8 md:space-x-8 text-grey-lightest flex-nowrap ">
        <TableTitles />
      </span>
      {!loadingAvailableSurveys ? (
        <span className="flex flex-col pt-8 space-y-5">
          {availableSurveys.length > 0 ? (
            <>
              {availableSurveys.map((surveyObj, index) => (
                <RowItem
                  handleSurveyReviewClick={handleSurveyReviewClick}
                  openToast={openToast}
                  handleSurveyEditClick={handleSurveyEditClick}
                  setAvailableSurveys={setAvailableSurveys}
                  availableSurveys={availableSurveys}
                  axiosOptions={axiosOptions}
                  goToLoginAndRemoveTokensFromLS={
                    goToLoginAndRemoveTokensFromLS
                  }
                  endpoints={endpoints}
                  surveyObj={surveyObj}
                  index={index}
                  key={index}
                />
              ))}
            </>
          ) : (
            <EmptyResource text="No Surveys Found" />
          )}
        </span>
      ) : (
        <span className="pt-20 center">
          {" "}
          <LoadingAnimation />{" "}
        </span>
      )}
    </div>
  );
};

const Checkbox = ({ children }) => {
  return (
    <div className="flex items-start w-full font-semibold text-blue">
      {/* <span className="mt-1 ml-9">{checked ? <CheckedIcon /> : <UncheckedIcon /> }</span> */}
      <span className="flex w-full space-x-8">{children}</span>
    </div>
  );
};

const RowItem = ({
  openToast,
  handleSurveyReviewClick,
  handleSurveyEditClick,
  surveyObj,
  index,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
  endpoints,
  availableSurveys,
  setAvailableSurveys,
}) => {
  const [openConfirmationCard, setOpenConfirmationCard] = useState(false);

  const handleDeleteSurvey = () => {
    axios
      .delete(`${endpoints.deleteSurvey}/${surveyObj.id}`, axiosOptions)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let newArr = [...availableSurveys];
          const deletedSurvey = newArr.splice(index, 1);
          setAvailableSurveys(newArr);
          openToast(console.log, "success", "Successfully deleted survey.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        else
          openToast(
            console.log,
            "error",
            "You're not authorized for this operation."
          );
      });
  };

  return (
    <span key={surveyObj.id} className="flex">
      <Checkbox>
        <span className="w-3 text-center lg:w-1/12 ">{index + 1}</span>
        <span className="w-20 text-center lg:w-4/12 ">{surveyObj.name}</span>
        {/* <span className="w-8 lg:w-16">{surveyObj.group}</span> */}
        <span className="w-5 text-center lg:w-3/12 md:w-10 ">
          {surveyObj.created_on}
        </span>
        {/* <span className="w-5 text-center lg:w-1/12 md:w-10 ">{surveyObj.responses}</span> */}
        {/* <span className="w-5 text-center lg:w-1/12 ">
                    { surveyObj.status==='active' ? <Active />: <Inactive /> }
                </span> */}
        <span className="flex w-2/12 space-x-4 text-center ">
          {/* <span data-background-color="#8B0021" data-tip="Review" onClick={()=>handleSurveyReviewClick(surveyObj)} className="pointer">
                    {
                        surveyObj.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span> */}
          <span
            data-background-color="#8B0021"
            data-tip="Edit"
            onClick={() => handleSurveyEditClick(surveyObj)}
            className="pointer"
          >
            <EditIcon />
          </span>
          <span
            data-background-color="#8B0021"
            data-tip="Delete"
            onClick={() => setOpenConfirmationCard(true)}
            className="pointer"
          >
            <DeleteIcon />
          </span>
          {openConfirmationCard && (
            <ConfirmationCard
              deleteFunc={handleDeleteSurvey}
              setOpenConfirmationCard={setOpenConfirmationCard}
            />
          )}
        </span>
      </Checkbox>
      {/* <span className="flex justify-end w-2/4 pr-10 space-x-3">
                    <span className="pointer">
                    {
                        surveyObj.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span>
                    <span className="pointer">
                        <EditIcon />
                    </span>
                    <span className="pointer">
                        <DeleteIcon />
                    </span>
            </span> */}
      <ReactTooltip />
    </span>
  );
};

const TableTitles = () => (
  <>
    <span className="w-1/12 text-center ">No.</span>
    <span className="w-4/12 text-center">Name</span>
    {/* <span>Group</span> */}
    <span className="w-3/12 text-center ">Created On</span>
    {/* <span className="w-1/12 text-center">Responses</span> */}
    {/* <span className="w-1/12 text-center ">Status</span> */}
  </>
);

// const Active = () => <div className="space-x-2 center"><span><ActiveIcon /></span><span>Active</span></div>
// const Inactive = () => <span className="space-x-2 center"><span><InactiveIcon /></span><span>Inactive</span></span>

// const CheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="18" height="18" rx="6" fill="#8B0021"/> <path d="M13.5 6L7.82813 12.3838L5.25 9.48207" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
// const UncheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="17" height="17" rx="5.5" stroke="#CCCCCC"/> </svg>
// const EyeInvisibleIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#878787" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#878787"/> </svg>
// const EyeVisibleIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#8B0021" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#8B0021"/> </svg>
const ActiveIcon = () => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#8CD76A" />{" "}
  </svg>
);
const InactiveIcon = () => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#CCCCCC" />{" "}
  </svg>
);
const EditIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      d="M16 2.00006C16.2626 1.73741 16.5744 1.52907 16.9176 1.38693C17.2608 1.24479 17.6286 1.17163 18 1.17163C18.3714 1.17163 18.7392 1.24479 19.0824 1.38693C19.4256 1.52907 19.7374 1.73741 20 2.00006C20.2626 2.2627 20.471 2.57451 20.6131 2.91767C20.7553 3.26083 20.8284 3.62862 20.8284 4.00006C20.8284 4.37149 20.7553 4.73929 20.6131 5.08245C20.471 5.42561 20.2626 5.73741 20 6.00006L6.5 19.5001L1 21.0001L2.5 15.5001L16 2.00006Z"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
  </svg>
);
const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      d="M3 6H5H21"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M10 11V17"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M14 11V17"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
  </svg>
);

export default MainSurveyTableCard;
