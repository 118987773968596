import React from 'react'

const ReviewDropdown = () => {
    return (
        <div className="s">
            <span className="sp">dropdown</span>
        </div>
    )
}

const DropdownIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4.5 6.75L9 11.25L13.5 6.75" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>

export default ReviewDropdown
