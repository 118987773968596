import React, { useState, useEffect } from "react";

import CreateASurvey from "./Create/CreateASurvey";
import ReviewASurvey from "./ReviewSurvey/ReviewASurvey";
import SettingsOfNewSurvey from "./Settings/SettingsOfNewSurvey";
import PublishNewSurvey from "./Publish/PublishNewSurvey";
import axios from "axios";

import makeJSON from "./utils/makeJSON";

const CreateSurvey = ({
  openToast,
  LoadingAnimation,
  pageOpen,
  setPageOpen,
  setSelectedPage,
  setOpenNewSurveyCard,
  endpoints,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
  completeSurvey,
  setCompleteSurvey,
  updateSurvey,
  setUpdateSurvey,
  resetCompleteSurvey,
  surveyGroups,
}) => {
  // "dropdown-simple", "dropdown-horizontal", "checkbox-dropdown-vertical", "checkbox-simple", "textbox"

  const [surveyTitle, setSurveyTitle] = useState(completeSurvey.surveyTitle);
  const [questions, setQuestions] = useState(completeSurvey.surveyQuestions);

  useEffect(() => {
    const newObj = { ...completeSurvey };
    newObj.surveyTitle = surveyTitle;
    setCompleteSurvey(newObj);
  }, [surveyTitle]);

  useEffect(() => {
    const newObj = { ...completeSurvey };
    newObj.surveyQuestions = questions;
    setCompleteSurvey(newObj);
    // console.log(completeSurvey)
  }, [questions]);

  // questions.id,questions.title,questions.description,questions.options, questions.options.length, questions.type, questions.enableOtherResponse

  let prevSurveyState = {};

  const errToastWithMsg = (msg) => openToast(console.log, "error", msg);
  const checkAtLestOneQuestionMandatory = (survey) => {
    let ret = false;
    survey?.surveyQuestions?.map((question) => {
      if (question.mandatory) ret = true;
    });

    return ret;
  };

  const sendNewSurveyToApi = () => {
    prevSurveyState = makeJSON(
      completeSurvey,
      prevSurveyState,
      setCompleteSurvey
    );

    if (!checkAtLestOneQuestionMandatory(prevSurveyState)) {
      errToastWithMsg("One survey question must be mandatory.");
      setPageOpen("create");
      return;
    }

    console.log("prevSurveyState", prevSurveyState);

    axios
      .post(endpoints.createSurvey, prevSurveyState, axiosOptions)
      .then((res) => {
        if (res.body === "survey created") console.log(res);
        if (res.status === 201) {
          resetCompleteSurvey();
          setPageOpen("publish");
          return true;
        } else {
          // resetCompleteSurvey();
          console.log(res);
          openToast(
            console.log, //goToLoginAndRemoveTokensFromLS,
            "error",
            "You're not authorized for this operation."
          );
        }
      })
      .catch((err) => {
        console.log(err);
        resetCompleteSurvey();
        errToastWithMsg(`error: ${err.response?.data}`);
      });
  };

  const updateSurveyToApi = () => {
    prevSurveyState = makeJSON(
      completeSurvey,
      prevSurveyState,
      setCompleteSurvey
    );

    if (!checkAtLestOneQuestionMandatory(prevSurveyState)) {
      errToastWithMsg("One survey question must be mandatory.");
      setPageOpen("create");
      return;
    }

    console.log("prevSurveyState", prevSurveyState);
    axios
      .post(
        `${endpoints.updateSurvey}/${completeSurvey.surveyId}`,
        prevSurveyState,
        axiosOptions
      )
      .then((res) => {
        // if (res.body==='survey created') console.log(res);
        if (res.status === 201) {
          resetCompleteSurvey();
          setPageOpen("publish");
          return true;
        } else {
          console.log(res);
          openToast(
            console.log, //goToLoginAndRemoveTokensFromLS,
            "error",
            "You're not authorized for this operation."
          );
        }
      })
      .catch((err) => {
        console.log(err);
        errToastWithMsg(`error: ${err.response?.data}`);
      });
  };

  const [selectedSurveyGroups, setSelectedSurveyGroups] = useState(
    completeSurvey.surveygroups
  );
  useEffect(() => {
    const tempp = selectedSurveyGroups?.map((item) => ({
      id: item.id,
      name: item.surveyGroupName,
    }));
    if (tempp?.length)
      setCompleteSurvey((prev) => ({ ...prev, surveygroups: [...tempp] }));
  }, [selectedSurveyGroups]);

  return (
    <div className="transition-all">
      {console.log(
        "completeSurvey.surveyQuestions",
        completeSurvey.surveyQuestions
      )}
      {pageOpen === "create" ? (
        <CreateASurvey
          openToast={openToast}
          pageOpen={pageOpen}
          setPageOpen={setPageOpen}
          questions={questions}
          setQuestions={setQuestions}
          surveyTitle={surveyTitle}
          setSurveyTitle={setSurveyTitle}
          surveyId={completeSurvey.surveyId}
          // selectedOption={selectedOption}
          // setSelectedOption={setSelectedOption}
          setSelectedPage={setSelectedPage}
          setOpenNewSurveyCard={setOpenNewSurveyCard}
          surveyGroups={surveyGroups}
          completeSurvey={completeSurvey}
          setCompleteSurvey={setCompleteSurvey}
          selectedSurveyGroups={selectedSurveyGroups}
          setSelectedSurveyGroups={setSelectedSurveyGroups}
          resetCompleteSurvey={resetCompleteSurvey}
        />
      ) : pageOpen === "review" ? (
        <ReviewASurvey
          pageOpen={pageOpen}
          setPageOpen={setPageOpen}
          questions={questions}
          setQuestions={setQuestions}
          surveyTitle={surveyTitle}
          setSurveyTitle={setSurveyTitle}
          setOpenNewSurveyCard={setOpenNewSurveyCard}
          resetCompleteSurvey={resetCompleteSurvey}
        />
      ) : pageOpen === "settings" ? (
        <SettingsOfNewSurvey
          LoadingAnimation={LoadingAnimation}
          pageOpen={pageOpen}
          setPageOpen={setPageOpen}
          questions={questions}
          setQuestions={setQuestions}
          surveyTitle={surveyTitle}
          setSurveyTitle={setSurveyTitle}
          setOpenNewSurveyCard={setOpenNewSurveyCard}
          completeSurvey={completeSurvey}
          setCompleteSurvey={setCompleteSurvey}
          sendNewSurveyToApi={sendNewSurveyToApi}
          updateSurvey={updateSurvey}
          setUpdateSurvey={setUpdateSurvey}
          updateSurveyToApi={updateSurveyToApi}
          resetCompleteSurvey={resetCompleteSurvey}
        />
      ) : pageOpen === "publish" ? (
        <PublishNewSurvey
          pageOpen={pageOpen}
          setPageOpen={setPageOpen}
          questions={questions}
          surveyTitle={surveyTitle}
          setOpenNewSurveyCard={setOpenNewSurveyCard}
          setSelectedPage={setSelectedPage}
          setUpdateSurvey={setUpdateSurvey}
        />
      ) : null}
    </div>
  );
};

export default CreateSurvey;
