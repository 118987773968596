import { endDateTimeMaker, startDateTimeMaker } from "../Settings/utils";

const makeJSON = (completeSurvey, prevSurveyState, setCompleteSurvey) => {
  console.log("BEFORE CLEANING:", completeSurvey.surveyQuestions);
  const cleanedQuestionsJson = [];
  completeSurvey.surveyQuestions.map((questionObj, index) => {
    const depends = questionObj?.depends;
    const fields2 = questionObj?.fields2;
    const isFields2 = fields2
      ? Object.keys(fields2)?.length > 0
        ? true
        : false
      : false;
    // const dependingChildsType = questionObj?.dependingChildType;
    const dependsSource = questionObj?.dependsSource;
    if (questionObj.type === "dropdown-vertical") {
      cleanedQuestionsJson.push({
        index: 0,
        type: "dropdown-simple",
        mandatory: questionObj.mandatory ? 1 : 0,
        label: questionObj.title,
        description: questionObj.description,
        fields: isFields2
          ? []
          : questionObj.enableOtherResponse
          ? [...questionObj.options, questionObj.otherResponseLabel]
          : [...questionObj.options],
        fields2: isFields2 ? fields2 : {},
        default: "",
        depends: depends ? true : false,
        "depends-source": dependsSource ? dependsSource : "",
        "depending-childs": [],
        "depending-childs-type": [], //dependingChildsType?.length>0 ? dependingChildsType : []
        // "multi-select": 0,
        otherResponseLabel: questionObj.otherResponseLabel,
      });
    }
    if (questionObj.type === "dropdown-horizontal") {
      cleanedQuestionsJson.push({
        // "index": index,
        type: "dropdown-horizontal",
        mandatory: questionObj.mandatory ? 1 : 0,
        label: questionObj.title,
        description: questionObj.description,
        "multi-select": questionObj.enableMultiSelect ? 1 : 0,
        fields: questionObj.enableOtherResponse
          ? [...questionObj.options, questionObj.otherResponseLabel]
          : [...questionObj.options],
        fields2: {},
        default: "",
        depends: depends ? true : false,
        "depends-source": dependsSource ? dependsSource : "",
        "depending-childs": [],
        "depending-childs-type": [],
        otherResponseLabel: questionObj.otherResponseLabel,
      });
    }
    if (questionObj.type === "checkbox-dropdown-vertical") {
      cleanedQuestionsJson.push({
        // "index": index,
        type: "checkbox-dropdown-vertical",
        mandatory: questionObj.mandatory ? 1 : 0,
        label: questionObj.title,
        "multi-select": questionObj.enableMultiSelect ? 1 : 0,
        fields: questionObj.enableOtherResponse
          ? [...questionObj.options, questionObj.otherResponseLabel]
          : [...questionObj.options],
        fields2: {},
        default: "",
        depends: depends ? true : false,
        "depends-source": dependsSource ? dependsSource : "",
        "depending-childs": [],
        "depending-childs-type": [],
        otherResponseLabel: questionObj.otherResponseLabel,
      });
    }
    if (questionObj.type === "checkbox-simple") {
      cleanedQuestionsJson.push({
        // "index": index,
        type: "checkbox-simple",
        mandatory: questionObj.mandatory ? 1 : 0,
        label: questionObj.title,
        "multi-select": questionObj.enableMultiSelect ? 1 : 0,
        fields: questionObj.enableOtherResponse
          ? [...questionObj.options, questionObj.otherResponseLabel]
          : [...questionObj.options],
        fields2: {},
        default: "",
        depends: depends ? true : false,
        otherResponseLabel: questionObj.otherResponseLabel,
      });
    }
    if (questionObj.type === "textbox") {
      cleanedQuestionsJson.push({
        // "index": index,
        type: "textbox",
        mandatory: questionObj.mandatory ? 1 : 0,
        label: questionObj.title,
        "character-limit": 270,
        placeholder: "Enter response here",
        link: false,
        default: "",
      });
    }

    // cleanedQuestionsJson.splice(index, 0, )
  });

  const returnObjForTextbox = (mandatory, label, linkSource) => {
    return {
      // "index": index,
      type: "textbox",
      mandatory: mandatory,
      label: label,
      "character-limit": 270,
      placeholder: "Enter reason",
      link: true,
      "Link-Source": linkSource,
      "Link-if": label,
      default: "",
    };
  };

  const otherAddedCleanedQuestions = [];

  cleanedQuestionsJson.map((cleanedObj, index) => {
    if (cleanedObj.type === "textbox") {
      otherAddedCleanedQuestions.push(cleanedObj);
    } else {
      const otherResponseLabel = cleanedObj.otherResponseLabel;
      delete cleanedObj.otherResponseLabel;
      otherAddedCleanedQuestions.push(cleanedObj);
      if (cleanedObj.fields.includes(otherResponseLabel))
        otherAddedCleanedQuestions.push(
          returnObjForTextbox(
            cleanedObj.mandatory,
            otherResponseLabel,
            cleanedObj.label
          )
        );
    }
  });

  const finalQuestionsArr = [];
  otherAddedCleanedQuestions.map((item, index) => {
    item.index = index;
    finalQuestionsArr.push(item);
  });

  console.log(finalQuestionsArr);

  // Addition (Adding depending-childs)
  // As each question has a label, below array will also be our indexing guide
  const labelsToSearch = finalQuestionsArr.map((item) => item.label);

  finalQuestionsArr.map((item, index) => {
    if (item.depends && item["depends-source"]) {
      const indexes = labelsToSearch.map((label, index) => {
        if (label === item["depends-source"]) return index;
      });
      const filteredIndexes =
        indexes?.length > 0
          ? indexes.filter((item) => item !== undefined)
          : null;
      console.log("filtered indexes", filteredIndexes);
      const indexForDependent =
        filteredIndexes?.length > 0 ? filteredIndexes[0] : null;

      // double-depend
      let indexForDependentDependency = null;
      if (finalQuestionsArr[indexForDependent]?.depends) {
        const indexes = labelsToSearch.map((label, index) => {
          if (label === finalQuestionsArr[indexForDependent]["depends-source"])
            return index;
        });
        const filteredIndexes =
          indexes?.length > 0
            ? indexes.filter((item) => item !== undefined)
            : null;
        indexForDependentDependency =
          filteredIndexes?.length > 0 ? filteredIndexes[0] : null;
      }
      if (
        indexForDependentDependency !== null &&
        indexForDependentDependency !== undefined
      ) {
        console.log("indexForDependentDependency", indexForDependentDependency);
        finalQuestionsArr[indexForDependentDependency][
          "depending-childs"
        ]?.push(index);
        finalQuestionsArr[indexForDependentDependency][
          "depending-childs-type"
        ]?.push(item.type);
      }
      // double-depend depends-source
      if (
        indexForDependent !== null &&
        indexForDependent !== undefined &&
        indexForDependentDependency !== null &&
        indexForDependentDependency !== undefined
      ) {
        const dependentSource = item["depends-source"];
        const doubleDependSource =
          finalQuestionsArr[indexForDependent]["depends-source"];
        if (
          dependentSource !== null &&
          dependentSource !== undefined &&
          doubleDependSource !== null &&
          doubleDependSource !== undefined
        ) {
          let tempQuestion = { ...item };
          tempQuestion = {
            ...tempQuestion,
            "depends-source": `${doubleDependSource}/${dependentSource}`,
          };
          finalQuestionsArr.splice(index, 1, tempQuestion);
        }
      }

      if (indexForDependent !== null && indexForDependent !== undefined) {
        console.log(
          "filtered test",
          finalQuestionsArr[indexForDependent]["depending-childs"]
        );
        finalQuestionsArr[indexForDependent]["depending-childs"]?.push(index);
        finalQuestionsArr[indexForDependent]["depending-childs-type"]?.push(
          item.type
        );
      }
    }
  });
  // Unique "depending-childs" and "depending-child-type"
  // let tempFinalQuestionsArr = [];
  // finalQuestionsArr.map((item, index)=>{
  //     if(item["depending-childs-type"]?.length>1){
  //         console.log('RANNNNN')
  //         // const dependingChilds = Array.from(new Set(item["depending-childs"]));
  //         const dependingChildsType = Array.from(new Set(item["depending-childs-type"]));
  //         // finalQuestionsArr[index]["depending-childs"] = dependingChilds;
  //         let tempQuestion = {...item};
  //         tempQuestion = {...tempQuestion, "depending-childs-type": dependingChildsType}
  //         finalQuestionsArr.splice(index,1,tempQuestion);
  //     }
  // });

  //   // ISO Date
  //   const surveySettings = completeSurvey.surveySettings;
  //   const startDateTime = startDateTimeMaker(surveySettings.startDateTime);
  //   const endDateTime = endDateTimeMaker(surveySettings.endDateTime);

  prevSurveyState = { ...completeSurvey };
  prevSurveyState.surveyQuestions = finalQuestionsArr;
  setCompleteSurvey(prevSurveyState);
  return prevSurveyState;
};

export default makeJSON;
