import React from "react";

const TopNav = ({ resetCompleteSurvey, pageOpen, setPageOpen, setOpenNewSurveyCard, displayCancelButton }) => {
  const handleCancelClick = () => {
    setOpenNewSurveyCard(false);
    resetCompleteSurvey();
    setPageOpen('create');
  }
  return (
    <div className="flex justify-start mx-10 mt-8 space-x-28">
      <div className="space-x-8 text-2xl font-medium">
        <span className={pageOpen=="create" ? "text-blue-dark font-semibold" : "text-grey-lightest"}>1. Create Survey</span>
        {/* <span className={pageOpen=="review" ? "text-blue-dark font-semibold" : "text-grey-lightest"}>2. Review</span> */}
        <span className={pageOpen=="settings" ? "text-blue-dark font-semibold" : "text-grey-lightest"}>2. Settings</span>
        <span className={pageOpen=="publish" ? "text-blue-dark font-semibold" : "text-grey-lightest"}>3. Publish</span>
      </div>
      {
        displayCancelButton &&
        <span className="max-w-min">
          <button onClick={handleCancelClick} className="btn-grey">
            Cancel
          </button>
        </span>
      }
    </div>
  );
};

export default TopNav;
