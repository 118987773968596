import React, { useEffect, useRef } from "react";
import BottomNav from "../BottomNav";
import ReviewQuestion from "./ReviewQuestion";
import TopNav from "../TopNav";

const ReviewASurvey = ({
  resetCompleteSurvey,
  pageOpen,
  setPageOpen,
  questions,
  surveyTitle,
  setOpenNewSurveyCard,
  sendNewSurveyToApi,
}) => {
  const scrollNode = useRef(null);

  // Go to top of the page when this component renders
  useEffect(() => {
    const executeScroll = () => scrollNode.current.scrollIntoView();
    executeScroll();
  }, []);

  return (
    <div className="w-full" ref={scrollNode}>
      <TopNav
        resetCompleteSurvey={resetCompleteSurvey}
        setPageOpen={setPageOpen}
        pageOpen={pageOpen}
        setOpenNewSurveyCard={setOpenNewSurveyCard}
        displayCancelButton={true}
      />
      <div className="w-full mt-10 center">
        <span className="w-1/2 py-2 font-mono text-lg text-center rounded-lg bg-red-light">
          This page is undergoing development.
        </span>
      </div>
      <div className="mt-10 mb-10 space-y-5">
        <div className="flex items-center mx-10 space-x-5">
          <span className="text-2xl font-semibold text-blue-dark">
            {surveyTitle}
          </span>
          <span className="mt-1 text-sm font-medium text-red-light">
            {questions.length} Questions Total
          </span>
        </div>
        <div className="mx-10 space-y-8">
          {questions.map((question) => (
            <ReviewQuestion
              enableOtherResponse={question.enableOtherResponse}
              description={question.description}
              options={question.options}
              type={question.type}
              title={question.title}
              mandatory={question.mandatory}
            />
          ))}
        </div>
      </div>
      <BottomNav
        pageOpen={pageOpen}
        setPageOpen={setPageOpen}
        setOpenNewSurveyCard={setOpenNewSurveyCard}
      />
    </div>
  );
};

export default ReviewASurvey;
