export const finalJsonMaker = (
  roleName, 
  roleDescription, 
  permissionsArrUG, 
  permissionsArrSurveys, 
  permissionsArrSG, 
  permissionsArrRoles
  ) => {
    
    const permArrUG = individualPermissionsObjMaker(permissionsArrUG);
    const permArrSurveys = individualPermissionsObjMaker(permissionsArrSurveys);
    const permArrSG = individualPermissionsObjMaker(permissionsArrSG);
    const permArrRoles = individualPermissionsObjMaker(permissionsArrRoles);
    
    const rolesArrTemp = [
      permArrUG && permArrUG.flat(), 
      permArrSurveys && permArrSurveys.flat(), 
      permArrSG && permArrSG.flat(),
      permArrRoles && permArrRoles.flat()
    ]; 
    const rolesArr = rolesArrTemp.filter(item=>item!==undefined && item!==null).flat();
    
    const roleObjToSend = {
        name: roleName,
        description: roleDescription,
        permissions: [...rolesArr] 
    };

    if(rolesArr?.length) return roleObjToSend;
    else return null;
}

const individualPermissionsObjMaker = (permissionsArr) => {
    if(permissionsArr.length){
        const permArr = permissionsArr.map((permObj)=>{
            const toReturn = permObj?.allowed_actions?.map(allowed_action=>({
                resource_id: permObj.id,
                resource_type: permObj.resource_type,
                allowed_action: allowed_action
            }))
            return toReturn;
        })
        // console.log('permissions',permissions);
        if(permArr?.length) return permArr;
        else return null;
    } else return null;
}

export const finalJsonMakerForSP = (roleName, roleDescription, spForUsers, spForUG, spForSurveys, spForSG, spForRoles) => {
    
    const permArr = [];
    
    if(spForUsers) permArr.push(...spUsersArr);
    if(spForUG) permArr.push(...spUGArr);
    if(spForSurveys) permArr.push(...spSurveysArr);
    if(spForSG) permArr.push(...spSGArr);
    if(spForRoles) permArr.push(...spRolesArr);

    const roleObjToSend = {
        name: roleName,
        description: roleDescription,
        permissions: [...permArr] 
    };
    return roleObjToSend;
}

const spSGArr = [
    {
        "resource_type": "surveygroup",
        "allowed_action": "index"
      },
      {
        "resource_type": "surveygroup",
        "allowed_action": "create"
      },
      {
        "resource_type": "surveygroup",
        "allowed_action": "view"
      },
      {
        "resource_type": "surveygroup",
        "allowed_action": "update"
      },
      {
        "resource_type": "surveygroup",
        "allowed_action": "delete"
      }
]

const spSurveysArr = [
    {
        "resource_type": "survey",
        "allowed_action": "index"
      },
      {
        "resource_type": "survey",
        "allowed_action": "create"
      },
      {
        "resource_type": "survey",
        "allowed_action": "view"
      },
      {
        "resource_type": "survey",
        "allowed_action": "update"
      },
      {
        "resource_type": "survey",
        "allowed_action": "delete"
      }
]

const spUGArr = [
     {
        "resource_type": "usergroup",
        "allowed_action": "index"
      },
      {
        "resource_type": "usergroup",
        "allowed_action": "create"
      },
      {
        "resource_type": "usergroup",
        "allowed_action": "view"
      },
      {
        "resource_type": "usergroup",
        "allowed_action": "update"
      },
      {
        "resource_type": "usergroup",
        "allowed_action": "delete"
      }
]

const spUsersArr = [
      {
        "resource_type": "user",
        "allowed_action": "index"
      },
      {
        "resource_type": "user",
        "allowed_action": "create"
      },
      {
        "resource_type": "user",
        "allowed_action": "view"
      },
      {
        "resource_type": "user",
        "allowed_action": "update"
      },
      {
        "resource_type": "user",
        "allowed_action": "delete"
      }
]

const spRolesArr = [
    {
        "resource_type": "role",
        "allowed_action": "index"
      },
      {
        "resource_type": "role",
        "allowed_action": "create"
      },
      {
        "resource_type": "role",
        "allowed_action": "view"
      },
      {
        "resource_type": "role",
        "allowed_action": "update"
      },
      {
        "resource_type": "role",
        "allowed_action": "delete"
      }
]