import React, { useState, useEffect, useRef } from 'react'

const ConfirmationCard = ({ deleteFunc, setOpenConfirmationCard }) => {

    const handleCancelClick = () => setOpenConfirmationCard(false);
    const handleDeleteClick = () => {
        deleteFunc();
        setOpenConfirmationCard(false);
    }
    
    // const handleOutsideClick = (e) => {
    //     if (node.current?.contains(e.target)) {
    //       // inside click
    //       return;
    //     }
    //     setOpenConfirmationCard(false);
    //   }
    
    //   const node = useRef();
    
    //   useEffect(() => {
    //     // add when mounted
    //     document.addEventListener("mousedown", handleOutsideClick);
    //     // return function to be called when unmounted
    //     return () => {
    //       document.removeEventListener("mousedown", handleOutsideClick);
    //     };
    //   }, []);

    return (
        <div 
        //  ref={node}
         className="fixed transition-all top-1/3 right-1/3 fade-in">
            <div className="px-10 py-5 space-y-5 shadow-xl h-60 bg-grey-dark rounded-2xl " 
            style={{boxShadow:'2px 2px 3px 0px lightgrey', width:'450px'}}
            >
                <span className="flex flex-col items-center space-y-3">
                    <span><DeleteIcon /></span>
                    <span className="text-2xl font-bold text-blue-dark">
                        Are you sure?
                    </span>
                    <span className="text-base font-medium text-red-light">
                        This action can't be undone.
                    </span>
                </span>
                <span className="flex justify-center space-x-3">
                    <button onClick={handleCancelClick} className="outlined-btn text-blue-dark ">
                        Cancel
                    </button>
                    <button onClick={handleDeleteClick} className="font-semibold btn-red">
                        Delete
                    </button>
                </span>
            </div>
        </div>
    )
}

const DeleteIcon = () => <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.25 10.5H8.75H36.75" stroke="#F77D82" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14 10.5V7C14 6.07174 14.3687 5.1815 15.0251 4.52513C15.6815 3.86875 16.5717 3.5 17.5 3.5H24.5C25.4283 3.5 26.3185 3.86875 26.9749 4.52513C27.6313 5.1815 28 6.07174 28 7V10.5M33.25 10.5V35C33.25 35.9283 32.8813 36.8185 32.2249 37.4749C31.5685 38.1313 30.6783 38.5 29.75 38.5H12.25C11.3217 38.5 10.4315 38.1313 9.77513 37.4749C9.11875 36.8185 8.75 35.9283 8.75 35V10.5H33.25Z" stroke="#F77D82" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> <path d="M17.5 19.25V29.75" stroke="#F77D82" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> <path d="M24.5 19.25V29.75" stroke="#F77D82" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> </svg>

export default ConfirmationCard
