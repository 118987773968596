import React, { lazy, Suspense } from 'react';

import { Link } from 'react-router-dom';

import { motion } from "framer-motion"

import PersonSVG from './PersonSVG';

const LandingPage = ({ location }) => {

    // const PersonSVG = lazy(()=>import('./PersonSVG'));

    return (
        <motion.div exit={{ opacity: 0 }} className="min-h-screen">
            <div className="w-full space-x-20 center pt-28">
                <div className="flex flex-col w-1/3 mt-10 space-y-10">
                    <h1 className="pt-10 text-5xl font-semibold text-blue-dark">Surveys Made <span className="text-red">Easier</span> Than Ever</h1>
                    <span>
                        <Link to='/login'>
                            <button className="w-48 font-semibold btn-red">Get Started</button>
                        </Link>
                    </span>
                </div>
                <span className="mt-20 w-72">
                    {/* <Suspense fallback={<>Loading Image...</>}> */}
                        <PersonSVG />
                    {/* </Suspense> */}
                </span>
            </div>
        </motion.div>
    )
}

export default LandingPage
