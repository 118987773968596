import React, { useEffect } from "react";
import TopNav from "../TopNav";
// import BottomNav from './BottomNav';

const PublishNewSurvey = ({
  setUpdateSurvey,
  pageOpen,
  setPageOpen,
  questions,
  surveyTitle,
  setOpenNewSurveyCard,
  setSelectedPage,
}) => {
  useEffect(() => {
    setUpdateSurvey(false);
  }, []);

  return (
    <div className="w-full">
      <TopNav pageOpen={pageOpen} displayCancelButton={false} />
      <div className="mt-10 mb-10 space-y-5">
        <div className="flex flex-col items-center justify-center space-y-5">
          <span className="mt-5 mb-5 text-4xl font-semibold text-red">
            Survey Published!
          </span>
          <span className="">
            <SurveySvg />
          </span>
          <span className="text-2xl font-semibold text-blue-dark ">
            {surveyTitle}
          </span>
          <span className="text-sm font-semibold text-red-light">
            {questions.length} Questions Total
          </span>
        </div>
      </div>
      <BottomNavPublish
        setSelectedPage={setSelectedPage}
        // pageOpen={pageOpen}
        setPageOpen={setPageOpen}
        setOpenNewSurveyCard={setOpenNewSurveyCard}
      />
    </div>
  );
};

const SurveySvg = () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <g clipPath="url(#clip0)">
      {" "}
      <path
        d="M118.53 137.173H31.4699C28.9677 137.173 26.9395 135.145 26.9395 132.643V17.3576C26.9395 14.8554 28.968 12.8271 31.4699 12.8271H118.53C121.033 12.8271 123.061 14.8557 123.061 17.3576V132.643C123.061 135.145 121.033 137.173 118.53 137.173Z"
        fill="#D4D4D4"
      />{" "}
      <path
        d="M109.462 124.346H22.4016C19.8993 124.346 17.8711 122.318 17.8711 119.816V4.53047C17.8711 2.02852 19.8996 0 22.4016 0H109.462C111.964 0 113.992 2.02852 113.992 4.53047V119.815C113.993 122.318 111.964 124.346 109.462 124.346Z"
        fill="#F5F5F5"
      />{" "}
      <path
        d="M104.925 0V124.346H109.459C111.963 124.346 113.993 122.319 113.993 119.819V4.52725C113.993 2.02676 111.963 0 109.459 0L104.925 0Z"
        fill="#E6E6E6"
      />{" "}
      <path
        d="M50.1006 52.0623H34.4065C33.7397 52.0623 33.1992 51.5218 33.1992 50.855V35.1609C33.1992 34.4941 33.7397 33.9536 34.4065 33.9536H50.1006C50.7674 33.9536 51.3079 34.4941 51.3079 35.1609V50.855C51.3079 51.5218 50.7674 52.0623 50.1006 52.0623Z"
        fill="#F77D82"
      />{" "}
      <path
        d="M50.1006 81.3382H34.4065C33.7397 81.3382 33.1992 80.7977 33.1992 80.1309V64.4368C33.1992 63.77 33.7397 63.2295 34.4065 63.2295H50.1006C50.7674 63.2295 51.3079 63.77 51.3079 64.4368V80.1309C51.3079 80.7977 50.7674 81.3382 50.1006 81.3382Z"
        fill="#8CF2FF"
      />{" "}
      <path
        d="M50.1006 110.614H34.4065C33.7397 110.614 33.1992 110.073 33.1992 109.406V93.7122C33.1992 93.0454 33.7397 92.5049 34.4065 92.5049H50.1006C50.7674 92.5049 51.3079 93.0454 51.3079 93.7122V109.406C51.3079 110.073 50.7674 110.614 50.1006 110.614Z"
        fill="#FFCA28"
      />{" "}
      <path
        d="M62.4751 39.7727H98.6925C99.9062 39.7727 100.89 38.7892 100.89 37.5754C100.89 36.3617 99.9062 35.3782 98.6925 35.3782H62.4751C61.2616 35.3782 60.2778 36.3617 60.2778 37.5754C60.2778 38.7892 61.2613 39.7727 62.4751 39.7727Z"
        fill="#7488ED"
      />{" "}
      <path
        d="M98.6922 45.0361H62.4751C61.2616 45.0361 60.2778 46.0196 60.2778 47.2334C60.2778 48.4472 61.2616 49.4307 62.4751 49.4307H98.6925C99.9062 49.4307 100.89 48.4472 100.89 47.2334C100.89 46.0196 99.9059 45.0361 98.6922 45.0361Z"
        fill="#7488ED"
      />{" "}
      <path
        d="M98.6922 64.6541H62.4751C61.2616 64.6541 60.2778 65.6375 60.2778 66.8513C60.2778 68.0651 61.2616 69.0486 62.4751 69.0486H98.6925C99.9062 69.0486 100.89 68.0651 100.89 66.8513C100.89 65.6375 99.9059 64.6541 98.6922 64.6541Z"
        fill="#7488ED"
      />{" "}
      <path
        d="M98.6922 74.3118H62.4751C61.2616 74.3118 60.2778 75.2953 60.2778 76.509C60.2778 77.7228 61.2616 78.7063 62.4751 78.7063H98.6925C99.9062 78.7063 100.89 77.7228 100.89 76.509C100.89 75.2953 99.9059 74.3118 98.6922 74.3118Z"
        fill="#7488ED"
      />{" "}
      <path
        d="M98.6922 93.929H62.4751C61.2616 93.929 60.2778 94.9125 60.2778 96.1262C60.2778 97.34 61.2616 98.3235 62.4751 98.3235H98.6925C99.9062 98.3235 100.89 97.34 100.89 96.1262C100.89 94.9125 99.9059 93.929 98.6922 93.929Z"
        fill="#7488ED"
      />{" "}
      <path
        d="M98.6922 103.587H62.4751C61.2616 103.587 60.2778 104.57 60.2778 105.784C60.2778 106.998 61.2616 107.981 62.4751 107.981H98.6925C99.9062 107.981 100.89 106.998 100.89 105.784C100.89 104.57 99.9059 103.587 98.6922 103.587Z"
        fill="#7488ED"
      />{" "}
      <path
        d="M87.2235 21.2776H45.5736C42.6565 21.2776 40.292 18.9131 40.292 15.996C40.292 13.0789 42.6565 10.7144 45.5736 10.7144H87.2235C90.1406 10.7144 92.5052 13.0789 92.5052 15.996C92.5052 18.9128 90.1406 21.2776 87.2235 21.2776Z"
        fill="#8C9EFF"
      />{" "}
      <path
        d="M58.4114 32.0746C57.6236 31.1518 56.2367 31.0422 55.3136 31.83L44.547 41.0207L42.3588 38.6503C41.5358 37.7588 40.1457 37.7031 39.2539 38.5261C38.3621 39.349 38.3064 40.7392 39.1297 41.631L42.7514 45.5547C43.1838 46.0232 43.7738 46.2616 44.3668 46.2616C44.8722 46.2616 45.3796 46.0882 45.7927 45.7355L58.1668 35.1722C59.0899 34.3844 59.1992 32.9978 58.4114 32.0746Z"
        fill="#8C9EFF"
      />{" "}
      <circle cx="113.5" cy="19.5" r="19.5" fill="#F77D82" />{" "}
      <path
        d="M122 14L111 25L106 20"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
    </g>{" "}
    <defs>
      {" "}
      <clipPath id="clip0">
        {" "}
        <rect width="150" height="150" fill="white" />{" "}
      </clipPath>{" "}
    </defs>{" "}
  </svg>
);

const BottomNavPublish = ({
  setPageOpen,
  setSelectedPage,
  setOpenNewSurveyCard,
}) => {
  const handleProceed = () => {
    setOpenNewSurveyCard(false);
    setSelectedPage("home");
    setPageOpen("create");
  };

  const handleBack = () => {
    setPageOpen("create");
  };

  return (
    <div className="flex items-center justify-between mx-10">
      {/* <button onClick={handleBack} className="w-40 py-2 pr-1 space-x-2 font-semibold transition-all rounded-xl center hover:opacity-90 flex-nowrap bg-grey-light">
                <span className="">Edit Survey</span>
            </button> */}
      <span></span>
      <button
        onClick={handleProceed}
        className="w-40 py-2 pl-1 space-x-1 font-semibold text-white transition-all hover:opacity-90 rounded-xl center bg-red"
      >
        <span>View Surveys</span>
      </button>
    </div>
  );
};

export default PublishNewSurvey;
