import React, { useState, useRef, useEffect } from "react";

import ReactTooltip from "react-tooltip";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import NewQuestion from "./NewQuestion";
import AddNewBtn from "./AddNewBtn";
import TopNav from "../TopNav";
import BottomNav from "../BottomNav";

import Multiselect from "../../Users/MultiSelect/MultiSelect";

const CreateASurvey = (props) => {
  const {
    resetCompleteSurvey,
    openToast,
    questions,
    setQuestions,
    surveyTitle,
    setSurveyTitle,
    pageOpen,
    setPageOpen,
    surveyId,
    setSelectedPage,
    setOpenNewSurveyCard,
    surveyGroups,
    completeSurvey,
    setCompleteSurvey,
    selectedSurveyGroups,
    setSelectedSurveyGroups,
  } = props;

  // const [editingSurveyTitle, setEditingSurveyTitle] = useState(true);
  const [areLabelsUnique, setAreLabelsUnique] = useState(true);

  useEffect(() => {
    // Check if the labels/titles for questions are unique
    const filteredArr = questions.reduce((acc, current) => {
      const x = acc.find(
        (item) => item?.title?.trim() === current?.title?.trim()
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    // Check if the labels/titles for other label are unique
    let seen = new Set();
    var hasDuplicates = questions.some(function (currentObject) {
      return (
        currentObject.enableOtherResponse &&
        seen.size === seen.add(currentObject?.otherResponseLabel?.trim())?.size
      );
    });

    if (questions.length > filteredArr.length || hasDuplicates) {
      setAreLabelsUnique(false);
      openToast(
        console.log,
        "error",
        "The labels must be unique, please check your inputs",
        {
          timeout: 5000,
        }
      );
    } else setAreLabelsUnique(true);

    if (questions?.length) {
      questions?.map((question) =>
        question.title === "" ? setAreLabelsUnique(false) : null
      );
    }
  }, [questions]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestions(items);
  };

  const checkDragDisabled = (question) => {
    const isSource = questions.filter(
      (item) => item?.dependsSource === question.title
    )?.length;
    // console.log('isSource',questions.filter(item=>item?.dependsSource===question.title))
    if (isSource || question.depends) return true;
    return false;
  };

  // useEffect(() => {
  //   console.log(questions)
  // }, [questions])

  return (
    <div key={questions.length} className="w-full">
      <TopNav
        resetCompleteSurvey={resetCompleteSurvey}
        pageOpen={pageOpen}
        setPageOpen={setPageOpen}
        setOpenNewSurveyCard={setOpenNewSurveyCard}
        displayCancelButton={true}
      />
      <div className="w-full mt-10 space-y-5">
        <SurveyTitleInput
          setSurveyTitle={setSurveyTitle}
          surveyTitle={surveyTitle}
          surveyId={surveyId}
        />
        <SurveyGroupsInput
          surveyGroups={surveyGroups}
          selectedSurveyGroups={selectedSurveyGroups}
          setSelectedSurveyGroups={setSelectedSurveyGroups}
        />
        <div className="mx-10 space-y-5">
          <SubTitle numOfQuestions={questions.length} />
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="questions">
              {(provided) => (
                <ul
                  className="z-0 p-0 space-y-5 list-none"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {questions.map((question, index) => {
                    return (
                      <Draggable
                        isDragDisabled={checkDragDisabled(question)}
                        key={question.id}
                        draggableId={question.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            className={`transition-colors list-none rounded-xl ${
                              snapshot.isDragging && "bg-grey-dark opacity-70"
                            }`}
                            id={question.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // className={`relative z-0 transition-colors cursor-move list-none rounded-xl ${isDragged && 'bg-grey-dark opacity-50'}`}
                          >
                            {/* {console.log('isDragged:',isDragged)} */}
                            <NewQuestion
                              // className={`${isDragged ? 'bg-red-light' : null}`}
                              key={`
                              ${questions.length}${index}
                              ${JSON.stringify(questions.slice(0, index))}
                              `}
                              openToast={openToast}
                              questions={questions}
                              setQuestions={setQuestions}
                              index={index}
                              question={question}
                            />
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          {/* COMMENTED 2 */}
          <AddNewBtn setQuestions={setQuestions} />
        </div>
      </div>
      <BottomNav
        areLabelsUnique={areLabelsUnique && surveyTitle?.length && questions?.length}
        pageOpen={pageOpen}
        setPageOpen={setPageOpen}
        setOpenNewSurveyCard={setOpenNewSurveyCard}
      />
    </div>
  );
};

const SurveyTitleInput = (props) => {
  const { setSurveyTitle, surveyTitle, surveyId } = props;

  return (
    <div className="mx-10">
      {
        <input
          key={surveyId}
          onFocus={(e) => {
            if (e.target.value === "Your new Survey") e.target.value = "";
          }}
          onChange={(e) => {
            setSurveyTitle(e.target.value);
          }}
          value={surveyTitle}
          className="w-full px-10 py-3 font-semibold border text-grey-lightest rounded-xl border-grey-lightest"
          type="text"
          placeholder="Survey Title"
        />
      }
    </div>
  );
};

const SurveyGroupsInput = ({
  surveyGroups,
  selectedSurveyGroups,
  setSelectedSurveyGroups,
}) => {
  useEffect(() => {
    console.log("selectedSurveyGroups", selectedSurveyGroups);
    // console.log('surveyGroups.surveygroups',surveyGroups.surveygroups)
  }, [selectedSurveyGroups]);

  return (
    <>
      <span className="flex w-1/3 mx-10">
        <span
          data-place="right"
          data-effect="solid"
          data-background-color="#8B0021"
          data-tip={"Choose which survey groups should this survey be a part of"}
        >
          <Multiselect
            placeholder="select survey groups"
            items={surveyGroups}
            displayValue="surveyGroupName"
            setSelectedItems={setSelectedSurveyGroups}
            selectedItems={selectedSurveyGroups}
          />
        </span>
        <ReactTooltip />
      </span>
    </>
  );
};

const SubTitle = ({ numOfQuestions }) => (
  <div className="flex items-center space-x-5">
    <div className="text-xl font-semibold">Add Questions</div>
    <div className="mt-1 text-sm font-medium text-red-light">
      {numOfQuestions} Questions Added
    </div>
  </div>
);

export default CreateASurvey;
