import React, {useState, useEffect} from 'react'

const PermissionTypesRadio = (props) => {

    const handleToggle = () => {
        props.setToggle(prev=>!prev);
    }
    
    return (
        <div>
            <span className="">
                <span className="flex items-center justify-start space-x-4 font-semibold">
                    <input onChange={handleToggle} checked={props.value} type="radio" name={props.title1} id={props.title1}/><label htmlFor={props.title1}>{props.title1}</label>
                    <input onChange={handleToggle} checked={!props.value} type="radio" name={props.title2} id={props.title2}/><label htmlFor={props.title2}>{props.title2}</label>
                </span>
            </span>
        </div>
    )
}

export default PermissionTypesRadio
