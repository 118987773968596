import React, { useState, useEffect } from "react";
import ResourcePermissions from "./ResourcePermissions";

const ResourceSpecificSection = (props) => {
  const defaultCrudUG = [
    "index",
    "view",
    "create",
    "update",
    "delete",
    "indexusers",
    "createusers",
    "viewusers",
    "updateusers",
    "deleteusers",
  ];
  const defaultCrudSurveys = ["index", "view", "create", "update", "delete"];
  const defaultCrudSG = [
    "index",
    "view",
    "create",
    "update",
    "delete",
    "indexsurveys",
    "createsurveys",
    "viewsurveys",
    "updatesurveys",
    "deletesurveys",
  ];
  const defaultCrudRoles = ["index", "view", "create", "update", "delete"];

  const [selectAllUG, setSelectAllUG] = useState(true); //props.updateRole ? false : true
  const [selectAllSurveys, setSelectAllSurveys] = useState(true); //props.updateRole ? false : true
  const [selectAllSG, setSelectAllSG] = useState(true); //props.updateRole ? false : true
  const [selectAllRoles, setSelectAllRoles] = useState(true); //props.updateRole ? false : true

  // useEffect(() => {
  //     if(permissionsArrUG.length){
  //         const permissions = permissionsArrUG.map((permObj)=>{
  //             const toReturn = permObj?.allowed_actions?.map(allowed_action=>({
  //                 resource_id: permObj.id,
  //                 resource_type: permObj.resource_type,
  //                 allowed_action: allowed_action
  //             }))
  //             return toReturn;
  //         })
  //         console.log('permissions',permissions);
  //     }
  // }, [permissionsArrUG])

  // useEffect(() => {
  //     console.log('props.permissionsArrSurveys', props.permissionsArrSurveys)
  // }, [props.permissionsArrSurveys])

  return (
    <div className="flex flex-col items-start justify-center mt-3 space-y-8">
      {props.userGroups?.length && !props.removePermUG ? (
        <ResourcePermissions
          setRemovePermissions={props.setRemovePermUG}
          defaultCrud={defaultCrudUG}
          selectAll={selectAllUG}
          setSelectAll={setSelectAllUG}
          name="User Groups"
          inputArr={props.userGroups}
          displayValue="userGroupName"
          resource_type="usergroup"
          permissionsArr={props.permissionsArrUG}
          setPermissionsArr={props.setPermissionsArrUG}
        />
      ) : null}
      {props.availableSurveys?.length && !props.removePermSurveys ? (
        <ResourcePermissions
          setRemovePermissions={props.setRemovePermSurveys}
          defaultCrud={defaultCrudSurveys}
          selectAll={selectAllSurveys}
          setSelectAll={setSelectAllSurveys}
          name="Surveys"
          inputArr={props.availableSurveys}
          displayValue="name"
          resource_type="survey"
          permissionsArr={props.permissionsArrSurveys}
          setPermissionsArr={props.setPermissionsArrSurveys}
          // updateRole={props.updateRole}
        />
      ) : null}
      {props.surveyGroups?.length && !props.removePermSG ? (
        <ResourcePermissions
          setRemovePermissions={props.setRemovePermSG}
          defaultCrud={defaultCrudSG}
          selectAll={selectAllSG}
          setSelectAll={setSelectAllSG}
          name="Survey Groups"
          inputArr={props.surveyGroups}
          displayValue="surveyGroupName"
          resource_type="surveygroup"
          permissionsArr={props.permissionsArrSG}
          setPermissionsArr={props.setPermissionsArrSG}
        />
      ) : null}
      {props.availableRoles?.length && !props.removePermRoles ? (
        <ResourcePermissions
          setRemovePermissions={props.setRemovePermRoles}
          defaultCrud={defaultCrudRoles}
          selectAll={selectAllRoles}
          setSelectAll={setSelectAllRoles}
          name="Roles"
          inputArr={props.availableRoles}
          displayValue="name"
          resource_type="role"
          permissionsArr={props.permissionsArrRoles}
          setPermissionsArr={props.setPermissionsArrRoles}
        />
      ) : null}
    </div>
  );
};

export default ResourceSpecificSection;
