import React, { useState, useEffect } from "react";
import PermissionTypesRadio from "./PermissionTypesRadio";

import MultiSelectForCrud from "./MultiSelectForCrud/MultiSelectForCrud";

import { DeleteIcon } from "../../Icons";

const ResourcePermissions = (props) => {
  const defaultCrud = props.defaultCrud;

  const [selectedInput, setSelectedInput] = useState(props.inputArr[0]);

  const [selectedCrudForAll, setSelectedCrudForAll] = useState([
    ...defaultCrud,
  ]);
  const [selectedCrud, setSelectedCrud] = useState([...defaultCrud]); //props.permissionsArr? [...props.permissionsArr[0].allowed_actions] : [...defaultCrud]

  // useEffect(() => {
  //     console.log('selectedCruddd',[...props.permissionsArr[0].allowed_actions])
  // }, [selectedCrud])

  useEffect(() => {
    // if(props.selectAll) return;

    const tempArr = [];
    props.inputArr.map((item) =>
      tempArr.push({
        id: item.id,
        resource_type: props.resource_type,
        // name: '',
        allowed_actions: props.selectAll
          ? [...selectedCrudForAll]
          : [...defaultCrud],
      })
    );
    props.setPermissionsArr(tempArr);
    console.log("tempArr", tempArr);
  }, [props.selectAll]);

  useEffect(() => {
    if (props.selectAll) return;

    const allowed_actions = props.permissionsArr.filter(
      (item) => item.id === selectedInput.id
    )[0]?.allowed_actions;
    if (allowed_actions) setSelectedCrud(allowed_actions);
    console.log(
      "allowed_actions",
      props.permissionsArr.filter((item) => item.id === selectedInput.id)
    );
    console.log("permissionsArr", props.permissionsArr);
  }, [selectedInput]);

  useEffect(() => {
    if (!props.permissionsArr.length && props.selectAll) return;

    console.log("selectedCrud", selectedCrud);
    const tempArr = props.permissionsArr.map((item) => {
      if (item.id === selectedInput.id)
        return { ...item, allowed_actions: [...selectedCrud] };
      else return item;
    });
    // console.log(tempArr)
    props.setPermissionsArr(tempArr);
  }, [selectedCrud]);

  // FOR SELECT ALL
  useEffect(() => {
    if (!props.permissionsArr.length) return;

    console.log("selectedCrud", selectedCrud);
    const tempArr = props.permissionsArr.map((item) => {
      return { ...item, allowed_actions: [...selectedCrudForAll] };
    });
    // console.log(tempArr)
    props.setPermissionsArr(tempArr);
  }, [selectedCrudForAll]);

  const handleRemoveSpecPerm = () => {
    setSelectedCrud((prev) => []);
  };

  return (
    <div className="flex flex-col mt-2 space-y-3">
      <span className="flex items-center space-x-4">
        <span className="text-xl font-semibold"> {props.name} </span>
        <span
          onClick={() => props.setRemovePermissions(true)}
          className="pointer"
        >
          <DeleteIcon />
        </span>
      </span>
      <span>
        <PermissionTypesRadio
          title1={`All ${props.name}`}
          title2={`Custom ${props.name}`}
          value={props.selectAll}
          setToggle={props.setSelectAll}
        />
      </span>
      <span>
        {props.selectAll ? (
          <MultiSelectForCrud
            items={defaultCrud}
            selectedItems={selectedCrudForAll}
            setSelectedItems={setSelectedCrudForAll}
            placeholder="select operations"
          />
        ) : (
          <span key={selectedCrud.length} className="space-y-2">
            <span className="flex items-center space-x-5">
              <SelectField
                // key={selectedInput[props.displayValue]}
                inputArr={props.inputArr}
                selectedInput={selectedInput}
                setSelectedInput={setSelectedInput}
                displayValue={props.displayValue}
              />
              <span onClick={handleRemoveSpecPerm} className="pointer">
                <DeleteIcon />
              </span>
            </span>
            <MultiSelectForCrud
              items={defaultCrud}
              selectedItems={selectedCrud}
              setSelectedItems={setSelectedCrud}
              placeholder="select operations"
            />
          </span>
        )}
      </span>
    </div>
  );
};

const SelectField = ({
  inputArr,
  selectedInput,
  setSelectedInput,
  displayValue,
}) => {
  const handleOnChange = (e) => {
    console.log(e.target.value);
    const filteredRoles = inputArr.filter(
      (item) => item[displayValue] === e.target.value
    );
    if (!filteredRoles) return;
    const id = filteredRoles[0]?.id;
    setSelectedInput({
      [displayValue]: e.target.value,
      id: id,
    });
    setTimeout(() => {
      console.log(selectedInput);
    }, 1000);
  };

  return (
    <>
      <select
        id={selectedInput.id}
        onChange={handleOnChange}
        value={selectedInput[displayValue]}
        className="content-center pl-10 font-semibold text-center rounded-lg text-grey-lightest border-grey-lightest w-60"
      >
        {inputArr.map((item, index) => (
          <option
            key={item.id}
            id={item.id}
            tabIndex={index}
            className="font-semibold text-grey"
          >
            {item[displayValue]}
          </option>
        ))}
      </select>
    </>
  );
};

export default ResourcePermissions;
