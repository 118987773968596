import React, { useEffect, useState } from "react";

import axios from "axios";

const AddNewSurveyGroupCard = ({
  setOpenAddNewSurveyGroupCard,
  availableSurveys,
  openToast,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
  endpoints,
  LoadingAnimation,
  errToast,
  setSurveyGroups,
  surveyGroups,
  updateSurveyGroup,
  selectedSurveyGroup,
  setUpdateSurveyGroup,
}) => {
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  useEffect(() => {
    if (updateSurveyGroup) {
      const toAdd = selectedSurveyGroup?.surveys?.map((item) => ({
        id: item?.id,
        name: item?.name,
      }));
      if (toAdd?.length) setSelectedSurveys([...toAdd]);
    }
  }, []);
  const [newSurveyGroupName, setNewSurveyGroupName] = useState(
    selectedSurveyGroup.name
  );

  const errToastWithMsg = (msg) => openToast(console.log, "error", msg);

  const sendNewSurveyGroupToApi = (surveyGroupObjToSend) => {
    axios
      .post(endpoints.createSurveyGroups, surveyGroupObjToSend, axiosOptions)
      .then((res) => {
        // if (res.body==='survey created') console.log(res);
        if (res.status === 201) {
          setOpenAddNewSurveyGroupCard(false);
          const prev = [...surveyGroups];
          // surveyGroupObjToSend.surveyGroupName= surveyGroupObjToSend.username;
          prev.push(surveyGroupObjToSend);
          setSurveyGroups(prev);
          openToast(
            () => console.log("survey group created"),
            "success",
            "Successfully Created Survey Group."
          );
        } else {
          console.log(res);
          errToast();
        }
      })
      .catch((err) => {
        console.log(err);
        errToastWithMsg(`error: ${err.response?.data}`);
      });
  };

  const sendEditSurveyGroupToApi = (surveyGroupId, surveyGroupObjToSend) => {
    axios
      .post(
        `${endpoints.updateSurveyGroups}/${surveyGroupId}`,
        surveyGroupObjToSend,
        axiosOptions
      )
      .then((res) => {
        // if (res.body==='survey created') console.log(res);
        if (res.status === 201) {
          setOpenAddNewSurveyGroupCard(false);
          const prev = [...surveyGroups];
          const filteredArr = prev.filter((item) => item.id !== surveyGroupId);
          // filteredArr.push(surveyGroupObjToSend);
          // prev.push(surveyObjToSend)
          setSurveyGroups(filteredArr);
          setUpdateSurveyGroup(false);
          openToast(
            () => console.log("survey group updated"),
            "success",
            "Successfully Updated Survey Group."
          );
        } else {
          console.log(res);
          // setUpdateUser(false);
          errToast();
        }
      })
      .catch((err) => {
        console.log(err);
        // setUpdateUser(false);
        errToastWithMsg(`error: ${err.response?.data}`);
      });
  };

  const handleCreateSurveyGroupClick = () => {
    // console.log('selectedSurveys', selectedSurveys);
    let surveys = [];
    if (selectedSurveys.length) {
      selectedSurveys.map((survey) =>
        surveys.push({ id: survey.id, name: survey.name })
      );
    }
    const surveyGroupObjToSend = {
      name: newSurveyGroupName,
      description: "",
      surveys: [...surveys],
      visible: true,
    };
    console.log("surveyGroupObjToSend", surveyGroupObjToSend);
    if (!updateSurveyGroup) sendNewSurveyGroupToApi(surveyGroupObjToSend);
    if (updateSurveyGroup)
      sendEditSurveyGroupToApi(selectedSurveyGroup.id, surveyGroupObjToSend);
  };

  const handleCancelClick = () => {
    setUpdateSurveyGroup(false);
    setOpenAddNewSurveyGroupCard(false);
    // setViewSurveyGroup(false);
  };

  useEffect(() => {
    console.log("selectedSurveys", selectedSurveys);
  }, [selectedSurveys]);

  return (
    <div
      style={{
        boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.19)",
        minWidth: "50%",
      }}
      className="absolute w-9/12 pb-24 space-y-5 bg-white left-56 top-20 rounded-xl"
    >
      <div className="flex flex-col p-8 pl-10">
        <span className="flex items-center justify-between">
          <span className="text-2xl font-semibold text-blue-dark">
            {updateSurveyGroup ? "Update" : "Create"} Survey Group
          </span>
          <span className="flex space-x-4">
            <button
              onClick={() => handleCancelClick(false)}
              className="btn-grey"
            >
              Cancel
            </button>
            <button
              disabled={!newSurveyGroupName.length}
              onClick={handleCreateSurveyGroupClick}
              className={`${
                !newSurveyGroupName.length ? "btn-red-disabled" : "btn-red"
              }`}
            >
              Save Group
            </button>
          </span>
        </span>
      </div>

      <div className="flex flex-col justify-center mx-10 space-y-3">
        <EditField
          type="text"
          input={newSurveyGroupName}
          setInput={setNewSurveyGroupName}
          placeholder="Survey Group Name"
        />
        <span className="flex items-center pt-5 space-x-5">
          <span className="blue-heading">Add Surveys</span>
          <span className="mt-2 small-pink-text">
            {selectedSurveys.length} Selected
          </span>
        </span>
        <span className="pt-1">
          <CreateSurveyGroupTable
            selectedSurveys={selectedSurveys}
            setSelectedSurveys={setSelectedSurveys}
            availableSurveys={availableSurveys}
          />
        </span>
      </div>
    </div>
  );
};

const CreateSurveyGroupTable = ({
  availableSurveys,
  selectedSurveys,
  setSelectedSurveys,
}) => {
  return (
    <div className="space-y-2">
      <span className="flex items-center justify-start mx-24 space-x-20 font-semibold text-grey-lightest flex-nowrap ">
        <span className="w-48">Name</span>
        {/* <span>Name</span> */}
        <span className="w-44">No. of Questions</span>
        {/* <span className="">Status</span> */}
      </span>
      <span
        className={
          availableSurveys?.length > 4
            ? "flex flex-col pt-4 overflow-y-scroll h-60 space-y-5"
            : "flex flex-col pt-4 space-y-5"
        }
      >
        {availableSurveys.map((surveyObj, index) => (
          <RowItem
            selectedSurveys={selectedSurveys}
            setSelectedSurveys={setSelectedSurveys}
            surveyObj={surveyObj}
            index={index}
            key={index}
          />
        ))}
      </span>
    </div>
  );
};

const RowItem = ({ surveyObj, index, selectedSurveys, setSelectedSurveys }) => {
  const checked =
    selectedSurveys.filter((item) => item.id === surveyObj.id).length > 0;

  const handleCheck = () => {
    if (checked)
      setSelectedSurveys((prev) =>
        prev.filter((item) => item.id !== surveyObj.id)
      );
    else setSelectedSurveys((prev) => [...prev, surveyObj]);
  };

  console.log(surveyObj);

  return (
    <span key={surveyObj.id} className="flex">
      <Checkbox
        handleCheck={handleCheck}
        checked={checked}
        selectedSurveys={selectedSurveys}
        setSelectedSurveys={setSelectedSurveys}
      >
        <span className="w-48">{surveyObj.name}</span>
        {/* <span className="w-10">{surveyObj.name}</span> */}
        <span className="w-44">{surveyObj?.surveyQuestions?.length}</span>
        {/* <span className="w-20">
                    { surveyObj.status==='active' ? <Active />: <Inactive /> }
                </span> */}
      </Checkbox>
      {/* <span className="ml-16">
                    {
                        surveyObj.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
            </span> */}
    </span>
  );
};

const Active = () => (
  <div className="space-x-2 center">
    <span>
      <ActiveIcon />
    </span>
    <span>Active</span>
  </div>
);
const Inactive = () => (
  <span className="space-x-2 center">
    <span>
      <InactiveIcon />
    </span>
    <span>Inactive</span>
  </span>
);

const Checkbox = ({
  checked,
  children,
  selectedSurveys,
  setSelectedSurveys,
  handleCheck,
}) => {
  return (
    <div
      onClick={handleCheck}
      className="flex items-start font-semibold text-blue pointer"
    >
      <span className="mt-1 ml-9">
        {checked ? <CheckedIcon /> : <UncheckedIcon />}
      </span>
      <span className="flex ml-10 space-x-40">{children}</span>
    </div>
  );
};
const CheckedIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <rect width="18" height="18" rx="6" fill="#8B0021" />{" "}
    <path
      d="M13.5 6L7.82813 12.3838L5.25 9.48207"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
  </svg>
);
const UncheckedIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="5.5"
      stroke="#CCCCCC"
    />{" "}
  </svg>
);
const EyeInvisibleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      fill="#878787"
    />{" "}
  </svg>
);
const EyeVisibleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
      stroke="#8B0021"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      fill="#8B0021"
    />{" "}
  </svg>
);
const ActiveIcon = () => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#8CD76A" />{" "}
  </svg>
);
const InactiveIcon = () => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#CCCCCC" />{" "}
  </svg>
);

const EditField = ({ type, input, placeholder, setInput }) => {
  // const [editing, setEditing] = useState(false);

  return (
    <>
      {
        // editing ?
        <input
          onChange={(e) => setInput(e.target.value)}
          // onKeyDownCapture={(e)=>{if(e.key==='Enter') setEditing(false)}}
          autoFocus
          value={input}
          className="w-full px-10 py-2 font-semibold border grey-placeholder text-grey-lightest border-grey-lightest rounded-xl focus:outline-none"
          placeholder={placeholder}
          type={type}
        />
        // :
        // <div onClick={()=>setEditing(true)} className="w-full px-10 py-2 font-semibold border text-grey-lightest pointer border-grey-lightest rounded-xl focus:outline-none">
        //     {input ? `${input}` : `${placeholder}`}
        // </div>
      }
    </>
  );
};

export default AddNewSurveyGroupCard;
