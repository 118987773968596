import React from 'react';
import EmptyTableSVG from '../assets/empty_data.svg';

const EmptyResource = ({ text }) => {
    return (
        <span className="flex flex-col items-center justify-center w-full space-y-8">
            <span className="w-44"><img src={EmptyTableSVG} alt="" /></span>
            <span className="text-xl font-semibold w-68 text-red">{text}</span>
        </span>
    )
}

export default EmptyResource
