import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import ReactPaginate from "react-paginate";

import { SaveIcon, SortIcon } from "../../Icons";
import EmptyResource from "../../EmptyResource";
import useAsync from "../../../Hooks/useAsync";
import { endpoints } from "../../../endpoints";
import { axiosOptions, validateEmail } from "../../../utils";
import Checkbox from "./Checkbox";
import Button from "./Button";
import BatchEdit from "./BatchEdit";
import { compareFunc } from "./emailUtils";

import { ControlsSpacer, EmailTitle, NameTitle } from "./StatelessComponents";

const Emails = (props) => {
  const [updateTable, setUpdateTable] = useState(true);

  const [completeAlertsData, setCompleteAlertsData] = useState(null);
  const [alertsData, setAlertsData] = useState(null);
  const [sort, setSort] = useState("");

  const [openBatchEdit, setOpenBatchEdit] = useState(false);

  // MULTI SELECT
  const [multiSelected, setMultiSelected] = useState([]);
  useEffect(() => {
    console.log(multiSelected);
  }, [multiSelected]);
  //

  // PAGINATION
  const paginationConfig = {
    offset: 0,
    numberPerPage: 5,
    pageCount: 0,
    currentData: [],
  };
  const [pagination, setPagination] = useState({
    data: alertsData,
    ...paginationConfig,
  });
  //

  useEffect(() => {
    console.log(sort);
    if (sort === "building") {
      setAlertsData((prev) =>
        [...prev].sort((a, b) => compareFunc(a, b, "building"))
      );
      console.log(alertsData.sort((a, b) => compareFunc(a, b, "building")));
    } else if (sort === "floor") {
      setAlertsData((prev) =>
        [...prev].sort((a, b) => compareFunc(a, b, "floor"))
      );
    } else if (sort === "area") {
      setAlertsData((prev) =>
        [...prev].sort((a, b) => compareFunc(a, b, "area"))
      );
    }
    // else if (sort === "email") {
    //   setAlertsData((prev) =>
    //     [...prev].sort((a, b) => compareFunc(a, b, "email"))
    //   );
    // }
  }, [sort]);

  const errToast = () =>
    props.openToast(
      console.log,
      "error",
      "You're not authorized for this operation."
    );
  const successToast = () =>
    props.openToast(console.log, "success", "Update Operation Successful.");

  const invalidEmailToast = () =>
    props.openToast(
      console.log,
      "error",
      "Please enter a valid email address."
    );

  const makeApiCall = async (endpoint, axiosOptions) => {
    return await axios.get(endpoint, axiosOptions);
  };
  const makePostApiCall = async (endpoint, body, axiosOptions) => {
    return await axios.post(endpoint, body, axiosOptions);
  };

  const { execute, status, value, error } = useAsync(
    () => makeApiCall(props.endpoints.listAlert, props.axiosOptions),
    false
  );

  useEffect(() => {
    if (updateTable) execute();
  }, [updateTable]);

  useEffect(() => {
    if (value?.data?.json_list) {
      setCompleteAlertsData((prev) => value.data.json_list);
      setAlertsData((prev) => value.data.json_list);
    }
    console.log(value?.data?.json_list);
  }, [value]);

  const handleSuccess = () => successToast();
  const handleError = (err) => {
    if (
      ["unsupported authentication type", "expired token"].includes(
        err.response?.data
      )
    )
      props.goToLoginAndRemoveTokensFromLS();
    console.log(err);
    errToast();
  };
  const handleInvalidEmail = () => invalidEmailToast();

  useEffect(() => {
    console.log(value, status, error);
    setUpdateTable(false);
    if (error) {
      handleError(error);
    }
  }, [value, error, status]);

  // SEARCH
  const [search, setSearch] = useState("");
  const handleSearch = (e) => setSearch(e.target.value);

  useEffect(() => {
    const filteredData = completeAlertsData?.filter((item) =>
      item.building.toLowerCase()?.includes(search.toLowerCase())
    );
    if (filteredData) setAlertsData((prev) => [...filteredData]);
  }, [search]);
  //   useEffect(() => {
  //     setPagination({
  //         ...paginationConfig,
  //       data: alertsData,
  //       currentData: alertsData?.slice(
  //         pagination.offset,
  //         pagination.offset + pagination.numberPerPage
  //       )
  //     })
  // }, [search])
  //

  return (
    <div className="flex flex-col w-full space-y-10 transition-all">
      <div className="flex items-center space-x-10">
        <span className="mt-8 ml-10 blue-heading">
          Add Email Alerts
          {/* to:{" "}
          <span className="ml-2 text-red">Covid Survey</span> */}
        </span>
        <span className="flex items-center space-x-2 mt-9">
          <input
            value={search}
            onChange={handleSearch}
            className="h-8 rounded-xl border-grey-lightest text-grey-lightest placeholder-grey-lightest"
            placeholder="search building"
            type="text"
            name="search"
            id="search"
          />
          <Button
            onClick={() => setOpenBatchEdit(true)}
            disabled={!multiSelected?.length}
          >
            Batch Edit
          </Button>
        </span>
      </div>
      <div
        style={{ boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.19)" }}
        className="w-11/12 p-5 pt-10 mx-10 h-[500px] rounded-xl"
      >
        <EmailsTable
          availableItems={alertsData}
          EmptyTableSVG={EmptyResource}
          loadingAvailableItems={["pending"].includes(status)}
          LoadingAnimation={props.LoadingAnimation}
          key={alertsData?.length}
          itemsArray={alertsData}
          handleError={handleError}
          handleSuccess={handleSuccess}
          openToast={props.openToast}
          setSort={setSort}
          pagination={pagination}
          setPagination={setPagination}
          paginationConfig={paginationConfig}
          search={search}
          multiSelected={multiSelected}
          setMultiSelected={setMultiSelected}
          handleInvalidEmail={handleInvalidEmail}
          makePostApiCall={makePostApiCall}
        />
      </div>
      {openBatchEdit && (
        <BatchEdit
          setUpdateTable={setUpdateTable}
          openBatchEdit={openBatchEdit}
          setOpenBatchEdit={setOpenBatchEdit}
          multiSelected={multiSelected}
          setMultiSelected={setMultiSelected}
          endpoints={props.endpoints}
          axiosOptions={props.axiosOptions}
          handleError={handleError}
          handleSuccess={handleSuccess}
          handleInvalidEmail={handleInvalidEmail}
          makePostApiCall={makePostApiCall}
        />
      )}
    </div>
  );
};

const EmailsTable = (props) => {
  const {
    availableItems,
    LoadingAnimation,
    loadingAvailableItems,
    itemsArray,
    handleError,
    handleSuccess,
    openToast,
    setSort,
    pagination,
    setPagination,
    paginationConfig,
    search,
    multiSelected,
    setMultiSelected,
    handleInvalidEmail,
    makePostApiCall,
  } = props;

  useEffect(() => {
    setPagination((prevState) => ({
      ...paginationConfig,
      data: availableItems,
      pageCount: availableItems?.length / prevState.numberPerPage,
      currentData: availableItems?.slice(
        pagination.offset,
        pagination.offset + pagination.numberPerPage
      ),
    }));
  }, [availableItems]);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageCount: prevState.data?.length / prevState.numberPerPage,
      currentData: prevState.data?.slice(
        pagination.offset,
        pagination.offset + pagination.numberPerPage
      ),
    }));
  }, [pagination.numberPerPage, pagination.offset]);
  const handlePageClick = (event) => {
    const selected = event.selected;
    const offset = selected * pagination?.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  return (
    <div
      className={
        itemsArray?.length > 5
          ? "space-y-5 overflow-x-scroll overflow-y-scroll lg:overflow-x-hidden h-96"
          : "space-y-5 overflow-x-scroll overflow-y-visible lg:overflow-x-hidden h-96"
      }
    >
      <TableTitles setSort={setSort} />
      {!loadingAvailableItems ? (
        <>
          {availableItems?.length > 0 ? (
            <>
              <>
                {pagination.currentData &&
                  pagination.currentData.map((itemObj, index) => (
                    <RowItem
                      itemObj={itemObj}
                      key={`${itemObj?.email}${index}${multiSelected.includes(
                        itemObj.id
                      )}`}
                      LoadingAnimation={LoadingAnimation}
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                      openToast={openToast}
                      index={index}
                      multiSelected={multiSelected}
                      setMultiSelected={setMultiSelected}
                      handleInvalidEmail={handleInvalidEmail}
                      makePostApiCall={makePostApiCall}
                    />
                  ))}
              </>
              <ReactPaginate
                key={`${JSON.stringify(pagination.data)}`}
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pagination.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={"pagination"}
                activeClassName={"pagination-active"}
                onPageChange={handlePageClick}
                previousLinkClassName={"pagination-prev"}
                nextClassName={"pagination-next"}
              />
            </>
          ) : (
            <EmptyResource text="Questions Not Found" />
          )}
        </>
      ) : (
        <span className="pt-20 center">
          <LoadingAnimation />{" "}
        </span>
      )}
    </div>
  );
};

const RowItem = ({
  itemObj,
  LoadingAnimation,
  handleError,
  handleSuccess,
  openToast,
  index,
  multiSelected,
  setMultiSelected,
  handleInvalidEmail,
  makePostApiCall,
}) => {
  const [email, setEmail] = useState(itemObj.email ?? "");
  const [name, setName] = useState(itemObj.name ?? "");
  const [checked, setChecked] = useState(multiSelected.includes(itemObj.id));

  useEffect(() => {
    setEmail(itemObj.email);
  }, [itemObj.email]);

  const handleChange = (e) => setEmail(e.target.value);
  const handleChangeName = (e) => setName(e.target.value);

  const endpoint = `${endpoints.updateAlert}/${itemObj.id}`;
  const body = { email: email, name: name };

  const { execute, status, value, error } = useAsync(
    () => makePostApiCall(endpoint, body, axiosOptions),
    false
  );

  const handleEditClick = () => {
    if (itemObj.id && email) {
      if (validateEmail(email)) {
        execute();
      } else handleInvalidEmail();
    }
  };

  useEffect(() => {
    if (error) handleError(error);
    if (status === "success") handleSuccess();
  }, [status, value, error]);

  const handleCheckClick = (e) => {
    const type = e.target?.type;
    const tagName = e.target?.tagName;
    if (
      !["email", "text"].includes(type) &&
      !["svg", "path"].includes(tagName)
    ) {
      if (!checked) setMultiSelected((prev) => [...prev, itemObj.id]);
      if (checked)
        setMultiSelected((prev) => [...prev].filter((id) => id !== itemObj.id));
      setChecked((prev) => !prev);
    }
  };

  return (
    <Checkbox key={checked} checked={checked} className="w-full">
      <span
        onClick={handleCheckClick}
        key={itemObj.id}
        className="flex items-center justify-start space-x-1 text-sm font-semibold text-blue-dark"
      >
        <span className="w-24 text-center break-all lg:w-3/12 ">
          {itemObj.building}
        </span>
        <span className="flex flex-wrap justify-center text-center break-all w-44 lg:w-3/12 ">
          {itemObj.floor}
        </span>
        <span className="w-24 text-center lg:pl-0 lg:w-3/12 md:w-14 -pl-20 ">
          {itemObj.area}
        </span>
        <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-3/12">
          <input
            disabled={checked}
            value={email}
            onChange={handleChange}
            type="email"
            className={`${
              checked && "bg-grey-dark"
            } w-full text-sm font-semibold text-center text-grey placeholder-opacity-80 rounded-xl placeholder-grey-lightest`}
            placeholder="Enter email address"
          />
        </span>
        <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-2/12">
          <input
            disabled={checked}
            value={name}
            onChange={handleChangeName}
            type="text"
            className={`${
              checked && "bg-grey-dark"
            } w-full text-sm font-semibold text-center text-grey placeholder-opacity-80 rounded-xl placeholder-grey-lightest`}
            placeholder="Enter Name"
          />
        </span>
        <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-1/12">
          {["pending"].includes(status) ? (
            <LoadingAnimation />
          ) : (
            <button
              disabled={!email?.length || checked}
              data-background-color="#8B0021"
              data-tip="Save"
              onClick={() => handleEditClick(itemObj.id, email)}
              className="pointer"
              name="save"
            >
              <SaveIcon disabled={!email?.length || checked} />
            </button>
          )}
          {/* <span data-background-color="#8B0021" data-tip="Delete" onClick={()=>setOpenConfirmationCard(true)} className="pointer">
                <DeleteIcon />
            </span> */}
        </span>
        <ReactTooltip />
      </span>
    </Checkbox>
  );
};

const TableTitles = ({ setSort }) => (
  <span className="flex items-center justify-start w-full ml-1 font-semibold space-x-28 lg:space-x-1 text-grey-lightest flex-nowrap">
    <span className="w-5"></span>
    <span className="flex items-center justify-center space-x-1 w-14 lg:w-3/12 ">
      <span>Building</span>
      <button
        data-effect="solid"
        data-place="bottom"
        data-background-color="#8B0021"
        data-tip="Sort"
        onClick={() => setSort((prev) => "building")}
      >
        <SortIcon />
      </button>
    </span>
    <span className="flex items-center justify-center space-x-1 w-14 lg:w-3/12 ">
      <span>Floor</span>
      <button
        data-effect="solid"
        data-place="bottom"
        data-background-color="#8B0021"
        data-tip="Sort"
        onClick={() => setSort((prev) => "floor")}
      >
        <SortIcon />
      </button>
    </span>
    <span className="flex items-center justify-center space-x-1 w-14 lg:w-3/12 ">
      <span>Area</span>
      <button
        data-effect="solid"
        data-place="bottom"
        data-background-color="#8B0021"
        data-tip="Sort"
        onClick={() => setSort((prev) => "area")}
      >
        <SortIcon />
      </button>
    </span>
    <EmailTitle />
    <NameTitle />
    <ControlsSpacer />
    <ReactTooltip />
  </span>
);

// const SearchIcon = () => <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ccc"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>

export default Emails;
