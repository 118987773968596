import React, { useState, useEffect, useRef } from "react";

import Checkbox from "./Checkbox";
import MaxResponses from "./MaxResponses";
import StartEndTime from "./StartEndTime";
import StartEndDate from "./StartEndDate";
import { endDateTimeMaker, startDateTimeMaker } from "./utils";

const SettingsMain = ({ completeSurvey, setCompleteSurvey }) => {
  const surveySettings = completeSurvey.surveySettings;
  console.log(surveySettings.startDateTime);

  const [startOnPublish, setStartOnPublish] = useState(
    surveySettings.startOnPublish
  );
  const [startTime, setStartTime] = useState(
    new Date(surveySettings.startDateTime)?.toTimeString()
    // ?.split(" ")[1]
  );
  const [endTime, setEndTime] = useState(
    new Date(surveySettings.endDateTime)?.toTimeString()
    // ?.split(" ")[1]
  );
  const [startDate, setStartDate] = useState(
    new Date(surveySettings.startDateTime)?.toDateString()
    // ?.split(" ")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(surveySettings.endDateTime)?.toDateString()
    // ?.split(" ")[0]
  );

  useEffect(() => {
    setStartTime(new Date(surveySettings.startDateTime)?.toTimeString());
    setEndTime(new Date(surveySettings.endDateTime)?.toTimeString());
    setStartDate(new Date(surveySettings.startDateTime)?.toDateString());
    setEndDate(new Date(surveySettings.endDateTime)?.toDateString());
  }, [
    completeSurvey.surveySettings?.startDateTime,
    completeSurvey.surveySettings?.endDateTime,
  ]);

  const [editingStartTime, setEditingStartTime] = useState(false);
  const [editingEndTime, setEditingEndTime] = useState(false);
  const [editingStartDate, setEditingStartDate] = useState(false);
  const [editingEndDate, setEditingEndDate] = useState(false);

  const [allowMultipleResponses, setAllowMultipleResponses] = useState(true); // surveySettings.allowMultipleResponses
  const [limitResponses, setLimitResponses] = useState(
    surveySettings.limitResponses
  );

  const [maxResponses, setMaxResponses] = useState(surveySettings.maxResponses);
  const [editingMaxResponses, setEditingMaxResponses] = useState(false);

  const handleStartOnPublishCheck = () => {
    setStartOnPublish((prev) => !prev);
  };

  const handleAllowMultipleResponsesCheck = () => {
    setAllowMultipleResponses((prev) => !prev);
  };
  const handleLimitResponsesCheck = () => {
    setLimitResponses((prev) => !prev);
  };

  // update survey state
  useEffect(() => {
    const newObj = { ...completeSurvey };
    newObj.surveySettings.startOnPublish = startOnPublish;

    // newObj.surveySettings.startTime = startOnPublish
    //   ? new Date().toJSON().split("T")[1].split(".")[0]
    //   : startTime; // HH:MM:SS
    // newObj.surveySettings.endTime = endTime;
    // newObj.surveySettings.startDate = startOnPublish
    //   ? new Date().toJSON().split("T")[0]
    //   : startDate; // YYYY-MM-DD
    // newObj.surveySettings.endDate = endDate;

    newObj.surveySettings.startDateTime = startDateTimeMaker(
      startOnPublish,
      startTime,
      startDate
    );
    newObj.surveySettings.endDateTime = endDateTimeMaker(endTime, endDate);

    newObj.surveySettings.allowMultipleResponses = allowMultipleResponses;
    newObj.surveySettings.limitResponses = limitResponses;
    newObj.surveySettings.maxResponses = limitResponses ? maxResponses : 99999;
    setCompleteSurvey(newObj);
  }, [
    startTime,
    endTime,
    startDate,
    endDate,
    allowMultipleResponses,
    limitResponses,
    maxResponses,
    startOnPublish,
  ]);

  const node = useRef();
  const innerNode = useRef();

  const handleOutsideClick = (e) => {
    if (
      node.current.contains(e.target) ||
      innerNode.current.contains(e.target)
    ) {
      // inside click
      return;
    }
    setEditingStartTime(false);
    setEditingStartDate(false);
    setEditingMaxResponses(false);
    setEditingEndDate(false);
    setEditingEndTime(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleOutsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div
      style={{ boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.19)", width: "100%" }}
      className="flex flex-col items-start justify-center w-full pb-10 space-y-5"
    >
      <div className="mx-10 mt-5 text-2xl font-semibold">Settings</div>
      {/* <div className="w-full space-y-5">
                <div className="mx-10 space-y-5">
                    <div className="font-semibold">Survey Groups</div>
                    <div><input className="w-full px-10 py-3 border rounded-xl border-grey-lightest" type="text" name="Survey Groups" placeholder="Search for survey groups..."/></div>
                </div>
            </div> */}
      <div className="flex flex-col justify-center w-full px-10 space-y-5">
        <span className="mb-1 text-lg font-semibold">Scheduling</span>
        <span className="w-full space-y-5" ref={node}>
          <span onClick={handleStartOnPublishCheck}>
            <Checkbox checked={startOnPublish}>Start on Publishing</Checkbox>
          </span>
          <span className="flex items-center w-full space-x-10" ref={innerNode}>
            <StartEndTime
              startOnPublish={startOnPublish}
              editingStartTime={editingStartTime}
              setStartTime={setStartTime}
              setEditingStartTime={setEditingStartTime}
              editingEndTime={editingEndTime}
              setEditingEndTime={setEditingEndTime}
              endTime={endTime}
              setEndTime={setEndTime}
              startTime={startTime}
            />
          </span>
          <span
            className="flex items-center w-full space-x-10 "
            ref={innerNode}
          >
            <StartEndDate
              startOnPublish={startOnPublish}
              editingStartDate={editingStartDate}
              setStartDate={setStartDate}
              setEditingStartDate={setEditingStartDate}
              editingEndDate={editingEndDate}
              setEditingEndDate={setEditingEndDate}
              endDate={endDate}
              setEndDate={setEndDate}
              startDate={startDate}
            />
          </span>
        </span>
      </div>
      <div className="flex flex-col justify-center w-full mx-10 space-y-5">
        <span className="text-lg font-semibold">Responses</span>
        <span className="w-full space-y-5">
          <span onClick={handleLimitResponsesCheck}>
            <Checkbox checked={limitResponses}>Limit Responses</Checkbox>
          </span>
        </span>
        {limitResponses && (
          <span className="w-full">
            <MaxResponses
              maxResponses={maxResponses}
              setMaxResponses={setMaxResponses}
              editingMaxResponses={editingMaxResponses}
              setEditingMaxResponses={setEditingMaxResponses}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default SettingsMain;
