export const EmailTitle = () => (<span className="flex items-center justify-center space-x-1 w-18 lg:w-3/12 md:w-18 ">
<span>Email</span>
{/* <button onClick={() => setSort((prev) => "email")}>
  <SortIcon />
</button> */}
</span>)

export const NameTitle = () => (<span className="flex items-center justify-center space-x-1 w-18 lg:w-2/12 md:w-18 ">
<span>Name</span>
</span>)

export const ControlsSpacer = () => (
    <span className="text-center w-18 lg:w-1/12 md:w-18 "></span>
)