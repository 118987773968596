import React, {useState, useEffect, useRef} from 'react';

const QuestionOptions = ({ handleOptionClick, openQuestionOptions, setOpenQuestionOptions }) => {

    const handleOutsideClick = (e) => {
        if (node.current.contains(e.target)) {
          // inside click
          return;
        }
        setOpenQuestionOptions(false);
      }
    
      const node = useRef();
    
      useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleOutsideClick);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleOutsideClick);
        };
      }, []);

    return (
        <div ref={node} className="absolute z-50 right-20">
            <div className="flex flex-col items-start justify-center mt-5 space-y-2 transition-all opacity-100 lg:h-64 rounded-2xl lg:w-60 bg-grey-dark">
                        <a onClick={()=>handleOptionClick('dropdown-vertical')} className="newQuestionOptionItem pointer">
                            <span><SmallDndIcon /></span>
                            <span>Simple Drop Down</span>
                        </a>
                        <a onClick={()=>handleOptionClick('textbox')} className=" newQuestionOptionItem pointer">
                            <span><TextIcon /></span>
                            <span className="pl-4">Text Box</span>
                        </a>
                        <a onClick={()=>handleOptionClick('dropdown-horizontal')} className="newQuestionOptionItem pointer">
                            <span><HorizontalDropDownIcon /></span>
                            <span>Horizontal Drop Down</span>
                        </a>
                        {/* <div style={{backgroundColor:'darkgray', height:'1px', width: '100px'}}></div> */}
                        <a onClick={()=>handleOptionClick('checkbox-dropdown-vertical')} className="newQuestionOptionItem pointer">
                            <span><CheckBoxDropDownIcon /></span>
                            <span>Checkbox Dropdown</span>
                        </a>
                        <a onClick={()=>handleOptionClick('checkbox-simple')} className="newQuestionOptionItem pointer">
                            <span><SimpleCheckBoxIcon /></span>
                            <span>Simple Checkbox</span>
                        </a>
                    </div>
        </div>
    )
}

const SmallDndIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18 12H6" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18 8H6" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18 16H6" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const DndIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 10H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 6H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 14H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 18H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const TextIcon = () => <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.35 0.199999V1.74H4.592V10H2.982V1.74H0.21V0.199999H7.35Z" fill="#2F2E41"/> </svg>
const HorizontalDropDownIcon = () => <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 1L1 1" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15 1L10 1" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const CheckBoxDropDownIcon = () => <span className="center"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="10" height="10" rx="5" fill="#343434"/> <path d="M7.49996 3.33325L4.34892 6.8798L2.91663 5.26773" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 3.75L5 6.25L7.5 3.75" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg></span>
const SimpleCheckBoxIcon = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="14" height="14" rx="3" fill="#343434"/> <path d="M10.5 4.66675L6.08858 9.63192L4.08337 7.37502" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>

export default QuestionOptions
