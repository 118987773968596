import React, {useState} from 'react'

const BottomNav = ({LoadingAnimation, areLabelsUnique, updateSurveyToApi, updateSurvey, setUpdateSurvey, pageOpen, setPageOpen, setOpenNewSurveyCard, sendNewSurveyToApi }) => {

    const labelUnique = areLabelsUnique!==undefined ? areLabelsUnique : true;

    const [isLoading, setIsLoading] = useState(false);

    const handleProceed = () => {
        if(pageOpen==='create') {
            // setPageOpen('review');
            setPageOpen('settings');
        } 
        // else if(
        //     pageOpen=='review'
        //     ) {
        //     setPageOpen('settings');
        // } 
        else if (pageOpen=='settings') {
            if(updateSurvey){
                setIsLoading(true);
                const res = updateSurveyToApi();
                if(res) setIsLoading(false)
            } else{
                setIsLoading(true);
                const res = sendNewSurveyToApi();
                if(res) setIsLoading(false);
            }
            // if(status===201) setPageOpen('publish');
            // setPageOpen('publish');
        }
    }

    const handleBack = () => {
        if(pageOpen==='publish') {
            setPageOpen('settings');
        } 
        else if(pageOpen=='settings') {
            setPageOpen('create');
        } 
        // else if (pageOpen=='review') {
        //     setPageOpen('create');
        // }
        else {
            setOpenNewSurveyCard(false);
        }
    }

    return (
        <div className="flex items-center justify-between mx-10">
            <button onClick={handleBack} className="py-2 pr-1 space-x-2 font-semibold transition-all rounded-xl center w-28 hover:opacity-90 flex-nowrap bg-grey-light">
                <ArrowLeft />
                <span className="">Back</span>
            </button>
            {console.log(labelUnique)}
            
            { pageOpen ==="settings" ?
                <>
                {
                    !isLoading ?
                    <button onClick={handleProceed} disabled={!labelUnique} className={labelUnique ? "proceed-btn" : 'proceed-btn-disabled'}>
                        <span>Proceed</span> 
                        <ArrowRight />
                    </button>
                    :
                    <span className="pr-5"><LoadingAnimation /></span>
                }
                </>
                :
                <>
                    <button onClick={handleProceed} disabled={!labelUnique} className={labelUnique ? "proceed-btn" : 'proceed-btn-disabled'}>
                        <span>Proceed</span> 
                        <ArrowRight />
                    </button>    
                </>
            }
        </div>
    )
}

const ArrowLeft = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19 12L5 12" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 19L5 12L12 5" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const ArrowRight = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>

export default BottomNav
