// NEW QUESTION

export const DeleteIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3 6H5H21" stroke="#F77D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#F77D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 11V17" stroke="#F77D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14 11V17" stroke="#F77D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
export const DndIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 10H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 6H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 14H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M21 18H3" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
export const EyeInvisibleIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#878787" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#878787"/> </svg>
export const EyeVisibleIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#8B0021" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#8B0021"/> </svg>
export const EyeIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#CCCCCC"/> </svg>
export const SmallDndIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18 12H6" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18 8H6" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18 16H6" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
export const TextIcon = () => <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.35 0.199999V1.74H4.592V10H2.982V1.74H0.21V0.199999H7.35Z" fill="#2F2E41"/> </svg>
export const HorizontalDropDownIcon = () => <svg width="25" height="2" viewBox="0 0 25 2" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 1L1 1" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15 1L10 1" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M24 1L19 1" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
export const CheckBoxDropDownIcon = () => <svg width="36" height="18" viewBox="0 0 36 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="18" height="18" rx="6" fill="#343434"/> <path d="M13.5 6L7.82813 12.3838L5.25 9.48207" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M22.5 6.75L27 11.25L31.5 6.75" stroke="#343434" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
export const SimpleCheckBoxIcon = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="14" height="14" rx="3" fill="#343434"/> <path d="M10.5 4.66675L6.08858 9.63192L4.08337 7.37502" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>

// SIDEBAR 

export const SelectedDivider = () => <svg width="229" height="1" viewBox="0 0 229 1" fill="none" xmlns="http://www.w3.org/2000/svg"> <line y1="0.5" x2="229" y2="0.5" stroke="#CCCCCC"/> </svg>
export const Divider = () => <svg width="63" height="1" viewBox="0 0 63 1" fill="none" xmlns="http://www.w3.org/2000/svg"> <line y1="0.5" x2="63" y2="0.5" stroke="#CCCCCC"/> </svg>
export const ArrowIcon = () => <svg className="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5 12L19 12" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M12 5L19 12L12 19" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const SelectedArrowIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19 12H5" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M12 19L5 12L12 5" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const HomeIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M9 22V15H15V22" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const SelectedHomeIcon = ()=> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M9 22V15H15V22" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const DocumentIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M14 2V8H20" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M16 13H8" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M16 17H8" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const SelectedDocumentIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M14 2V8H20" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M16 13H8" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M16 17H8" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const ProfileIcon = ()=> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const SelectedProfileIcon = ()=> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const PeopleIcon = ()=> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.2917 18V16.3333C17.2917 15.4493 17.002 14.6014 16.4863 13.9763C15.9706 13.3512 15.2711 13 14.5417 13H9.04175C8.3124 13 7.61293 13.3512 7.0972 13.9763C6.58148 14.6014 6.29175 15.4493 6.29175 16.3333V18" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M11.2917 10C12.9486 10 14.2917 8.65685 14.2917 7C14.2917 5.34315 12.9486 4 11.2917 4C9.63489 4 8.29175 5.34315 8.29175 7C8.29175 8.65685 9.63489 10 11.2917 10Z" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M22.2917 18V16.2964C22.2913 15.5415 22.0947 14.8082 21.7327 14.2115C21.3707 13.6149 20.8638 13.1887 20.2917 13" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M16.2917 4C17.1499 4.17056 17.9106 4.55796 18.4537 5.10114C18.9969 5.64432 19.2917 6.31238 19.2917 7C19.2917 7.68762 18.9969 8.35567 18.4537 8.89886C17.9106 9.44204 17.1499 9.82944 16.2917 10" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M1.29175 18V16.2964C1.29219 15.5415 1.48884 14.8082 1.85084 14.2115C2.21283 13.6149 2.71966 13.1887 3.29175 13" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M6.29175 4C5.43358 4.17056 4.67294 4.55796 4.12976 5.10114C3.58658 5.64432 3.29175 6.31238 3.29175 7C3.29175 7.68762 3.58658 8.35567 4.12976 8.89886C4.67294 9.44204 5.43358 9.82944 6.29175 10" stroke="#878787" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const SelectedPeopleIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.292 18V16.3333C17.292 15.4493 17.0023 14.6014 16.4865 13.9763C15.9708 13.3512 15.2713 13 14.542 13H9.04199C8.31265 13 7.61317 13.3512 7.09745 13.9763C6.58172 14.6014 6.29199 15.4493 6.29199 16.3333V18" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M11.292 10C12.9488 10 14.292 8.65685 14.292 7C14.292 5.34315 12.9488 4 11.292 4C9.63514 4 8.29199 5.34315 8.29199 7C8.29199 8.65685 9.63514 10 11.292 10Z" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M22.2917 18V16.2964C22.2913 15.5415 22.0947 14.8082 21.7327 14.2115C21.3707 13.6149 20.8638 13.1887 20.2917 13" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M16.292 4C17.1502 4.17056 17.9108 4.55796 18.454 5.10114C18.9972 5.64432 19.292 6.31238 19.292 7C19.292 7.68762 18.9972 8.35567 18.454 8.89886C17.9108 9.44204 17.1502 9.82944 16.292 10" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M1.29199 18V16.2964C1.29243 15.5415 1.48909 14.8082 1.85108 14.2115C2.21308 13.6149 2.71991 13.1887 3.29199 13" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M6.29199 4C5.43382 4.17056 4.67319 4.55796 4.13001 5.10114C3.58682 5.64432 3.29199 6.31238 3.29199 7C3.29199 7.68762 3.58682 8.35567 4.13001 8.89886C4.67319 9.44204 5.43382 9.82944 6.29199 10" stroke="#8B0021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
export const RolesIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M23.3013 4.26581L23.2969 4.26562H16.9219V3.5625C16.9219 2.39941 15.9756 1.45312 14.8125 1.45312H9.1875C8.02441 1.45312 7.07812 2.39941 7.07812 3.5625V4.26562H0.703125C0.311646 4.26562 0 4.58643 0 4.96875V20.4375C0 21.6006 0.946289 22.5469 2.10938 22.5469H21.8906C23.0537 22.5469 24 21.6006 24 20.4375V4.98358V4.98083C23.9731 4.51904 23.6891 4.26837 23.3013 4.26581ZM8.48438 3.5625C8.48438 3.17487 8.79987 2.85938 9.1875 2.85938H14.8125C15.2001 2.85938 15.5156 3.17487 15.5156 3.5625V4.26562H8.48438V3.5625ZM22.3213 5.67188L20.1378 12.2223C20.042 12.5099 19.7739 12.7031 19.4709 12.7031H15.5156V12C15.5156 11.6116 15.2009 11.2969 14.8125 11.2969H9.1875C8.79913 11.2969 8.48438 11.6116 8.48438 12V12.7031H4.52911C4.22607 12.7031 3.95801 12.5099 3.86224 12.2223L1.67871 5.67188H22.3213ZM14.1094 12.7031V14.1094H9.89062V12.7031H14.1094ZM22.5938 20.4375C22.5938 20.8251 22.2783 21.1406 21.8906 21.1406H2.10938C1.72174 21.1406 1.40625 20.8251 1.40625 20.4375V9.30157L2.52814 12.6671C2.81561 13.5298 3.61981 14.1094 4.52911 14.1094H8.48438V14.8125C8.48438 15.2009 8.79913 15.5156 9.1875 15.5156H14.8125C15.2009 15.5156 15.5156 15.2009 15.5156 14.8125V14.1094H19.4709C20.3802 14.1094 21.1844 13.5298 21.4719 12.6671L22.5938 9.30157V20.4375Z" fill="#878787"/> </svg>
export const SelectedRolesIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M23.3013 4.26581L23.2969 4.26562H16.9219V3.5625C16.9219 2.39941 15.9756 1.45312 14.8125 1.45312H9.1875C8.02441 1.45312 7.07812 2.39941 7.07812 3.5625V4.26562H0.703125C0.311646 4.26562 0 4.58643 0 4.96875V20.4375C0 21.6006 0.946289 22.5469 2.10938 22.5469H21.8906C23.0537 22.5469 24 21.6006 24 20.4375V4.98358V4.98083C23.9731 4.51904 23.6891 4.26837 23.3013 4.26581ZM8.48438 3.5625C8.48438 3.17487 8.79987 2.85938 9.1875 2.85938H14.8125C15.2001 2.85938 15.5156 3.17487 15.5156 3.5625V4.26562H8.48438V3.5625ZM22.3213 5.67188L20.1378 12.2223C20.042 12.5099 19.7739 12.7031 19.4709 12.7031H15.5156V12C15.5156 11.6116 15.2009 11.2969 14.8125 11.2969H9.1875C8.79913 11.2969 8.48438 11.6116 8.48438 12V12.7031H4.52911C4.22607 12.7031 3.95801 12.5099 3.86224 12.2223L1.67871 5.67188H22.3213ZM14.1094 12.7031V14.1094H9.89062V12.7031H14.1094ZM22.5938 20.4375C22.5938 20.8251 22.2783 21.1406 21.8906 21.1406H2.10938C1.72174 21.1406 1.40625 20.8251 1.40625 20.4375V9.30157L2.52814 12.6671C2.81561 13.5298 3.61981 14.1094 4.52911 14.1094H8.48438V14.8125C8.48438 15.2009 8.79913 15.5156 9.1875 15.5156H14.8125C15.2009 15.5156 15.5156 15.2009 15.5156 14.8125V14.1094H19.4709C20.3802 14.1094 21.1844 13.5298 21.4719 12.6671L22.5938 9.30157V20.4375Z" fill="#8B0021"/> </svg>

export const CheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="18" height="18" rx="6" fill="#8B0021"/> <path d="M13.5 6L7.82813 12.3838L5.25 9.48207" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
export const UncheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="17" height="17" rx="5.5" stroke="#CCCCCC"/> </svg>

// TABLES
export const ActiveIcon = () => <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"> <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#8CD76A"/> </svg>
export const InactiveIcon = () => <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"> <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#CCCCCC"/> </svg>
export const EditIcon = () => <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16 2.00006C16.2626 1.73741 16.5744 1.52907 16.9176 1.38693C17.2608 1.24479 17.6286 1.17163 18 1.17163C18.3714 1.17163 18.7392 1.24479 19.0824 1.38693C19.4256 1.52907 19.7374 1.73741 20 2.00006C20.2626 2.2627 20.471 2.57451 20.6131 2.91767C20.7553 3.26083 20.8284 3.62862 20.8284 4.00006C20.8284 4.37149 20.7553 4.73929 20.6131 5.08245C20.471 5.42561 20.2626 5.73741 20 6.00006L6.5 19.5001L1 21.0001L2.5 15.5001L16 2.00006Z" stroke="#878787" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>


export const SaveIcon = ({ disabled }) => (
    <svg
      name="save"
      xmlns="http://www.w3.org/2000/svg"
      height="36px"
      viewBox="0 0 24 24"
      width="36px"
      fill={!disabled ? "#8B0021" : "#ccc"}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
    </svg>
  );
  
export const SortIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="#ccc"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
    </svg>
  );
