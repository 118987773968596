import React, { useState, useEffect } from "react";

import AddNewRoleCard from "./AddNewRoleCard";

import ConfirmationCard from "../../ConfirmationCard";

import axios from "axios";
import ReactTooltip from "react-tooltip";
import EmptyResource from "../../EmptyResource";

const RolesPage = ({
  surveyGroups,
  availableSurveys,
  EmptyTableSVG,
  LoadingAnimation,
  openToast,
  endpoints,
  axiosOptions,
  goToLoginAndRemoveTokensFromLS,
}) => {
  const [openAddNewRoleCard, setOpenAddNewRoleCard] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [updateRole, setUpdateRole] = useState(false);
  const [viewRole, setViewRole] = useState(false);

  const dateMaker = (date) => {
    // dd/mm/yy
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    const yy = date.getFullYear();

    return `${dd}/${mm}/${yy}`;
  };

  // const userObj =  {
  //             "active": true,
  //             "created_at": "Mon, 22 Mar 2021 15:23:20 GMT",
  //             "email": "sum.admin@admin.admin",
  //             "email_confirmed_at": "Mon, 22 Mar 2021 15:23:20 GMT",
  //             "id": 10,
  //             "organizations": [],
  //             "role": {
  //                 "created_at": "Mon, 22 Mar 2021 15:23:19 GMT",
  //                 "description": null,
  //                 "id": 4,
  //                 "name": "superadmin",
  //                 "updated_at": null
  //             },
  //             "updated_at": null,
  //             "username": "superadmin"
  //         }

  const [usersArray, setUsersArray] = useState([]);

  const [loadingAvailableUsers, setLoadingAvailableUsers] = useState(false);

  useEffect(() => {
    axios
      .get(endpoints.availableRoles, axiosOptions)
      .then((res) => {
        const cleanedArr = [];
        try {
          const resArr = res.data.json_list;
          resArr.map((roleObj) => {
            const newRoleObj = {
              id: roleObj.id,
              name: roleObj.name,
              description: roleObj.description,
              createdAt: dateMaker(new Date(roleObj.created_at)),
              permissions: roleObj.permissions,
            };
            cleanedArr.push(newRoleObj);
          });
        } catch (err) {
          console.log(err);
          openToast(
            goToLoginAndRemoveTokensFromLS,
            "error",
            "Please login, you're not authorized."
          );
        }
        console.log("avail roles cleaned", cleanedArr);
        setAvailableRoles(cleanedArr);
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        console.log(err);
        // openToast(
        //     goToLoginAndRemoveTokensFromLS,
        //     'error',
        //     "Please login, you're not authorized."
        // );
      });
  }, [availableRoles.length, updateRole]);

  useEffect(() => {
    setLoadingAvailableUsers(true);
    axios
      .get(endpoints.availableUsers, axiosOptions)
      .then((res) => {
        const resObj = res.data;
        const dataArr = resObj.json_list;
        const cleanedArr = [];
        console.log("DATAAA", dataArr);
        try {
          if (dataArr) {
            if (dataArr.length > 0) {
              dataArr.map((userObj) => {
                const newUserObj = {
                  id: userObj.id,
                  username: userObj.username,
                  // name: userObj.username,
                  email: userObj.email,
                  role: {
                    name: userObj.role.name,
                    id: userObj.role.id,
                  },
                  visible: userObj.active,
                };
                cleanedArr.push(newUserObj);
              });
            }
          }
        } catch (err) {
          console.log(err);
          setLoadingAvailableUsers(false);
          openToast(console.log, "error", err.message);
        }
        setUsersArray(cleanedArr);
        setLoadingAvailableUsers(false);
        console.log("CLEANEED", cleanedArr);
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        console.log(err);
        setLoadingAvailableUsers(false);
        // openToast(
        //     goToLoginAndRemoveTokensFromLS,
        //     'error',
        //     "Please login, you're not authorized."
        // );
      });
  }, [usersArray.length]);

  // const [userRoles, setUserRoles] = useState(['admin', 'user', 'superadmin']);

  const [roleObj, setRoleObj] = useState({
    name: "",
    email: "",
    userId: "",
    role: {
      name: "",
      id: 0,
    },
    id: "",
    // userGroups: [],
  });

  const handleRoleDeleteClick = (id) => {
    axios
      .delete(`${endpoints.deleteRole}/${id}`, axiosOptions)
      .then((res) => {
        setAvailableRoles((prev) => prev.filter((item) => item.id !== id));
        openToast(
          () => console.log("Role deleted"),
          "success",
          "Successfully deleted Role."
        );
      })
      .catch((err) => {
        if (
          ["unsupported authentication type", "expired token"].includes(
            err.response?.data
          )
        )
          goToLoginAndRemoveTokensFromLS();
        openToast(
          console.log, //goToLoginAndRemoveTokensFromLS,
          "error",
          "You're do not have permissions for this operation."
        );
      });
    console.log("id", id);
  };

  const handleRoleEditClick = (id) => {
    const selectedRoleObj = availableRoles.filter((item) => item.id === id)[0];
    setUpdateRole(true);
    setRoleObj(selectedRoleObj);
    setOpenAddNewRoleCard(true);
  };

  const handleRoleReviewClick = (id) => {
    const selectedRoleObj = usersArray.filter((item) => item.id === id)[0];
    setViewRole(true);
    setUpdateRole(false);
    setRoleObj(selectedRoleObj);
    setOpenAddNewRoleCard(true);
  };

  return (
    <div className="flex flex-col w-full space-y-16 transition-all">
      <div className="flex items-center space-x-5">
        <div className="mt-8 ml-10 blue-heading">Roles</div>
        <button
          onClick={() => {
            setUpdateRole(false);
            // setUserObj({
            //     name: '',
            //     email: '',
            //     // userId: '',
            //     role: {
            //         name: availableRoles[0].name,
            //         id: availableRoles[0].id
            //     },
            //     // id: '',
            //     // userGroups: [],
            // });
            setOpenAddNewRoleCard(true);
          }}
          // style={{boxShadow:'0px 8px 18px #FFC9CB'}}
          className="mt-8 text-sm text-white shadow-2xl w-36 h-9 bg-red rounded-xl"
        >
          Add New
        </button>
      </div>
      {/* <div className="w-full center">
                <span className="underDevelopmentCard">This page is undergoing development.</span>
            </div> */}
      {openAddNewRoleCard && (
        <span className="w-full">
          <AddNewRoleCard
            availableSurveys={availableSurveys}
            viewRole={viewRole}
            setViewRole={setViewRole}
            updateRole={updateRole}
            setUpdateRole={setUpdateRole}
            openToast={openToast}
            usersArray={usersArray}
            setUsersArray={setUsersArray}
            roleObj={roleObj}
            setRoleObj={setRoleObj}
            // userRoles={userRoles}
            axiosOptions={axiosOptions}
            goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS}
            endpoints={endpoints}
            setOpenAddNewRoleCard={setOpenAddNewRoleCard}
            availableRoles={availableRoles}
            setAvailableRoles={setAvailableRoles}
            surveyGroups={surveyGroups}
          />
        </span>
      )}
      <div
        style={{ boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.19)" }}
        className="w-11/12 p-5 pt-10 mx-10 h-96 rounded-xl"
      >
        <RolesTable
          availableRoles={availableRoles}
          viewRole={viewRole}
          setViewRole={setViewRole}
          handleRoleReviewClick={handleRoleReviewClick}
          EmptyTableSVG={EmptyTableSVG}
          loadingAvailableUsers={loadingAvailableUsers}
          LoadingAnimation={LoadingAnimation}
          key={usersArray.length}
          handleRoleEditClick={handleRoleEditClick}
          handleRoleDeleteClick={handleRoleDeleteClick}
          usersArray={usersArray}
        />
      </div>
    </div>
  );
};

const RolesTable = (props) => {
  const {
    availableRoles,
    handleRoleReviewClick,
    LoadingAnimation,
    loadingAvailableUsers,
    usersArray,
    handleRoleDeleteClick,
    handleRoleEditClick,
  } = props;

  return (
    <div
      className={
        usersArray.length > 5
          ? "space-y-5 overflow-x-scroll overflow-y-scroll lg:overflow-x-hidden h-72"
          : "space-y-5 overflow-x-scroll overflow-y-visible lg:overflow-x-hidden h-72"
      }
    >
      <TableTitles />
      {!loadingAvailableUsers ? (
        <span className="flex flex-col w-full pt-8 space-y-5">
          {availableRoles.length > 0 ? (
            <>
              {availableRoles.map((roleObj, index) => (
                <RowItem
                  handleRoleReviewClick={handleRoleReviewClick}
                  handleRoleEditClick={handleRoleEditClick}
                  handleRoleDeleteClick={handleRoleDeleteClick}
                  roleObj={roleObj}
                  index={index}
                  key={index}
                />
              ))}
            </>
          ) : (
            <EmptyResource text="No Roles Found" />
          )}
        </span>
      ) : (
        <span className="pt-20 center">
          <LoadingAnimation />{" "}
        </span>
      )}
    </div>
  );
};

const RowItem = ({
  roleObj,
  index,
  handleRoleReviewClick,
  handleRoleDeleteClick,
  handleRoleEditClick,
}) => {
  const [openConfirmationCard, setOpenConfirmationCard] = useState(false);

  return (
    <span key={roleObj.id} className="flex">
      <Checkbox>
        <span className="w-24 text-center break-all lg:w-2/12 ">
          {roleObj.name}
        </span>
        {/* <span className="w-24 break-all lg:w-24">{userObj.name}</span> */}
        <span className="flex flex-wrap justify-center text-center break-all w-44 lg:w-4/12 ">
          {roleObj.description}
        </span>
        <span className="w-24 text-center lg:pl-0 lg:w-2/12 md:w-14 -pl-20">
          {roleObj.createdAt}
        </span>
        {/* <span className="w-20">
                    { surveyGroup.status==='active' ? <Active />: <Inactive /> }
                </span> */}
        <span className="flex justify-center w-20 space-x-4 text-center flex-nowrap lg:w-2/12">
          {/* <span onClick={()=>handleUserReviewClick(userObj.id)} data-background-color="#8B0021" data-tip="View" className="pointer">
                    {
                        roleObj.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span> */}
          <span
            data-background-color="#8B0021"
            data-tip="Edit"
            onClick={() => handleRoleEditClick(roleObj.id)}
            className="pointer"
          >
            <EditIcon />
          </span>
          <span
            data-background-color="#8B0021"
            data-tip="Delete"
            onClick={() => setOpenConfirmationCard(true)}
            className="pointer"
          >
            <DeleteIcon />
          </span>
          {openConfirmationCard && (
            <ConfirmationCard
              deleteFunc={() => handleRoleDeleteClick(roleObj.id)}
              setOpenConfirmationCard={setOpenConfirmationCard}
            />
          )}
        </span>
      </Checkbox>
      {/* <span className="flex justify-end w-2/4 pr-10 space-x-3">
                    <span className="pointer">
                    {
                        surveyGroup.visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
                    }
                    </span>
                    <span className="pointer">
                        <EditIcon />
                    </span>
                    <span className="pointer">
                        <DeleteIcon />
                    </span>
            </span> */}
      <ReactTooltip />
    </span>
  );
};

const TableTitles = () => (
  <span className="flex items-center justify-start w-full mx-5 font-semibold space-x-28 lg:space-x-4 text-grey-lightest flex-nowrap ">
    <span className="w-5 text-center lg:w-2/12 ">Name</span>
    {/* <span className="w-14 lg:w-14">Name</span> */}
    <span className="w-16 text-center lg:w-4/12 ">Description</span>
    <span className="text-center w-18 lg:w-2/12 md:w-18 ">Created At</span>
  </span>
);

// const Active = () => <div className="space-x-2 center"><span><ActiveIcon /></span><span>Active</span></div>
// const Inactive = () => <span className="space-x-2 center"><span><InactiveIcon /></span><span>Inactive</span></span>

const Checkbox = ({ children }) => {
  return (
    <div className="flex items-start w-full font-semibold text-blue">
      {/* <span className="mt-1 ml-9">{checked ? <CheckedIcon /> : <UncheckedIcon /> }</span> */}
      <span className="flex w-full ml-5 lg:space-x-6 space-x-14 md:space-x-14">
        {children}
      </span>
    </div>
  );
};

// const CheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="18" height="18" rx="6" fill="#8B0021"/> <path d="M13.5 6L7.82813 12.3838L5.25 9.48207" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
// const UncheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="17" height="17" rx="5.5" stroke="#CCCCCC"/> </svg>
// const EyeInvisibleIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#878787" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#878787"/> </svg>
// const EyeVisibleIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#8B0021" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#8B0021"/> </svg>
const ActiveIcon = () => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#8CD76A" />{" "}
  </svg>
);
const InactiveIcon = () => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <ellipse cx="4.001" cy="4" rx="4.001" ry="4" fill="#CCCCCC" />{" "}
  </svg>
);
const EditIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      d="M16 2.00006C16.2626 1.73741 16.5744 1.52907 16.9176 1.38693C17.2608 1.24479 17.6286 1.17163 18 1.17163C18.3714 1.17163 18.7392 1.24479 19.0824 1.38693C19.4256 1.52907 19.7374 1.73741 20 2.00006C20.2626 2.2627 20.471 2.57451 20.6131 2.91767C20.7553 3.26083 20.8284 3.62862 20.8284 4.00006C20.8284 4.37149 20.7553 4.73929 20.6131 5.08245C20.471 5.42561 20.2626 5.73741 20 6.00006L6.5 19.5001L1 21.0001L2.5 15.5001L16 2.00006Z"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
  </svg>
);
const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      d="M3 6H5H21"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M10 11V17"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
    <path
      d="M14 11V17"
      stroke="#878787"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />{" "}
  </svg>
);

export default RolesPage;
