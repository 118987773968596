import React from 'react';

const MaxResponses = ({ maxResponses, setMaxResponses, editingMaxResponses, setEditingMaxResponses }) => {
    
    return (
        <>
            {
                editingMaxResponses ? 
                <input onChange={(e)=>setMaxResponses(e.target.value)} onKeyDownCapture={(e)=>{if(e.key==='Enter') setEditingMaxResponses(false)}} autoFocus className="px-10 py-2 font-semibold border w-96 text-grey-lightest border-grey-lightest rounded-xl focus:outline-none" placeholder="Enter a number" type="number" name="max responses"/>
                : 
                <div onClick={()=>setEditingMaxResponses(true)} className="px-10 py-2 font-semibold border w-96 text-grey-lightest pointer border-grey-lightest rounded-xl focus:outline-none" >
                    {maxResponses ? <><span>Max Number of Responses:</span>  <span className="text-grey-lightest">{maxResponses}</span></> : 'Max Number of Responses'}
                </div>
            }
        </>
    )
}

export default MaxResponses
