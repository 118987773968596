// ISO FORMAT: "2021-07-31T21:59:09.170Z"

export const startDateTimeMaker = (startOnPublish, startTime, startDate) => {
  if (startOnPublish) {
    return new Date().toISOString();
  } else {
    return new Date(`${startDate} ${startTime}`).toISOString();
  }
};

export const endDateTimeMaker = (endTime, endDate) => {
  return new Date(`${endDate} ${endTime}`).toISOString();
};

// export const startDateTimeMaker = (startOnPublish, startTime, startDate) => {
//   if (startOnPublish) {
//     return new Date().toISOString();
//   } else {
//     return new Date(`${startDate} ${startTime}`).toISOString();
//   }
// };

// export const endDateTimeMaker = (endTime, endDate) => {
//   return new Date(`${endDate} ${endTime}`).toISOString();
// };
