import React, {useState, useEffect} from 'react';
import { v4 as uuid } from 'uuid';


import TemplateTitleCard from '../../TemplateTitleCard';
import AddNewTemplateCard from '../../AddNewTemplateCard';

import dateMaker from './utils/dateMaker';
import dateFormatter from './utils/dateFormatter';

import MainSurveyTableCard from './MainSurveyTableCard';
import axios from 'axios';


const MainSurvey = ({ 
    LoadingAnimation,
    openToast,
    feedbackSurveyTemplate,
    setFeedbackSurveyTemplate,
    pageOpen, 
    setPageOpen, 
    completeSurvey,
    setCompleteSurvey,
    openNewSurveyCard, 
    setOpenNewSurveyCard,
    updateSurvey, 
    setUpdateSurvey, 
    endpoints, 
    goToLoginAndRemoveTokensFromLS, 
    axiosOptions,
    availableSurveys,
    setAvailableSurveys 
}) => {

    // COMMENT 3

    const [loadingAvailableSurveys, setLoadingAvailableSurveys] = useState(false);

    // COMMENT 2

    // useEffect(() => {
    //     setLoadingAvailableSurveys(true);
    // }, [])

    const cleanSurveyGroups = (surveygroups) => {
        const cleanedArr = [];
        if(surveygroups?.length>0){
            surveygroups.map(surveyGroupObj=>{
                const newSurveyGroupObj = {
                    id: surveyGroupObj.id,
                    surveyGroupName: surveyGroupObj.name,
                    description: surveyGroupObj.description,
                    organizationId: surveyGroupObj.organization_id,
                    created_on: dateMaker(new Date(surveyGroupObj.created_at))
                }
                cleanedArr.push(newSurveyGroupObj);
            })
        }
        return cleanedArr;
    }

    useEffect(() => {
        setLoadingAvailableSurveys(true);
        axios.get(endpoints.availableSurveys, axiosOptions).then(res=>{
            console.log('available surveys: ',res);
            const resObj = res.data;
            const dataArr = resObj.json_list;
            if(dataArr) if(dataArr.length<0) {
                setAvailableSurveys([]);
                setLoadingAvailableSurveys(false);
                return;
            }
            // const cleanedArr = dataCleaner();
            const parseQuestions = (questions) => {
                const x = questions.replace(/'/g, '"');
                const y = x.replace(/True/g, 'true');
                const z = y.replace(/False/g, 'false');

                let tempParsed = JSON.parse(z);

                let otherRemovedParsed = [];
                let otherLabel = '';
                let linkSource = '';

                tempParsed.map((questionObj,index)=>{
                    if(questionObj.type==='textbox' && questionObj["Link-Source"]){
                        otherLabel = questionObj.label;
                        let tempQuestion = {...questionObj};
                        linkSource = tempQuestion["Link-Source"];
                        const otherLinkQuestionArr = tempParsed.filter(item=>item.label===linkSource);
                        const otherLinkQuestion = otherLinkQuestionArr?.length>0 ? otherLinkQuestionArr[0] : null; 
                        
                        // index of Linked Question: index-1
                        if(typeof(otherLinkQuestion)==="object"){
                            const otherLabelIndex = otherLinkQuestion.fields?.findIndex(item=>item===otherLabel);
                            otherLinkQuestion.fields?.splice(otherLabelIndex, 1);

                            otherRemovedParsed.splice(index-1, 1, otherLinkQuestion);
                        }
                    }
                    otherRemovedParsed.push(questionObj);
                });

                otherRemovedParsed = otherRemovedParsed.filter(item=>item.label!==otherLabel);

                // console.log('otherRemovedParsed',otherRemovedParsed)

                let tempp = [];
                otherRemovedParsed.map((questionObj,index)=>{
                    tempp.push({
                        // "index": index,
                        "id": uuid(),
                        "type": questionObj.type==="dropdown-simple" ? "dropdown-vertical" : questionObj.type,
                        "mandatory": questionObj.mandatory ? true : false,
                        "title": questionObj.label,
                        "description": questionObj.description ? questionObj.description : "",
                        "enableMultiSelect": questionObj["multi-select"] ? true : false,
                        "options": questionObj?.fields ? questionObj.fields : [],
                        "fields2": questionObj?.fields2 ? questionObj?.fields2 : {},
                        // "default": "",
                        "depends": questionObj.depends ? true : false,
                        "dependsSource": questionObj["depends-source"] ? questionObj["depends-source"]?.split('/')?.length>1 ? questionObj["depends-source"].split('/')[1] : questionObj["depends-source"] : '',
                        "depending-childs": [],
                        "depending-childs-type": [],
                        "enableOtherResponse": linkSource===questionObj.label && otherLabel ? true : false,
                        "otherResponseLabel": linkSource===questionObj.label && otherLabel ? otherLabel : ''
                      })
                })

                const parsedQuestions = tempp;
                console.log('parsed',parsedQuestions)
                return parsedQuestions;
            }
            const cleanedArr = [];
            try{
                dataArr.map((surveyObj, index) => {
                    cleanedArr.push({
                        id: surveyObj.id,
                        created_on: dateMaker(new Date(surveyObj.created_at)),
                        status: surveyObj.is_active ? surveyObj.is_active : "active",
                        name: surveyObj.name,
                        visible: surveyObj.is_visible ? surveyObj.is_visible : true,
                        surveygroups: cleanSurveyGroups(surveyObj.surveygroups),
                        responses: 0,
                        surveyDescription: surveyObj.description ? surveyObj.description : '',
                        surveySettings: {
                            startDateTime: dateFormatter(new Date(surveyObj.created_at)),
                            endDateTime: dateFormatter(new Date(surveyObj.end_at)),
                            // startTime: currentTime(),
                            // endTime: currentTime(),
                            // startDate: currentDate(),
                            // endDate: oneWeekFromNowDate(),
                            allowMultipleResponses: surveyObj.allow_multiple_responses,
                            limitResponses: surveyObj.limit_responses ? true : false,
                            maxResponses: surveyObj.limit_responses,
                            startOnPublish: surveyObj.start_on_publish
                        },
                        surveyQuestions: parseQuestions(surveyObj.questions) //JSON.parse(surveyObj)
                    });
                });
            } catch(err) {
                console.log(err);
                setLoadingAvailableSurveys(false);
                openToast(
                    console.log, // goToLoginAndRemoveTokensFromLS,
                    'error',
                    "Error while parsing the available surveys, please check the error in console."
                );
            }
            setLoadingAvailableSurveys(false);
            setAvailableSurveys(cleanedArr);
            // setAvailableSurveys([]);
            console.log(cleanedArr)
        }).catch(err=>{
            console.log(err.response?.data);
            if(['unsupported authentication type', 'expired token'].includes(err.response?.data)) goToLoginAndRemoveTokensFromLS();
            setLoadingAvailableSurveys(false);
            // checkLoggedIn including openToast
            // openToast(
            //     console.log, // goToLoginAndRemoveTokensFromLS,
            //     'error',
            //     `Server Response, ${err.message}`
            // );
        });  
    }, [])
    
    return (
        <div className="flex flex-col">
            <Title />
            <div className="flex mt-8 ml-10 space-x-5 transition-all">
                <AddNewTemplateCard setUpdateSurvey={setUpdateSurvey} openNewSurveyCard={openNewSurveyCard} setOpenNewSurveyCard={setOpenNewSurveyCard}/>
                <TemplateTitleCard 
                    setOpenNewSurveyCard={setOpenNewSurveyCard}
                    setCompleteSurvey={setCompleteSurvey} 
                    feedbackSurveyTemplate={feedbackSurveyTemplate} 
                    setUpdateSurvey={setUpdateSurvey} 
                    selected={false} 
                    text="Feedback Survey"
                    />
                <TemplateTitleCard
                  setOpenNewSurveyCard={setOpenNewSurveyCard}
                  setCompleteSurvey={setCompleteSurvey} 
                  feedbackSurveyTemplate={feedbackSurveyTemplate}
                  setUpdateSurvey={setUpdateSurvey} 
                  selected={false} 
                  text="Survey Template"
                  />
                <TemplateTitleCard 
                    setOpenNewSurveyCard={setOpenNewSurveyCard}
                    setCompleteSurvey={setCompleteSurvey} 
                    feedbackSurveyTemplate={feedbackSurveyTemplate}
                    setUpdateSurvey={setUpdateSurvey} 
                    selected={true} 
                    text="Survey Template 2"
                    />
            </div>
            <div className="w-full mt-8 space-y-5">
                <TableTitle numOfAvailableSurveys = { availableSurveys?.length } />
                {
                    // loadingAvailableSurveys ? 

                    <MainSurveyTableCard
                        openToast={openToast}
                        LoadingAnimation={LoadingAnimation}
                        loadingAvailableSurveys ={loadingAvailableSurveys}
                        key={availableSurveys.length}
                        updateSurvey = {updateSurvey}
                        setUpdateSurvey = {setUpdateSurvey}
                        pageOpen={pageOpen}
                        setPageOpen={setPageOpen}
                        completeSurvey={completeSurvey}
                        setCompleteSurvey={setCompleteSurvey}
                        feedbackSurveyTemplate={feedbackSurveyTemplate}
                        setFeedbackSurveyTemplate={setFeedbackSurveyTemplate} 
                        setAvailableSurveys={setAvailableSurveys} 
                        axiosOptions={axiosOptions} 
                        goToLoginAndRemoveTokensFromLS={goToLoginAndRemoveTokensFromLS} 
                        endpoints={endpoints} 
                        availableSurveys={availableSurveys}
                        openNewSurveyCard={openNewSurveyCard} 
                        setOpenNewSurveyCard={setOpenNewSurveyCard}
                        />

                    // :

                    // <LoadingAnimation key={availableSurveys.length}/>

                }
            </div>
        </div>
    )
}

const Title = () => {
    return (
        <div className="flex items-center justify-between">
            <h1 className="mt-8 ml-10 blue-heading">Create A Survey</h1>
            {/* <a href="#" className="mt-8 mr-20 text-sm text-red-light">More Templates</a> */}
        </div>
)}

const TableTitle = ({ numOfAvailableSurveys }) => {
    return (
        <div className="flex items-center ml-10 mr-0 space-x-4">
            <span className="blue-heading">Available Surveys</span>
            <span className="pt-2 small-pink-text"> { numOfAvailableSurveys } surveys available</span>
        </div>
    )
}

export default MainSurvey
