import React, { useState } from 'react'

const SuperPermissionsSection = ({
    spForUG,
    setSPForUG,
    spForUsers,
    setSPForUsers,
    spForSurveys,
    setSPForSurveys,
    spForSG,
    setSPForSG,
    spForRoles,
    setSPForRoles,
}) => {
    
    return (
        <div className="mt-5">
            <span className="flex flex-col space-y-2">
                <Checkbox
                    setSelectedInput={setSPForUsers}
                    checked={spForUsers} 
                >
                    Users
                </Checkbox>
                <Checkbox
                    setSelectedInput={setSPForUG}
                    checked={spForUG} 
                >
                    User Groups
                </Checkbox>
                <Checkbox
                    setSelectedInput={setSPForSurveys}
                    checked={spForSurveys} 
                >
                    Surveys
                </Checkbox>
                <Checkbox
                    setSelectedInput={setSPForSG}
                    checked={spForSG} 
                >
                    Survey Groups
                </Checkbox>
                <Checkbox
                    setSelectedInput={setSPForRoles}
                    checked={spForRoles} 
                >
                    Roles
                </Checkbox>
            </span>
        </div>
    )
}

const Checkbox = ({ checked, children, setSelectedInput }) => {
    const handleCheck  = () => {
        setSelectedInput(prev=>!prev);
    }

    return (
        <div onClick={handleCheck} className="flex items-center justify-start font-semibold text-blue pointer">
            <span className="ml-9">{checked ? <CheckedIcon /> : <UncheckedIcon /> }</span>
            <span className="flex ml-5 space-x-5">{children}</span> 
        </div>
    )
}
const CheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="18" height="18" rx="6" fill="#8B0021"/> <path d="M13.5 6L7.82813 12.3838L5.25 9.48207" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
const UncheckedIcon = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="17" height="17" rx="5.5" stroke="#CCCCCC"/> </svg>

export default SuperPermissionsSection
